import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/arrowDown.svg";

interface SelectBoxType {
  item: string;
  options?: string[];
  placeholder?: string;
  children?: React.ReactNode;
}

const SelectBox = ({ item, options, placeholder }: SelectBoxType) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAbove, setIsAbove] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const checkDropdownDirection = () => {
    const rect = selectRef.current?.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    if (rect) {
      setIsAbove(rect.bottom + 200 > viewportHeight);
    }
  };

  useEffect(() => {
    if (isOpen) {
      checkDropdownDirection();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectRef} className="relative w-full">
      <div
        className="dropdown cursor-pointer box-border h-12 relative flex items-center justify-between px-4 py-3 border rounded-[10px]"
        onClick={toggleDropdown}
      >
        {item || placeholder || "선택해주세요"}
        <ArrowDown className={`transform ${isOpen ? "rotate-180" : ""}`} />
      </div>
    </div>
  );
};

export default SelectBox;

export const ROLE_LABELS: Record<string, string> = {
  LEADING_ACTOR: "주연",
  LEADING_SUPPORTING_ACTOR: "주조연",
  SUPPORTING_ACTOR: "조연",
  SUPPORTING_BIT_PART: "조단역",
  BIT_PART: "단역",
  DOUBLE: "대역",
  IMAGE_BIT_PART: "이미지",
  CAMEO: "특별출연",
  EPISODE: "에피소드",
  ETC: "기타",
};

export const TYPE_LABELS: Record<string, string> = {
  MOVIE: "영화",
  TV_SERIES: "드라마",
  VARIETY_SHOW: "예능",
  MUSIC_VIDEO: "뮤직비디오",
  WEB_SERIES: "웹드라마",
  SHORT_FORM: "숏폼",
  INDEPENDENT_FILM: "독립영화",
  SHORT_FILM: "단편영화",
  THEATER: "연극",
  ADVERTISEMENT: "광고",
  MUSICAL: "뮤지컬",
  DOCUMENTARY: "다큐멘터리",
  AUDITION: "오디션",
};

export const JOB_LABELS: Record<string, string> = {
  ROLE_ARTIST: "아티스트",
  ROLE_MANAGER: "매니저",
  ROLE_CASTING_DIRECTOR: "캐스팅디렉터",
  ROLE_DIRECTOR: "연출",
  ROLE_PRODUCER: "제작",
  ROLE_AGENCY: "광고",
  ROLE_WRITER: "작가"
}
