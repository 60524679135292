import React from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';

import { useFeedbackStatistics } from 'apis/feedback';

const StaffFeedbackState = () => {
    const { data } = useFeedbackStatistics(6);

    const feedbackData = data?.data;

    return (
        <>
            <HeaderWithBackButton title={'보낸 피드백 현황'} />
            <div className='pt-5 pb-24'>
                <div className='px-5 pt-4 flex flex-col gap-7'>
                    {feedbackData?.slice(1).map((item: any) => {
                        const splitDate = item.statMonth?.split("-")
                        const date = `${splitDate[0]}년 ${splitDate[1]}월`

                        return (
                            <div key={item.statMonth} className='flex text-Gray09 items-center justify-between'>
                                <div className='MBody16'>
                                    {date}
                                </div>
                                <div className='BBody16'>
                                    {(item.feedbackCount || 0) + (item.castingFeedbackCount || 0)}개
                                </div>
                            </div>
                        )
                    })}
                </div>
                <hr className="w-full h-1.5 my-7 bg-Gray01" />
                <div className='text-Gray05 RCaption14 text-center'>보낸 피드백 현황은 6개월까지만 확인할 수 있어요.</div>
            </div >
        </>
    );
};

export default StaffFeedbackState;