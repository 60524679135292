import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useInView } from 'react-intersection-observer';

import { ReactComponent as Search } from "../assets/icon_search.svg";

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import MemberSelectCard from 'components/MemberSelectCard';
import ButtonBackground from 'components/ButtonBackground';
import { FillButton } from 'components/FillButton';
import useLoginInfo from 'stores/loginInfo';
import useDebounce from 'hooks/useDebounce';

import { useStaffs } from 'apis/member';

interface CastingStakeholderProps {
    isActive: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
    onMemberCardClick: (staff: {
        memberId: number,
        name: string;
        role: {
            code: string;
            label: string;
        },
        agency: string;
        thumbnailUrl: string;
        id: number;
    }) => void;
    selectedStaff?: {
        memberId: number;
        role: string;
    }[];
    type: string;

    onClick?: () => void;
}

const CastingStakeholders = ({
    isActive,
    setIsActive,
    selectedStaff,
    onMemberCardClick,
    type,
    onClick
}: CastingStakeholderProps) => {
    const { loginInfo } = useLoginInfo();
    const selectStaffModal = useRef<HTMLDivElement>(null);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [searchValue, setSearchValue] = useState<string>("");
    const debouncedValue = useDebounce(searchValue, 500);

    const { data, fetchNextPage, hasNextPage } = useStaffs({ name: debouncedValue });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView) {
            fetchNextPage();
        }
    }, [inView]);

    const staffLists = useMemo(
        () =>
            data
                ? data.pages.flatMap((staff) => staff.data.content).filter((staff) => staff.memberId !== loginInfo?.memberId)
                : [],
        [data, loginInfo?.memberId]
    );

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            ref={selectStaffModal}
            className={`${isActive
                ? "fixed top-0 left-2/4 -translate-x-2/4 max-w-[400px] w-full h-full bg-Gray00 z-[1002]"
                : "hidden"
                } `}
        >      <HeaderWithBackButton
                setIsBackAction={() => {
                    setIsActive(false)
                }}
                title={""}
            />
            <div className="px-5 pt-5 pb-4 space-y-7">
                <div className="BBody20 text-Gray09">{type === "DECISION_MAKER" ? "후보 확정 권한 추가" : type === "PARTICIPANT" ? "참조 관계자 추가" : ""}</div>
                <div className="relative MBody14">
                    <input
                        value={searchValue}
                        onChange={handleInputChange}
                        placeholder={"이름 입력"}
                        className="w-full border py-3 pr-[44px] rounded-md px-[16px] RBody16"
                    />
                    <Search className="absolute top-[15px] right-4" />
                </div>
            </div>
            {staffLists.length === 0 ? (
                <div className="mt-36 items-center text-center">
                    <div className="text-Gray05 RBody14">등록된 스탭이 없어요.</div>
                </div>
            ) : (
                <>
                    <div className="h-full">
                        <div
                            style={{ height: `${viewportHeight - (selectedStaff?.length! > 0 ? 298 : 214)}px` }}
                            className="overflow-y-scroll "
                        >
                            {staffLists?.map((item) => {
                                const staffDesc = `${item.role.label}•${item.agency || "소속사 없음"}`;
                                return (
                                    <MemberSelectCard
                                        key={item.memberId}
                                        imageUrl={item.thumbnailUrl}
                                        onClick={() => onMemberCardClick(item)}
                                        title={item.name}
                                        subTitle={staffDesc}
                                        selected={selectedStaff!?.some(
                                            (p) => p.memberId === item?.memberId
                                        )}
                                    />
                                )
                            })}
                            {hasNextPage && <div ref={ref} />}
                        </div>
                    </div>
                </>
            )}
            {selectedStaff?.length! > 0 &&
                <ButtonBackground>
                    <FillButton
                        onClick={onClick}
                        size="B"
                        variant="Membership"
                        text={"선택"}
                        className="w-full"
                    />
                </ButtonBackground>
            }
        </div>
    );
};

export default CastingStakeholders;