import React, { useState } from "react";

import TitleWrap from "components/TitleWrap";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import Input from "components/Input";
import ImgUpload from "components/ImgUpload";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { Toast } from "hooks/useToast";
import ButtonBackground from "components/ButtonBackground";
import { MY_ROLE } from "utils/onRoleStatus";
import Textarea from "components/Textarea";
import { useCreateStaffProfile } from "apis/profile";
import { createStaff } from "types/staff/staff";
import { useUploadImage } from "apis/attachment";
import useLoginInfo from "stores/loginInfo";

const CreateStaffProfile = () => {
  const navigate = useNavigate();
  const { setLoginInfo } = useLoginInfo();
  const role = MY_ROLE();

  const [staffData, setStaffData] = useState<createStaff>({
    filmographies: [],
    attachments: [],
    filmographySummary: "",
  });

  const { mutate: uploadImages } = useUploadImage();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: "THUMBNAIL",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setStaffData({
            ...staffData,
            attachments: [...staffData.attachments, ...updateItem],
          });
        },
      });
    }
  };

  const handleAddFilmography = () => {
    setStaffData((prevState) => ({
      ...prevState,
      filmographies: [
        ...prevState.filmographies,
        {
          title: "",
          releaseYear: 0,
          orderNumber: prevState.filmographies.length,
        },
      ],
    }));
  };

  const handleDeleteFilmography = (index: number) => {
    setStaffData((prevState) => ({
      ...prevState,
      filmographies: prevState.filmographies.filter((_, i) => i !== index),
    }));
  };

  const handleInputChange = (
    index: number,
    field: "title" | "releaseYear",
    value: string | number
  ) => {
    setStaffData((prevState) => ({
      ...prevState,
      filmographies: prevState.filmographies.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const { mutate } = useCreateStaffProfile();

  const valid = (data: {
    filmographies: {
      title: string;
      releaseYear: number;
      orderNumber: number;
    }[];
    attachments: {
      id: number;
      type: string;
      orderNumber: number;
      url?: string;
    }[];
  }) => {
    const hasThumbnail = data?.attachments!.some(
      (attachment) => attachment.type === "THUMBNAIL"
    );
    if (!hasThumbnail) return "프로필 이미지는 필수입니다.";
    if (
      staffData?.filmographies!?.some((filmography) => {
        return !filmography.title || !filmography.releaseYear;
      })
    )
      return "필모그래피는 빈칸없이 입력해주세요";
    return null;
  };

  const isManager = role === "ROLE_MANAGER";

  return (
    <>
      <HeaderWithBackButton title="내 프로필" />
      <div className="px-5 py-5 pb-24">
        <div className="flex items-start gap-5 flex-col">
          <div className="w-full">
            <TitleWrap
              subTitle="4:5 / 10MB 이하"
              title={"프로필 사진 업로드"}
              require
            >
              {staffData?.attachments.length ? (
                <div className="relative w-[172px]">
                  <img
                    className="w-[172px] h-[215px] rounded-[10px] object-cover"
                    src={`${process.env.REACT_APP_MEDIA_URL}${staffData?.attachments[0].url}`}
                    alt="THUMBNAIL"
                  />
                  <XButton
                    onClick={() => {
                      setStaffData({ ...staffData, attachments: [] });
                    }}
                    className="absolute cursor-pointer top-4 right-4"
                  />
                </div>
              ) : (
                <ImgUpload
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={(e) => {
                    imgUpload(e);
                  }}
                  title="사진 업로드"
                  id={"profileImg"}
                ></ImgUpload>
              )}
              <div className="mt-4 rounded-[10px] bg-Gray01 p-4">
                <div className="RBody14 text-Gray05">
                  <div>이런 사진은 프로필 등록이 불가능해요.</div>
                  <ul>
                    <li>• 워터마크가 포함된 사진</li>
                    <li>• 과도하게 편집된 사진</li>
                    <li>• 화질이 너무 안 좋은 사진</li>
                    <li>• 다른 사람이 포함된 사진</li>
                    <li>• 담배나 술이 확인되는 사진</li>
                    <li>• 사회통념에 반하는 내용이 들어간 사진</li>
                    <li>
                      • 타인이나 특정 계층을 모욕하거나 비하•비방하는 내용의
                      사진
                    </li>
                  </ul>
                </div>
              </div>
            </TitleWrap>
          </div>
          {isManager ? (
            <TitleWrap title={"경력"}>
              <Textarea
              rows={7}
                value={staffData?.filmographySummary}
                onChange={(e) => {
                  setStaffData({
                    ...staffData,
                    filmographySummary: e.target.value,
                  });
                }}
                placeholder="최대 150자"
                className="w-full h-[270px]"
                maxlength={150}
              />
            </TitleWrap>
          ) : (
            <div className="w-full">
              <TitleWrap require title={"필모그래피"}>
                <IconPlus
                  className="absolute BBody16 text-Blue04 cursor-pointer -top-[32px] right-0"
                  onClick={() => handleAddFilmography()}
                />
                <div className="flex flex-col gap-2">
                  {staffData.filmographies.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="w-full rounded-[10px] relative gap-4 bg-Gray01 p-4 flex"
                      >
                        <TitleWrap title={"제작연도"} require>
                          <Input
                            type={"number"}
                            inputMode="numeric"
                            className="w-[93px]"
                            placeholder="제작연도"
                            value={item.releaseYear}
                            onKeyDown={(e) => {
                              if (["e", "E", "+", "-", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              handleInputChange(
                                i,
                                "releaseYear",
                                e.target.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 4)
                              )
                            }
                          />
                        </TitleWrap>
                        <TitleWrap
                          subTitle="최대36자"
                          title={"작품제목"}
                          require
                        >
                          <Input
                            type={"text"}
                            className="w-full"
                            placeholder="작품제목"
                            value={item.title}
                            onChange={(e) =>
                              handleInputChange(i, "title", e.target.value)
                            }
                          />
                        </TitleWrap>
                        <div
                          onClick={() => handleDeleteFilmography(i)}
                          className="absolute right-5 BBody16 text-Blue04 cursor-pointer"
                        >
                          삭제
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TitleWrap>
            </div>
          )}
        </div>
        <ButtonBackground>
          <Button
            onClick={() => {
              const validError = valid(staffData);
              if (validError !== null) {
                Toast.error(validError);
              } else {
                mutate(staffData, {
                  onSuccess: (data, variables, context) => {
                    Toast.success("프로필을 등록했어요");
                    setLoginInfo();
                    navigate("/staff/my", { replace: true });
                  },
                });
              }
            }}
            className="w-full h-12 bg-Blue04 text-white border-none"
            text={"등록하기"}
          ></Button>
        </ButtonBackground>
      </div>
    </>
  );
};

export default CreateStaffProfile;
