import React, { useEffect, useState } from "react";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import TitleWrap from "components/TitleWrap";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import ImgUpload from "components/ImgUpload";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Toast } from "hooks/useToast";
import { TYPE_LIST } from "utils/type";
import ButtonBackground from "components/ButtonBackground";
import { TYPE_LABELS } from "components/SelectBox";
import { useCreateProduction, useProduction } from "apis/production";
import { createProduct } from "types/product/product";
import { useUploadImage } from "apis/attachment";

const CreateProduction = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const productionId = Number(searchParams.get("id"));

  const [postData, setPostData] = useState<createProduct>({
    title: "",
    format: "",
    description: "",
    directorName: "",
    companyName: "",
    agencyName: "",
    adAgencyName: "",
    attachmentUrl: "",
    attachmentId: null,
  });

  const { mutate } = useCreateProduction();

  const { mutate: uploadImages } = useUploadImage();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          setPostData({
            ...postData,
            attachmentId: res?.data[0].attachmentId,
            attachmentUrl: res?.data[0].attachmentUrl,
          });
        },
      });
    }
  };

  const valid = (postData: createProduct) => {
    if (!postData.title) return "작품제목은 필수입니다.";
    if (!postData.format) return "분야를 선택해주세요.";
    if (!postData.description) return "작품설명을 입력해주세요.";
    return null;
  };

  return (
    <>
      <HeaderWithBackButton title={`작품등록`} />
      <div className="pb-24 pt-5 px-5">
        <div className="flex flex-col gap-10">
          <TitleWrap title={"대표사진 업로드"}>
            {postData.attachmentUrl ? (
              <div className="relative">
                <img
                  className="w-full h-[450px] rounded-[10px] object-cover"
                  src={`${process.env.REACT_APP_MEDIA_URL}${postData?.attachmentUrl}`}
                  alt="ProfileImg"
                />
                <XButton
                  onClick={() => {
                    setPostData({
                      ...postData,
                      attachmentId: 0,
                      attachmentUrl: "",
                    });
                  }}
                  className="absolute cursor-pointer top-4 right-4"
                />
              </div>
            ) : (
              <ImgUpload
                accept="image/jpg,image/png,image/jpeg"
                onChange={(e) => {
                  imgUpload(e);
                }}
                title="대표 사진 업로드"
                id={"Prod_profile"}
              ></ImgUpload>
            )}
          </TitleWrap>
          <TitleWrap require title={"작품제목"}>
            <Input
              value={postData?.title}
              onChange={(e) => {
                setPostData({ ...postData, title: e.target.value });
              }}
              type={"text"}
              placeholder="작품제목"
            />
          </TitleWrap>
          <TitleWrap require title={"분야"}>
            <Dropdown
              innerText={TYPE_LABELS[postData.format || ""] || "선택"}
              width="w-full"
            >
              <div>
                {TYPE_LIST.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setPostData({ ...postData, format: item.role });
                      }}
                      key={item.id}
                    >
                      {item.title}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
          <div className="flex items-center gap-4">
            <TitleWrap title={"감독"}>
              <Input
                value={postData?.directorName}
                onChange={(e) => {
                  setPostData({ ...postData, directorName: e.target.value });
                }}
                type={"text"}
                placeholder="감독"
              />
            </TitleWrap>
            <TitleWrap title={"제작사"}>
              <Input
                value={postData?.companyName}
                onChange={(e) => {
                  setPostData({ ...postData, companyName: e.target.value });
                }}
                type={"text"}
                placeholder="제작사"
              />
            </TitleWrap>
          </div>
          <div className="flex items-center gap-4">
            <TitleWrap title={"소속사"}>
              <Input
                value={postData?.agencyName}
                onChange={(e) => {
                  setPostData({ ...postData, agencyName: e.target.value });
                }}
                type={"text"}
                placeholder="소속사"
              />
            </TitleWrap>
            <TitleWrap title={"에이전시"}>
              <Input
                value={postData?.adAgencyName}
                onChange={(e) => {
                  setPostData({ ...postData, adAgencyName: e.target.value });
                }}
                type={"text"}
                placeholder="에이전시"
              />
            </TitleWrap>
          </div>
          <TitleWrap require title={"작품설명"}>
            <Textarea
              value={postData?.description}
              onChange={(e) => {
                setPostData({ ...postData, description: e.target.value });
              }}
              rows={7}
              placeholder="작품설명"
              maxlength={500}
            />
          </TitleWrap>
        </div>
        <ButtonBackground>
          <Button
            onClick={() => {
              const validError = valid(postData);
              if (validError !== null) {
                Toast.error(validError);
              } else {
                mutate(postData, {
                  onSuccess(data, variables, context) {
                    navigate("/staff/casting/my", { replace: true });
                    Toast.success("작품이 등록되었어요");
                  },
                });
              }
            }}
            className="w-full h-12 bg-Blue04 text-white border-none"
            text={`${productionId ? "수정" : "등록"}하기`}
          ></Button>
        </ButtonBackground>
      </div>
    </>
  );
};

export default CreateProduction;
