import React, { useState } from 'react';

import ImageModal from 'components/ImageModal';

import { ReactComponent as ArrowLeft } from "assets/arrow_wh_right.svg";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import YouTube from 'react-youtube';
import playerThumbnail from "assets/playerThumbnail.png";
import { Zoom } from 'swiper/modules';
import { useNavigate, useSearchParams } from 'react-router';

interface ArtistAttachmentsProps {
    castingCallId?: string;
    castingId?: string;
    attachments: {
        id: number;
        type: {
            code: string;
            label: string;
        };
        url: string;
        orderNumber: number;
    }[];
    videoLinks: {
        id: number;
        link: string;
        type: {
            code: string;
            label: string;
        };
        orderNumber: number;
    }[];
}

const ArtistAttachments = ({ attachments, videoLinks, castingCallId, castingId }: ArtistAttachmentsProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [swiper, setSwiper] = useState<SwiperClass>();
    const [swiperModal, setSwiperModal] = useState<SwiperClass>();
    const [swiperIndex, setSwiperIndex] = useState(0);

    const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

    const handlePrev = () => {
        swiperModal?.slidePrev();
    };
    const handleNext = () => {
        swiperModal?.slideNext();
    };

    const filteredAtt = attachments!?.filter(
        (attachment) => attachment.type.code !== "ADDITIONAL_VIDEO"
    ).sort((a, b) => a.orderNumber - b.orderNumber);

    const getYouTubeVideoId = (url: string): string | undefined => {
        try {
            const urlObj = new URL(url);

            if (urlObj.hostname.includes("youtube.com")) {
                return urlObj.searchParams.get("v") ?? undefined;
            }

            if (urlObj.hostname.includes("youtu.be")) {
                return urlObj.pathname.substring(1) ?? undefined;
            }
        } catch (error) {
            return undefined;
        }
    };

    const opts = {
        width: "100%",
        height: "225",
        playerVars: {
            autoplay: 0,
            rel: 0,
            modestbranding: 1,
        },
    }

    return (
        <div className='flex flex-col gap-4'>
            <div className="relative">
                <div className="absolute right-4 top-4 MCaption14 bg-Gray08/80 z-50 text-Gray00 rounded-full h-7 px-2 py-1">
                    <span>{swiperIndex + 1}</span>
                    <span className="text-Gray05">{" / "}</span>
                    <span className="text-Gray05">{filteredAtt?.length}</span>
                </div>
                <Swiper
                    onActiveIndexChange={(e: SwiperClass) =>
                        setSwiperIndex(e.realIndex)
                    }
                    onSwiper={(e: SwiperClass) => {
                        setSwiper(e);
                    }}
                    spaceBetween={8}
                    slidesPerView={1}
                >
                    {filteredAtt?.map((item, idx) => {
                        return (
                            <SwiperSlide
                                onClick={() => {
                                    const params = new URLSearchParams();
                                    params.set("artist-detail-modal", "true");

                                    if (castingCallId) params.set("castingCallId", castingCallId);
                                    if (castingId) params.set("castingId", castingId);

                                    setSearchParams(params);

                                    setSwiperIndex(idx);
                                }}
                                key={idx}
                            >
                                <div className="w-full">
                                    <img
                                        src={MEDIA_URL + item.url}
                                        alt="아티스트 상세이미지"
                                        className="w-full aspect-4/5 object-cover"
                                    />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <div className='flex flex-col gap-2'>
                {attachments?.map((item) => {
                    if (item.type.code === "ADDITIONAL_VIDEO") {
                        return (
                            <video
                                preload='metadata'
                                poster={playerThumbnail}
                                className='w-full h-[225px]' key={item.id} controls >
                                <source src={MEDIA_URL + item.url} />
                            </video>
                        );
                    }
                })}
                {videoLinks?.map((item) => {
                    const videoId = getYouTubeVideoId(item?.link);
                    return (
                        <YouTube
                            key={item.id}
                            videoId={videoId}
                            opts={opts}
                        />
                    );
                })}
            </div>
            {searchParams.get("artist-detail-modal") && (
                <ImageModal
                    onClick={() => {
                        navigate(-1);
                    }}
                    className="w-full"
                >
                    <Swiper
                        modules={[Zoom]}
                        initialSlide={swiperIndex}
                        onSwiper={(e: SwiperClass) => {
                            setSwiperModal(e);
                        }}

                        zoom={{ maxRatio: 3 }}
                        slidesPerView={1}
                        spaceBetween={8}
                        className="w-full relative"
                    >
                        {filteredAtt?.map((item, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="relative my-auto mx-auto"
                                >
                                    {MEDIA_URL && (
                                        <div className="w-full h-auto swiper-zoom-container">
                                            <img
                                                src={`${MEDIA_URL}${item.url}`}
                                                alt="IMAGES"
                                                className="mx-auto w-full object-contain max-h-[90vh] aspect-4/5"
                                            />
                                        </div>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <ArrowLeft
                        onClick={() => {
                            handlePrev();
                        }}
                        className="absolute top-2/4 left-5 z-50 rotate-180 cursor-pointer"
                    />
                    <ArrowLeft
                        onClick={() => {
                            handleNext();
                        }}
                        className="absolute top-2/4 right-5 z-50 cursor-pointer"
                    />
                </ImageModal>
            )}
        </div>
    );
};

export default ArtistAttachments;