import React from "react";
import { formatDate } from "utils/onDateFormat";

import { ReactComponent as Back } from "assets/headerBack.svg";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";

import { useNavigate } from "react-router";
import { useMyMembership } from "apis/payment";

const MembershipMy = () => {
  const navigate = useNavigate();
  const { data } = useMyMembership();

  return (
    <div className="w-full pb-[103px] min-w-[280px] max-w-[400px] min-h-screen px-5 bg-Gray01 mx-auto">
      <div className="mx-auto px-5 py-2 fixed bg-Gray01 left-2/4 top-0 z-50 -translate-x-2/4 max-w-[400px] w-full BBody20 text-Gray09 ">
        <div className="flex justify-between items-center">
          <Back
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="absolute left-2/4 text-Gray09 BBody20 -translate-x-2/4 max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            멤버십
          </div>
        </div>
      </div>
      <div className="w-full pt-[74px]">
        <div className="mb-4 w-full border border-Gray03 bg-Gray00 rounded-[10px] p-5">
          <div className="flex flex-col items-start">
            <div className="MCaption14 text-Gray05">
              {formatDate(data?.data?.startDate!)} -{" "}
              {formatDate(data?.data?.endDate!)}
            </div>
            <div className="flex items-center mt-1.5 mb-1 w-full justify-between">
              <div className="MBody16 text-Gray09">
                {data?.data?.membershipName}
              </div>
              {/*<div className="BBody18 text-Blue04">*/}
              {/*  {data?.data.price?.toLocaleString()}원*/}
              {/*</div>*/}
            </div>
            <div className="RCaption12 text-Gray05">
              {data?.data.subsribeFlag && "다음 결제 예정일 :"}{" "}
              {data?.data.subsribeFlag
                ? formatDate(data?.data?.endDate!)
                : "멤버십 해지"}
            </div>
          </div>
          <hr className="my-4 h-px w-full text-Gray03" />
          <div
            onClick={() => {
              navigate("/membership/history", {
                state: {
                  membershipName: data?.data?.membershipName,
                  period: `${formatDate(data?.data?.startDate!)} - ${formatDate(
                    data?.data?.endDate!
                  )}`,
                  price: data?.data.price?.toLocaleString(),
                },
              });
            }}
            className="flex items-center cursor-pointer w-full justify-between"
          >
            <div className="MCaption14 text-Gray09">결제내역</div>
            <ArrowRight />
          </div>
        </div>
        {data?.data.subsribeFlag && (
          <div className="mb-4 w-full border border-Gray03 bg-Gray00 rounded-[10px] p-5">
            <div
              onClick={() => {
                navigate("/membership/payment-method");
              }}
              className="flex items-center cursor-pointer w-full justify-between"
            >
              <div className="MCaption14 text-Gray09">결제 수단 변경</div>
              <ArrowRight />
            </div>
          </div>
        )}
        <div className="text-Gray05">
          <div className="MCaption14">이용안내</div>
          <ol className="list-disc list-inside RCaption14">
            <li>결제 금액에는 부가세가 포함되어 있어요.</li>
            <li>
              언제든지 자동 결제를 해지할 수 있어요. 해지하면 다음 결제
              예정일부터 결제가 진행되지 않아요.
            </li>
            <li>
              미성년자가 결제하려면 법정대리인의 동의가 필요해요. 동의를 받지
              않은 경우 법정대리인이 구매를 취소할 수 있어요.
            </li>
          </ol>
        </div>
      </div>
      <div className="max-w-[400px] bg-Gray01 w-full fixed bottom-0 left-2/4 -translate-x-2/4 pb-[35px] px-5">
        <button
          onClick={() => {
            navigate(
              data?.data.subsribeFlag ? "/membership/cancel" : "/membership"
            );
          }}
          className={`bg-[#0D0D0D] MBody16 h-[52px] w-full border-Gray00 border justify-center text-Gray00 flex items-center hover:bg-opacity-80 disabled:opacity-20`}>
          {data?.data.subsribeFlag ? "멤버십 해지하기" : "다시 구독하기"}
        </button>
      </div>
    </div>
  );
};

export default MembershipMy;