import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";

import {
  artistProfilesRequest,
  deleteProfileRequest,
  getArtistProfilesByManagerRequest,
  getManagerArtistProfilesRequest,
  staffProfilesRequest,
} from "types/profile";

import { createArtists, updateArtists } from "types/artist/artist";
import { createStaff, updateStaff } from "types/staff/staff";

// 아티스트 프로필 등록
const createArtistProfile = async (
  createArtistProfileRequest: createArtists
) => {
  return await requestToCastingVote
    .post(`profiles/artist`, createArtistProfileRequest)
    .then((res) => res.data);
};

const useCreateArtistProfile = () => {
  return useMutation({
    mutationFn: createArtistProfile,
  });
};

// 스탭 프로필 등록
const createStaffProfile = async (createStaffProfileRequest: createStaff) => {
  return await requestToCastingVote
    .post(`profiles/staff`, createStaffProfileRequest)
    .then((res) => res.data);
};

const useCreateStaffProfile = () => {
  return useMutation({
    mutationFn: createStaffProfile,
  });
};

// 아티스트 프로필 수정
const updateArtistProfile = async (
  updateArtistProfileRequest: updateArtists
) => {
  return await requestToCastingVote
    .patch(`profiles/artist`, updateArtistProfileRequest)
    .then((res) => res.data);
};

const useUpdateArtistProfile = () => {
  return useMutation({
    mutationFn: updateArtistProfile,
  });
};

// 스탭 프로필 수정
const updateStaffProfile = async (updateStaffProfileRequest: updateStaff) => {
  return await requestToCastingVote
    .patch(`profiles/staff`, updateStaffProfileRequest)
    .then((res) => res.data);
};

const useUpdateStaffProfile = () => {
  return useMutation({
    mutationFn: updateStaffProfile,
  });
};

// 프로필 삭제
const deleteProfile = async (deleteProfileRequest: deleteProfileRequest) => {
  return await requestToCastingVote
    .delete(`profiles`, { data: deleteProfileRequest })
    .then((res) => res.data);
};

const useDeleteProfile = () => {
  return useMutation({
    mutationFn: deleteProfile,
  });
};

// 아티스트 프로필 목록 조회
const getArtistProfiles = async (
  artistProfilesRequest: artistProfilesRequest
) => {
  return await requestToCastingVote
    .get(`profiles/artist`, { params: artistProfilesRequest })
    .then((res) => res.data);
};

const useArtistProfiles = (artistProfilesRequest: artistProfilesRequest) => {
  return useInfiniteQuery({
    queryKey: ["artistProfiles", artistProfilesRequest],
    queryFn: ({ pageParam = 0 }) =>
      getArtistProfiles({ ...artistProfilesRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
    // staleTime: 1000 * 60 * 5, // 5분 동안 fresh 상태 유지
    // gcTime: 1000 * 60 * 10, // 10분 동안 캐시 유지
  });
};

// 아티스트 프로필 상세 조회
const getArtistProfile = async (id: number) => {
  return await requestToCastingVote
    .get(`profiles/${id}/artist`)
    .then((res) => res.data);
};

const useArtistProfile = (id: number) => {
  return useQuery({
    queryKey: ["artistProfile", id],
    queryFn: () => getArtistProfile(id),
  });
};

// 스탭 프로필 목록 조회
const getStaffProfiles = async (staffProfilesRequest: staffProfilesRequest) => {
  return await requestToCastingVote
    .get(`profiles/staff`, { params: staffProfilesRequest })
    .then((res) => res.data);
};

const useStaffProfiles = (staffProfilesRequest: staffProfilesRequest) => {
  return useInfiniteQuery({
    queryKey: ["staffProfiles", staffProfilesRequest],
    queryFn: ({ pageParam = 0 }) =>
      getStaffProfiles({ ...staffProfilesRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 스탭 프로필 상세 조회
const getStaffProfile = async (id: number) => {
  return await requestToCastingVote
    .get(`profiles/${id}/staff`)
    .then((res) => res.data);
};

const useStaffProfile = (id: number) => {
  return useQuery({
    queryKey: ["staffProfile", id],
    queryFn: () => getStaffProfile(id),
  });
};

// 매니저가 관리할 아티스트 프로필 등록
const createArtistProfileByManager = async (
  createArtistProfileByManagerRequest: createArtists
) => {
  return await requestToCastingVote
    .post(`manage-profiles`, createArtistProfileByManagerRequest)
    .then((res) => res.data);
};

const useCreateArtistProfileByManager = () => {
  return useMutation({
    mutationFn: createArtistProfileByManager,
  });
};

// 매니저가 관리할 아티스트 프로필 수정
const updateArtistProfileByManager = async (
  updateArtistProfileByManagerRequest: updateArtists
) => {
  return await requestToCastingVote
    .patch(`manage-profiles`, updateArtistProfileByManagerRequest)
    .then((res) => res.data);
};

const useUpdateArtistProfileByManager = () => {
  return useMutation({
    mutationFn: updateArtistProfileByManager,
  });
};

// 매니저가 관리할 아티스트 프로필 삭제
const deleteArtistProfileByManager = async (profileId: number) => {
  return await requestToCastingVote
    .delete(`manage-profiles`, { data: { profileId } })
    .then((res) => res.data);
};

const useDeleteArtistProfileByManager = () => {
  return useMutation({
    mutationFn: deleteArtistProfileByManager,
  });
};

// 매니저가 관리할 아티스트 프로필 목록 조회
const getArtistProfilesByManager = async (
  getArtistProfilesByManagerRequest: getArtistProfilesByManagerRequest
) => {
  return await requestToCastingVote
    .get(`manage-profiles`, { params: getArtistProfilesByManagerRequest })
    .then((res) => res.data);
};

const useArtistProfilesByManager = (
  getArtistProfilesByManagerRequest: getArtistProfilesByManagerRequest
) => {
  return useInfiniteQuery({
    queryKey: ["artistProfilesByManager", getArtistProfilesByManagerRequest],
    queryFn: ({ pageParam = 0 }) =>
      getArtistProfilesByManager({
        ...getArtistProfilesByManagerRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 매니저가 관리할 아티스트 프로필 상세 조회
const getArtistProfileByManager = async (id: number) => {
  return await requestToCastingVote
    .get(`manage-profiles/${id}`)
    .then((res) => res.data);
};

const useArtistProfileByManager = (id: number) => {
  return useQuery({
    queryKey: ["artistProfileByManager", id],
    queryFn: () => getArtistProfileByManager(id),
  });
};

// 매니저가 관리하는 아티스트 프로필 목록 조회
const getManagerArtistProfiles = async (
  getManagerArtistProfilesRequest: getManagerArtistProfilesRequest
) => {
  return await requestToCastingVote
    .get(`profiles/${getManagerArtistProfilesRequest?.id}/manage`, {
      params: getManagerArtistProfilesRequest,
    })
    .then((res) => res.data);
};

const useManagerArtistProfiles = (
  getManagerArtistProfilesRequest: getManagerArtistProfilesRequest
) => {
  return useInfiniteQuery({
    queryKey: ["managerArtistProfiles", getManagerArtistProfilesRequest],
    queryFn: ({ pageParam = 0 }) =>
      getManagerArtistProfiles({
        ...getManagerArtistProfilesRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    enabled:
      !!getManagerArtistProfilesRequest?.id &&
      !!getManagerArtistProfilesRequest?.isManager,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

export {
  createArtistProfile,
  useCreateArtistProfile,
  createStaffProfile,
  useCreateStaffProfile,
  updateArtistProfile,
  useUpdateArtistProfile,
  updateStaffProfile,
  useUpdateStaffProfile,
  deleteProfile,
  useDeleteProfile,
  getArtistProfiles,
  useArtistProfiles,
  getArtistProfile,
  useArtistProfile,
  getStaffProfiles,
  useStaffProfiles,
  getStaffProfile,
  useStaffProfile,
  createArtistProfileByManager,
  useCreateArtistProfileByManager,
  updateArtistProfileByManager,
  useUpdateArtistProfileByManager,
  deleteArtistProfileByManager,
  useDeleteArtistProfileByManager,
  getArtistProfilesByManager,
  useArtistProfilesByManager,
  getArtistProfileByManager,
  useArtistProfileByManager,
  getManagerArtistProfiles,
  useManagerArtistProfiles,
};
