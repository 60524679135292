import React, { useEffect } from "react";
import { ClipLoader } from "react-spinners";

const Loading = () => {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <div className="fixed w-full h-full top-0 left-0 z-[100000000]">
      <div className="left-2/4 top-2/4 fixed !-translate-x-2/4 !-translate-y-2/4">
        <ClipLoader color="#4383FF" className="w-[50px] h-[50px]" />
      </div>
    </div>
  );
};

export default Loading;
