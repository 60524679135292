import { create } from "zustand";

interface FullPageState {
  fullPage: boolean;
  openFullPage: () => void;
  closeFullPage: () => void;
}

const FullPage = create<FullPageState>()((set) => ({
  fullPage: false,

  openFullPage: () => set({ fullPage: true }),
  closeFullPage: () => set({ fullPage: false }),
}));

export default FullPage;
