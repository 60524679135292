import React, { useEffect, useMemo, useState } from "react";

import ArtistListCard from "components/ArtistListCard";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router";
import { splitBirth } from "utils/onBirth";
import { useManagerArtistProfiles } from "apis/profile";

interface StaffArtistsProfilesProps {
  memberId: number;
  isManager: boolean;
}

const StaffArtistsProfiles = ({
  memberId,
  isManager,
}: StaffArtistsProfilesProps) => {
  const [tabId, setTabId] = useState(0);

  const managerTab = [
    { id: 0, title: "아티스트" },
  ]

  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { data, fetchNextPage, refetch, hasNextPage } =
    useManagerArtistProfiles({
      id: memberId,
      isManager: isManager,
    });

  useEffect(() => {
    if (memberId) {
      refetch();
    }
  }, [memberId, refetch]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const artists = useMemo(
    () => (data ? data?.pages?.flatMap((data) => data.data.content) : []),
    [data]
  );

  return (
    <>
      <div className="border-b border-Gray-3 px-5">
        <div className="flex items-start gap-4 BCaption14">
          {managerTab.map((item) => {
            return (
              <div key={item.id}
                onClick={() => { setTabId(item.id) }}
                className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                {item.title}
              </div>
            )
          })}
        </div>
      </div>
      {artists.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">
            등록된 소속 아티스트가 없어요
          </div>
        </div>
      ) : (
        <div className="pt-4 px-5">
          <div className="gap-x-2 gap-y-4 grid grid-cols-3">
            {artists.map((item) => {
              return (
                <ArtistListCard
                  key={item.profileId}
                  onClick={() => navigate(`/artist/${item.profileId}`)}
                  title={item?.profileName}
                  src={item?.thumbnailUrl}
                  subTitle={`${splitBirth(item.birthDate!)}`}
                />
              );
            })}
          </div>
          {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
        </div>
      )}
    </>
  );
};

export default StaffArtistsProfiles;
