import React from "react";

interface DateinputType {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  min?: string;
  disabled?: boolean;
  placeholder?: string;
  max?: string;
}

const DateInput = ({
  className,
  disabled,
  min,
  max,
  onChange,
  value,
}: DateinputType) => {
  return (
    <div className="relative w-full">
      <input
        disabled={disabled}
        type="date"
        min={min}
        onChange={onChange}
        max={max}
        required
        value={value || ""}
        // date-placeholder={placeholder}
        className={`border RBody16 w-full border-Gray03 rounded-[10px] py-3 px-4 text-left ${className}`}
      />
    </div>
  );
};

export default DateInput;
