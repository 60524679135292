import React, { useState } from "react";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import TitleWrap from "components/TitleWrap";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import DateInput from "components/DateInput";
import { today } from "utils/onToday";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "hooks/useToast";
import ImgUpload from "components/ImgUpload";

import { CASTING_LIST } from "utils/type";

import ButtonBackground from "components/ButtonBackground";
import { ROLE_LABELS } from "components/SelectBox";
import CastingStakeholders from "pages/CastingStakeholders";
import { useCreateCastingcall } from "apis/castingcall";
import { createCasting } from "types/casting/casting";
import { useUploadFile } from "apis/attachment";
import { LineButton } from "components/LineButton";

const CreateCastingCall = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectStaffModal, setSelectStaffModal] = useState(false);
  const [stakeholderType, setStakeholderType] = useState('');
  const [castingCallData, setCastingCallData] = useState<createCasting>(
    {
      productionId: state?.prodId,
      type: "",
      title: "",
      description: "",
      deadlineDate: "",
      deadlineType: "",
      location: "",
      format: "",
      role: "",
      minAge: null,
      maxAge: null,
      ageType: "",
      gender: "",
      attachments: [],
      stakeholders: [],
    }
  );

  const gender = [
    { id: 0, name: "남자", gender: "MALE" },
    { id: 1, name: "여자 ", gender: "FEMALE" },
    { id: 2, name: "성별무관", gender: "ALL" },
  ];

  const { mutate: uploadFile } = useUploadFile();

  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadFile(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: "FILE",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setCastingCallData({
            ...castingCallData,
            attachments: [...castingCallData.attachments!, ...updateItem],
          });
        },
      });
    }
  };

  const handleDeleteFile = (id: number) => {
    const updatedAttachments = castingCallData.attachments?.filter(
      (item) => item.id !== id
    );
    setCastingCallData({
      ...castingCallData,
      attachments: updatedAttachments,
    });
  };

  const { mutate } = useCreateCastingcall();

  const valid = (castingCallData: createCasting) => {
    if (!castingCallData.title) return "공고제목은 필수입니다.";
    if (!castingCallData.gender) return "성별을 선택해주세요.";
    if (!castingCallData.minAge) return "최소나이 입력해주세요.";
    if (!castingCallData.maxAge) return "최대나이 입력해주세요.";
    return null;
  };

  return (
    <>
      {selectStaffModal &&
        <CastingStakeholders
          onClick={() => {
            setSelectStaffModal(false);
          }}
          isActive={selectStaffModal}
          setIsActive={setSelectStaffModal}
          selectedStaff={castingCallData.stakeholders}
          onMemberCardClick={(item) => {
            const staff = {
              memberId: item.memberId,
              role: "DECISION_MAKER",
              name: item.name
            };

            const isExisting = castingCallData.stakeholders?.some(
              (stakeholder) => stakeholder.memberId === item.memberId
            );

            const updatedStakeholders = isExisting
              ? castingCallData.stakeholders?.filter(
                (stakeholder) => stakeholder.memberId !== item.memberId
              )
              : [...(castingCallData.stakeholders || []), staff];

            setCastingCallData({ ...castingCallData, stakeholders: updatedStakeholders });
          }}
          type={stakeholderType} />
      }
      <HeaderWithBackButton title={state?.prodTitle} />
      <div className="py-5 px-5 pb-24">
        <div className="flex flex-col gap-10">
          <TitleWrap require title={"공고 제목"}>
            <Input
              type={"text"}
              value={castingCallData.title}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  title: e.target.value,
                });
              }}
              placeholder="공고 제목"
            />
          </TitleWrap>
          <TitleWrap require title={"배역"}>
            <Dropdown
              width="w-full"
              innerText={ROLE_LABELS[castingCallData.role || ""] || "선택"}
            >
              <div>
                {CASTING_LIST.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setCastingCallData({
                          ...castingCallData,
                          role: item.role,
                        });
                      }}
                      key={item.id}
                    >
                      {item.title}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
          <TitleWrap title={"성별"} require>
            <Dropdown
              width="w-full"
              innerText={
                castingCallData.gender === "MALE"
                  ? "남자"
                  : castingCallData.gender === "FEMALE"
                    ? "여자"
                    : castingCallData.gender === "ALL"
                      ? "성별무관"
                      : "구분"
              }
            >
              <div>
                {gender.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setCastingCallData({
                          ...castingCallData,
                          gender: item.gender,
                        });
                      }}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
          <TitleWrap require title={"나이"}>
            <div className="flex gap-1 items-center">
              <Input
                inputMode="numeric"
                placeholder="최소"
                value={castingCallData.minAge}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                  setCastingCallData({ ...castingCallData, minAge: value });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                type={"text"}
              />
              ~
              <Input
                inputMode="numeric"
                placeholder="최대"
                value={castingCallData.maxAge}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                  setCastingCallData({ ...castingCallData, maxAge: value });
                }}
                type={"text"}
              />
            </div>
          </TitleWrap>
          <hr />
          <TitleWrap title={"상세내용"}>
            <Textarea
              value={castingCallData.description}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  description: e.target.value,
                });
              }}
              rows={7}
              placeholder="상세내용"
              maxlength={500}
            />
          </TitleWrap>
          <TitleWrap
            subTitle="PDF파일만 올려주세요(최대 1개)"
            title={"첨부파일"}
          >
            {castingCallData.attachments?.some((i) => i.type === "FILE") ? (
              castingCallData.attachments?.map((item) => {
                if (item.type === "FILE")
                  return (
                    <div key={item.id}>
                      <div
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}${item?.url}`,
                            "_blank",
                            "noopener, noreferrer"
                          );
                        }}
                        className="text-Blue04 cursor-pointer MBody16 py-3 text-center bg-Blue01 rounded-[10px] w-full"
                      >
                        첨부파일 미리보기
                      </div>
                      <div
                        onClick={() => {
                          handleDeleteFile(item.id);
                        }}
                        className="text-Blue04 border border-Blue04 cursor-pointer MBody16 mt-2 py-3 text-center bg-Gray00 rounded-[10px] w-full"
                      >
                        첨부파일 삭제
                      </div>
                    </div>
                  );
              })
            ) : (
              <ImgUpload
                onChange={(e) => {
                  fileUpload(e);
                }}
                title="파일 업로드"
                accept=".pdf"
                id={"file"}
              ></ImgUpload>
            )}
          </TitleWrap>
          <TitleWrap title={"마감일자"}>
            <DateInput
              value={castingCallData.deadlineDate}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  deadlineDate: e.target.value,
                });
              }}
              min={today()}
              className="w-full"
            />
          </TitleWrap>
          <TitleWrap title="후보 확정 권한 추가" >
            <LineButton onClick={() => {
              setStakeholderType("DECISION_MAKER")
              setSelectStaffModal(true)
            }} className="w-full" variant="normal" size="L" text="확정 권한 추가" />
            <div className="mt-1.5 flex items-start flex-wrap gap-2">
              {castingCallData.stakeholders?.map((item) => {
                return (
                  <div key={item.memberId} className="bg-Gray01 text-Gray05 p-1.5 MCaption12">
                    {item.name}
                  </div>
                )
              })}
            </div>
          </TitleWrap>
        </div>
        <ButtonBackground>
          <Button
            onClick={() => {
              const validError = valid(castingCallData);
              if (validError !== null) {
                Toast.error(validError);
              } else {
                mutate(castingCallData, {
                  onSuccess: (res) => {
                    if (res.data)
                      navigate(`/casting/${res.data}`, {
                        replace: true,
                      });
                    Toast.success("캐스팅 공고가 등록되었어요");
                  },
                });
              }
            }}
            className="w-full h-12 bg-Blue04 text-white border-none"
            text={`등록하기`}
          ></Button>
        </ButtonBackground>
      </div>
    </>
  );
};

export default CreateCastingCall;
