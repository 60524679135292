import React, { useEffect, useMemo, useState } from "react";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import BottomPopup from "components/BottomPopup";
import { Toast } from "hooks/useToast";
import { useInView } from "react-intersection-observer";
import ButtonBackground from "components/ButtonBackground";
import { format_mmdd } from "utils/onDateFormat";
import { FillButton } from "components/FillButton";
import { MY_ROLE } from "utils/onRoleStatus";
import ArtistCastingOfferCard from "./OfferProfile/ArtistCastingOfferCard";
import { useAcceptOffer, useReceivedOffers } from "apis/offer";

const ArtistReceiveSuggest = () => {
  const [descInfo, setDescInfo] = useState(false);
  const [suggestInfo, setSuggestInfo] = useState<CastingOffer>();
  const myRole = MY_ROLE()

  const isArtist = myRole === "ROLE_ARTIST";

  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const { data, fetchNextPage, hasNextPage, refetch } = useReceivedOffers({});
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);


  const allLists = useMemo(
    () =>
      data ? data?.pages?.flatMap((item) => item.data.content) : [],
    [data]
  );

  const groupByDate = (list: any[]) => {
    return list.reduce((acc, item) => {
      const date = item.createdAt.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  const groupedByDate = groupByDate(allLists);

  const { mutate } = useAcceptOffer();

  const handleAcceptOffer = () => {
    mutate(Number(suggestInfo?.castingOfferId!),
      {
        onSuccess: () => {
          setDescInfo(false);
          Toast.success("제안 받은 공고에 프로필을 보냈어요.");
          refetch();
        }
      }
    );
  };

  const { staffName, profileName, castingCallTitle, description, deadlineDate, status, castingCallId } = suggestInfo || {};

  const canAcceptOffer = status?.code !== "ACCEPTED" && castingCallId;

  const initialSuggestInfo = {
    castingOfferId: null,
    description: "",
    deadlineDate: "",
    createdAt: "",
    readAt: null,
    acceptedAt: null,
    rejectedAt: null,
    status: {
      code: "",
      label: "",
    },
    castingCallId: null,
    castingCallTitle: "",
    staffId: null,
    staffName: "",
    staffRole: {
      code: "",
      label: "",
    },
    staffAgency: null,
    staffThumbnailUrl: "",
    profileId: null,
    profileName: "",
    thumbnailUrl: "",
  };

  // OFFERED(제안), ACCEPTED(제안 수락), REJECTED(제안 거절), CLOSED(마감)
  return (
    <>
      <HeaderWithBackButton title="받은 제안" />
      <div className="pt-5 pb-24">
        {allLists.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">받은 제안이 없어요.</div>
          </div>
        ) : (
          <div>
            {Object.keys(groupedByDate).map((date) => (
              <div key={date} className="mb-4">
                <div className="text-Gray05 RCaption14 px-5">{format_mmdd(date)}</div>
                <div>
                  {groupedByDate[date].map((offerItem: CastingOffer) => {
                    return (
                      <ArtistCastingOfferCard
                        key={offerItem.castingOfferId}
                        imageUrl={isArtist ? offerItem.staffThumbnailUrl : offerItem.thumbnailUrl}
                        title={`[${offerItem.profileName}]님이 ${offerItem.castingCallTitle ? `[${offerItem.castingCallTitle}] 캐스팅` : ""}  제안을 받았어요.`}
                        subTitle={offerItem.staffName}
                        onClick={() => {
                          setDescInfo(true);
                          setSuggestInfo(offerItem);
                        }}
                        isFeedback={false} />
                    )
                  })}
                </div>
              </div>
            ))}
            {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
          </div>
        )}
      </div>

      {descInfo && (
        <BottomPopup onClose={() => setDescInfo(false)}>
          <div className="BBody20 break-keep">
            [{profileName}]님이 {!castingCallTitle ? `[${staffName}]님께 ` : ""} {castingCallTitle ? `[${castingCallTitle}]` : ""}
            {castingCallTitle && " 캐스팅"} 제안을 받았어요!🙌
          </div>
          {description && (
            <div className="mt-4 w-full max-h-[248px] rounded-md bg-Gray01 RBody16 p-4 break-words overflow-y-auto whitespace-break-spaces">
              <div>{description}</div>
            </div>
          )}
          {deadlineDate && (
            <div className="text-Blue04 BBody14 mt-2">
              {deadlineDate}까지 수락하지 않으면 자동으로
              취소돼요.
            </div>
          )}
          <div
            className={`RBody14 text-Gray05 mt-4`}
          >
            스탭 회원의 캐스팅 공고와 제안은 캐스팅보트가 책임지지 않으니
            승인되지 않은 스탭의 허위 공고 또는 제안을 조심하세요.
          </div>
          <ButtonBackground>
            <FillButton
              onClick={() => {
                setDescInfo(false);
                setSuggestInfo(initialSuggestInfo)
              }}
              variant="Secondary"
              size="L"
              className="w-full"
              text={"닫기"}
            />
            {canAcceptOffer && (
              <FillButton
                onClick={() => {
                  handleAcceptOffer();
                }}
                className="w-full"
                variant="Membership"
                size="L"
                text={"수락하기"}
              />
            )}
          </ButtonBackground>
        </BottomPopup>
      )}
    </>
  );
};

export default ArtistReceiveSuggest;

interface CastingOffer {
  castingOfferId: number | null;
  description: string;
  deadlineDate: string;
  createdAt: string;
  readAt: string | null;
  acceptedAt: string | null;
  rejectedAt: string | null;
  status: {
    code: string;
    label: string;
  };
  castingCallId: number | null;
  castingCallTitle: string;
  staffId: number | null;
  staffName: string;
  staffRole: {
    code: string;
    label: string;
  };
  staffAgency: string | null;
  staffThumbnailUrl: string;
  profileId: number | null;
  profileName: string;
  thumbnailUrl: string;
}
