import { useEffect, MutableRefObject } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import {
  FlatIndexLocationWithAlign,
  LocationOptions,
  VirtuosoHandle,
} from "react-virtuoso";

export const useScrollRestorationWithVirtualization = (
  virtuosoRef: MutableRefObject<VirtuosoHandle | null>,
  isLoading: boolean,
  sleep: number = 300,
  customScrollOptions?: LocationOptions
) => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (!isLoading) {
      const handleRouteChangeComplete = () => {
        const scrollKey = `scrollIndex${location.pathname}`;

        const savedScrollPositionString = sessionStorage.getItem(scrollKey);

        const savedScrollPosition =
          savedScrollPositionString !== null
            ? parseInt(savedScrollPositionString)
            : null;
        const isReadyForScrollRestoration =
          savedScrollPosition !== null ||
          !!savedScrollPosition ||
          savedScrollPosition !== 0;

        if (
          isReadyForScrollRestoration &&
          virtuosoRef.current &&
          savedScrollPosition !== 0 &&
          navigationType === "POP"
        ) {
          setTimeout(() => {
            const scrollOptions: FlatIndexLocationWithAlign = {
              behavior: "auto",
              align: "start",
              index: savedScrollPosition!,
              ...customScrollOptions,
            };
            if (savedScrollPosition !== null && savedScrollPosition !== 3) {
              virtuosoRef.current?.scrollToIndex(scrollOptions);
              sessionStorage.removeItem(scrollKey);
            }
          }, sleep);
        }
      };

      handleRouteChangeComplete();

      return () => {};
    }
  }, [customScrollOptions, isLoading, location.pathname, sleep, virtuosoRef]);
};
