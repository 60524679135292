import { create } from "zustand";

interface ShortlistProfileState {
  selectedDeliveryProfileIds: number[];
  setSelectedDeliveryProfileIds: (ids: number[]) => void;
  deleteSelectedDeliveryProfileIds: () => void;
}

const shortlistProfile = create<ShortlistProfileState>((set) => ({
  selectedDeliveryProfileIds: [],
  setSelectedDeliveryProfileIds: (ids: number[]) => {
    set({
      selectedDeliveryProfileIds: ids,
    });
  },
  deleteSelectedDeliveryProfileIds: () =>
    set({ selectedDeliveryProfileIds: [] }),
}));

export default shortlistProfile;
