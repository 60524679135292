import React, { useEffect, useMemo, useState } from "react";
import StaffWorkCard from "components/StaffWorkCard";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router";
import { useProductions } from "apis/production";

interface StaffProductionsProps {
  memberId: number;
}

const StaffProductions = ({ memberId }: StaffProductionsProps) => {
  const [tabId, setTabId] = useState(0);

  const staffTab = [
    { id: 0, title: "작품" },
  ]

  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { data, fetchNextPage, refetch, hasNextPage } = useProductions({
    memberId: memberId,
  });

  useEffect(() => {
    if (memberId) {
      refetch();
    }
  }, [memberId, refetch]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const products = useMemo(
    () => (data ? data?.pages?.flatMap((data) => data.data.content) : []),
    [data]
  );

  return (
    <>
      <div className="border-b border-Gray-3 px-5">
        <div className="flex items-start gap-4 BCaption14">
          {staffTab.map((item) => {
            return (
              <div key={item.id}
                onClick={() => { setTabId(item.id) }}
                className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                {item.title}
              </div>
            )
          })}
        </div>
      </div>
      {products.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">등록된 작품이 없어요</div>
        </div>
      ) : (
        <div className="pt-4 px-5">
          <div className="gap-x-4 gap-y-4 grid grid-cols-2">
            {products.map((prodItem) => {
              return (
                <StaffWorkCard
                  isCasting={prodItem?.isCasting}
                  key={prodItem.productionId}
                  onClick={() =>
                    navigate(`/staff/product/${prodItem.productionId}`)
                  }
                  title={prodItem.title}
                  src={prodItem.thumbnailUrl}
                  isFavorite={prodItem.isFavorite}
                />
              );
            })}
          </div>
          {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
        </div>
      )}
    </>
  );
};

export default StaffProductions;
