import React from 'react';

import { ReactComponent as Facebook } from 'assets/icon_facebook_profile.svg'
import { ReactComponent as Youtube } from 'assets/icon_youtube_profile.svg'
import { ReactComponent as Tiktok } from 'assets/icon_tiktok_profile.svg'
import { ReactComponent as Instagram } from 'assets/icon_insta_profile.svg'
import { FillButton } from 'components/FillButton';
import { LineButton } from 'components/LineButton';
import { MY_ROLE } from 'utils/onRoleStatus';
import { useNavigate } from 'react-router';
import useLoginInfo from 'stores/loginInfo';

interface ArtistInfoCardProps {
    artistName: string;
    artistImageUrl: string;
    jobs: string[];
    birth: string;
    height: number;
    weight: number;
    instagram: string;
    youtube: string;
    memberId?: number;

    onClickOffer?: () => void;
    myProfile?: boolean;
    isSharedPage?: boolean;
}

const ArtistInfoCard = ({
    artistName,
    artistImageUrl,
    jobs,
    birth,
    height,
    weight,
    instagram,
    youtube,
    memberId,

    onClickOffer,

    isSharedPage,
    myProfile
}: ArtistInfoCardProps) => {
    const navigate = useNavigate();
    const { loginInfo } = useLoginInfo();

    const myRole = MY_ROLE();
    const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

    const isArtist = myRole === "ROLE_ARTIST";
    const isMyArtist = (loginInfo?.memberId as number) === (memberId as number);
    const isShared = isSharedPage;

    const calculateAge = () => {
        const currentYear = new Date().getFullYear();
        const calculatedAge =
            currentYear - Number(birth?.split("-")[0]);
        return calculatedAge;
    };

    const handleOpenNewTab = (url: string) => {
        window.open(url, "_blank", "noopener, noreferrer");
    };

    return (
        <div className='px-5'>
            <div className='flex items-start gap-2'>
                <img className='w-12 h-12 rounded-full object-cover'
                    alt="아티스트 썸네일"
                    src={MEDIA_URL + artistImageUrl}
                />
                <div className='flex flex-col gap-1 flex-1'>
                    <div className='BBody18 text-Gray09'>{artistName}</div>
                    {jobs?.length! > 0 && (
                        <div className="MCaption14 text-Gray05 flex flex-wrap items-center">
                            {jobs.join(", ")}
                        </div>
                    )}
                    <div className='flex items-center gap-1'>
                        <div className='MCaption14 text-Gray05'>
                            <div>{`${birth?.split("-")[0]}년생 ${calculateAge()}세`}</div>
                        </div>
                        <div className='MCaption14 text-Gray05'>
                            {`${height}cm ${weight}kg`}
                        </div>
                    </div>
                    {(instagram || youtube) &&
                        <div className='flex items-center gap-2 flex-wrap'>
                            {instagram &&
                                <Instagram
                                    className='cursor-pointer'
                                    onClick={() => {
                                        if (
                                            instagram.startsWith(
                                                "https://www.instagram.com/"
                                            )
                                        ) {
                                            handleOpenNewTab(instagram);
                                        } else {
                                            handleOpenNewTab(
                                                `https://www.instagram.com/${instagram}`
                                            );
                                        }
                                    }}
                                />
                            }
                            {youtube &&
                                <Youtube
                                    className='cursor-pointer'
                                    onClick={() => {
                                        handleOpenNewTab(youtube);
                                    }}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
            {(!isArtist && !isMyArtist && !isShared) &&
                <FillButton
                    variant="Membership"
                    size="M"
                    onClick={onClickOffer}
                    className='w-full mt-4'
                    text="제안하기"
                />
            }
            {myProfile &&
                <LineButton
                    variant="normal"
                    size="M"
                    onClick={() => navigate("/artist/profile/edit")}
                    className='w-full mt-4'
                    text="프로필 수정"
                />
            }
        </div>
    );
};

export default ArtistInfoCard;