import React, { useState } from 'react';

import { useOffer } from 'apis/offer';
import ButtonBackground from 'components/ButtonBackground';
import { FillButton } from 'components/FillButton';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import Textarea from 'components/Textarea';
import { Toast } from 'hooks/useToast';
import { useLocation, useNavigate } from 'react-router-dom';

const SuggestDescription = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [suggestDescription, setSuggestDescription] = useState<string>('');

    const pageData = location?.state;

    const { artistName, memberId, profileId, productId, castingCallId } = pageData;

    const { mutate } = useOffer();

    const suggest = () => {
        mutate(
            {
                artistId: memberId,
                profileId: profileId,
                castingCallId: castingCallId,
                description: suggestDescription,
                deadlineDate: null,
            },
            {
                onSuccess: () => {
                    Toast.success(`아티스트에게 제안을 보냈어요.`);
                    navigate(-1);
                },
            }
        );
    };

    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={''} />
            <div className='BBody20 text-Gray09 px-5 py-1'>
                제안할 내용을 입력해주세요
            </div>
            <div className='px-5 pt-5'>
                <Textarea
                    placeholder={
                        `이메일, 카카오톡 아이디 등 ${artistName}님이 연락할 수 있는 연락처를 함께 적어주세요.`
                    }
                    value={suggestDescription}
                    onChange={(e) => {
                        setSuggestDescription(e.target.value);
                    }}
                    rows={7}
                    maxlength={500}
                />
                <div className='mb-4 BBody16'>
                    제안 유의사항
                </div>
                <ol className="list-disc pl-4 RCaption14 space-y-1 text-Gray05">
                    <li>보낸 제안은 수정/삭제할 수 없으니 신중히 작성해 주세요.</li>
                    <li>제안받은 아티스트는 멤버십 미가입 회원이어도 수락할 수 있어요.</li>
                    <li>작성된 제안 내용이 아래에 해당할 경우 위반 내역의 심각성과 횟수에 따라 서비스 제한 및 조치가 진행될 수 있습니다.
                        <ol className="list-[lower-alpha] pl-6">
                            <li>부적절하거나 타인에게 불쾌감을 줄 수 있는 제안 (비속어 또는 부적절한 표현, 특정 대상에 대한 차별/혐오적 표현, 선정적이거나 폭력적인 내용, 문자 및 기호의 단순 나열 또는 반복된 내용 등)</li>
                            <li>서비스와 전혀 무관한 내용</li>
                            <li>기타 관계 법령, 서비스 이용 약관에 위배되는 피드백</li>
                        </ol>
                    </li>
                    <li>제한 조치에 대하여 이의가 있는 경우 고객센터를 통해 이의를 제기할 수 있습니다.</li>
                </ol>
            </div>
            <ButtonBackground>
                <FillButton
                    onClick={() => {
                        if (productId) {
                            suggest()
                        } else {
                            navigate("/suggest/deadline", {
                                state: {
                                    description: suggestDescription,
                                    ...pageData
                                },
                                replace: true
                            })
                        }
                    }}
                    className='w-full'
                    variant="Membership"
                    size="B"
                    text='제안하기'
                />
            </ButtonBackground>
        </div>
    );
};

export default SuggestDescription;