import React, { useEffect, useMemo, useState } from "react";

import BottomPopup from "components/BottomPopup";

import { ReactComponent as BlankStar } from "assets/blankStar.svg";
import { ReactComponent as Star } from "assets/star.svg";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useInView } from "react-intersection-observer";
import ButtonBackground from "components/ButtonBackground";
import { format_mmdd } from "utils/onDateFormat";
import { LineButton } from "components/LineButton";
import { MY_ROLE } from "utils/onRoleStatus";
import ArtistCastingOfferCard from "./OfferProfile/ArtistCastingOfferCard";
import { useSentProfile, useSentProfiles } from "apis/deliveryProfile";

const ArtistSendProfile = () => {
  const [deliveryId, setDeliveryId] = useState<number | null>(null);
  const [detailPopup, setDetailPopup] = useState<boolean>(false)
  const [feedbackPopup, setFeedbackPopup] = useState<boolean>(false)

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const myRole = MY_ROLE();
  const isArtist = myRole === "ROLE_ARTIST";

  const {
    data,
    fetchNextPage,
    hasNextPage,
  } = useSentProfiles({
  });
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);



  const { data: deliveryData } = useSentProfile(deliveryId!)
  const detail = deliveryData?.data;

  const desc = [
    { id: 0, title: "보낸 날짜", desc: format_mmdd(detail?.createdAt!) },
    { id: 1, title: "진행 상태", desc: detail?.castingStatus?.label || detail?.status?.label },
  ];

  const ratingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div key={i + 1}>
          {i + 1 <= detail?.rating! ? <Star /> : <BlankStar />}
        </div>
      );
    }
    return result;
  };

  const allLists = useMemo(
    () =>
      data ? data?.pages?.flatMap((item) => item.data.content) : [],
    [data]
  );

  const groupByDate = (list: any[]) => {
    return list.reduce((acc, item) => {
      const date = item.createdAt.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  const groupedByDate = groupByDate(allLists);

  return (
    <>
      <HeaderWithBackButton title="보낸 프로필" />
      <div className="pb-24 pt-5">
        {allLists.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">보낸 프로필이 없어요.</div>
          </div>
        ) : (
          <div>
            {Object.keys(groupedByDate).map((date) => (
              <div key={date} className="mb-4">
                <div className="text-Gray05 RCaption14 px-5">{format_mmdd(date)}</div>
                <div>
                  {groupedByDate[date].map((sendItem: DeliveryProfile) => {
                    return (
                      <ArtistCastingOfferCard
                        isFeedback={sendItem.status.code === "FEEDBACK"}
                        key={sendItem?.deliveryProfileId}
                        onClick={() => {
                          setDeliveryId(sendItem.deliveryProfileId);
                          setDetailPopup(true)
                        }}
                        imageUrl={isArtist ? sendItem.staffThumbnailUrl : sendItem.thumbnailUrl}
                        isOffered={sendItem?.isOffered}
                        title={sendItem?.castingCallTitle ? `[${sendItem?.castingCallTitle}]에 프로필 보내기` : `[${sendItem.staffName}]님에게 ${sendItem.type.label}`}
                        subTitle={isArtist ? sendItem.staffName : sendItem.profileName} />
                    )
                  })}
                </div>
              </div>
            ))}
            {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
          </div>
        )}
      </div>
      {(deliveryId && detailPopup) &&
        <BottomPopup onClose={() => { setDetailPopup(false) }}>
          <div className="space-y-1">
            {detail?.isOffered &&
              <div className='bg-Blue01 MCaption10 inline-flex h-[18px] py-0.5 px-1 text-Blue04 items-center rounded'>제안</div>
            }
            {detail?.castingCallTitle &&
              <div className="BBody20 text-Gray09 flex-1">{detail?.castingCallTitle}</div>
            }
          </div>
          <div className="bg-Gray01 p-4 rounded-md mt-4">
            <div className="flex flex-col gap-1">
              {desc?.map((item) => {
                return (
                  <div key={item.id} className="flex items-start MCaption14 gap-4">
                    <div className="text-Gray05 w-[107px]">{item.title}</div>
                    <div className="text-Gray09 flex-11">{item.desc}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <ButtonBackground>
            <LineButton
              onClick={() => {
                setDetailPopup(false)
                detail?.status.code === "FEEDBACK" && setFeedbackPopup(true)
              }}
              variant="normal"
              size="L"
              className="w-full"
              text={detail?.status.code === "FEEDBACK" ? `프로필 피드백 확인하기` : "닫기"}
            />
          </ButtonBackground>
        </BottomPopup>
      }
      {(deliveryId && !detailPopup && feedbackPopup) &&
        <BottomPopup onClose={() => {
          setFeedbackPopup(false)
        }}>
          <div className="flex items-center gap-4">
            <img className="w-12 h-12 object-cover rounded-full" src={MEDIA_URL + detail?.staffThumbnailUrl!} alt="" />
            <div className="BBody20 text-Gray09">{detail?.staffName}님의 프로필 피드백</div>
          </div>
          {/* <div className="my-4 flex flex-col gap-4">
            <div className="flex items-end gap-1">
              <div className="RBody16">별점</div>
              <div className="text-Blue04 BBody16">{detail?.rating}/5</div>
            </div>
            <div className="flex items-center gap-4">
              {ratingStartValue()}
            </div>
          </div> */}
          {detail?.comments &&
            <div className="p-4 mt-4 rounded-md bg-Gray01 text-Gray09 max-h-64 break-words overflow-y-auto whitespace-break-spaces">
              {detail?.comments}
            </div>
          }
          <ButtonBackground>
            <LineButton
              onClick={() => {
                setFeedbackPopup(false);
                setDeliveryId(null);
              }}
              variant="normal"
              size="L"
              className="w-full"
              text={"닫기"}
            />
          </ButtonBackground>
        </BottomPopup>
      }
    </>
  );
};

export default ArtistSendProfile;

interface DeliveryProfile {
  deliveryProfileId: number | null;
  staffId: number | null;
  staffName: string;
  staffThumbnailUrl: string;
  castingCallId: number | null;
  castingCallTitle: string | null;
  status: {
    code: string;
    label: string;
  };
  type: {
    code: string;
    label: string;
  };
  createdAt: string;
  readAt: string | null;
  feedbackAt: string | null;
  readFeedbackAt: string | null;
  isOffered: boolean;
  profileName: string;
  thumbnailUrl: string;
}

