import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router';
import { useInView } from 'react-intersection-observer';

import { ReactComponent as SelectCastingCall } from "assets/selectCastingCall.svg";
import BottomButtonPopup from 'components/BottomButtonPopup';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import ReceivedProfileListCard from 'pages/Received/_components/ReceivedProfileListCard';
import { splitBirth } from 'utils/onBirth';
import { Toast } from 'hooks/useToast';

import { useCastingsByCastingCall, useChangeCastingStatus } from 'apis/castingStaff';
import BottomPopup from 'components/BottomPopup';
import ButtonBackground from 'components/ButtonBackground';
import { FillButton } from 'components/FillButton';
import { useScroll } from 'hooks/useScroll';
import useLocalStorage from 'use-local-storage';


const ButtonText: Record<string, string> = {
    SHORTLIST_SELECTED: "리스트업",
    FINALIST_SELECTED: "캐스팅 확정",
};

const CastingReferenceProcess = () => {
    const navigationType = useNavigationType();
    const location = useLocation();
    const navigate = useNavigate();

    const [confirmPass, setConfirmPass] = useState(false);


    const { title, castingCallId, status } = location?.state;

    const [activeTab, setActiveTab] = useState("SHORTLIST_SELECTED");
    const [selectMode, setSelectMode] = useState(false);
    const [selectedCastingIds, setSelectedCastingIds] = useState<number[]>([]);

    const scrollKey = `scrollIndex${location.pathname}`;
    const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);

    const { y } = useScroll();

    useEffect(() => {
        setScrollY(y);
    }, [y]);

    useEffect(() => {
        if (scrollY !== 0 && navigationType === "POP") {
            setTimeout(() => {
                window.scrollTo(0, scrollY);
            }, 100);
        }
    }, [navigationType, scrollY]);

    const processTab: { id: number, title: string; type: string; }[] = [
        { id: 0, title: "후보", type: "SHORTLIST_SELECTED" },
        { id: 1, title: "리스트업", type: "FINALIST_SELECTED" },
        { id: 2, title: "캐스팅 확정", type: "PASSED" },
    ];

    useEffect(() => {
        const savedTab = localStorage.getItem("castingRefProcessTab");
        if (savedTab && navigationType === "POP") {
            setActiveTab(savedTab);
        } else {
            setActiveTab("SHORTLIST_SELECTED");
            localStorage.removeItem("castingRefProcessTab")
        }

    }, [navigationType]);

    const handleTabChange = (newTab: string) => {
        if (activeTab !== newTab) {
            setActiveTab(newTab);
            localStorage.setItem("castingRefProcessTab", newTab);
        }
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useCastingsByCastingCall({
        id: castingCallId,
        status: activeTab,
    });

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    const allLists = useMemo(
        () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
        [data]
    );

    const { mutate } = useChangeCastingStatus();

    const getNextStatus = (status: string) => {
        const statusMap: Record<string, string> = {
            SHORTLIST_SELECTED: "FINALIST_SELECTED",
            FINALIST_SELECTED: "PASSED",
        };
        return statusMap[status] || "";
    };

    const changeCastingStatus = () => {
        const nextStatus = getNextStatus(activeTab);
        if (!nextStatus) return;

        const data = {
            castingCallId: castingCallId,
            status: nextStatus,
            castingIds: selectedCastingIds,
        };

        mutate(data, {
            onSuccess: () => {
                setSelectMode(false);
                setConfirmPass(false);
                setSelectedCastingIds([])
                refetch()
                Toast.success(nextStatus === "PASSED" ? "캐스팅 확정되었어요." : "프로필을 이동했어요.");
            }
        })
    }

    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={title} >
                {(allLists.length !== 0 && activeTab !== "PASSED" && status === "ACTIVE") &&
                    < div onClick={() => {
                        if (selectMode) {
                            setSelectedCastingIds([])
                        }
                        setSelectMode(selectMode => !selectMode)
                    }} className='text-Blue04 BCaption14 cursor-pointer'>
                        {selectMode ? "취소" : "단계이동"}
                    </div>
                }
            </HeaderWithBackButton >

            {!selectMode &&
                <div className='flex items-center border-b border-Gray03 mt-5 px-5'>
                    {processTab.map((item) => {
                        return (
                            <div
                                onClick={() => handleTabChange(item.type)}
                                className={`flex-1 pb-4 text-center cursor-pointer BBody14 text-Gray05 ${activeTab === item.type ? "border-Gray09 border-b-2 text-Gray09" : ""} `} key={item.id}>
                                {item.title}
                            </div>
                        )
                    })}
                </div>
            }

            {
                allLists.length === 0 ? (
                    <div className="mt-36 items-center text-center">
                        <div className="text-Gray05 RBody14">받은 프로필이 없어요.</div>
                    </div>
                ) : (
                    <div>
                        {allLists.map((item) => {
                            return (
                                <ReceivedProfileListCard
                                    key={item.castingId}
                                    thumbnail={item.thumbnailUrl}
                                    createdAt={item.createdAt}
                                    readAt={item?.readAt}
                                    name={item.profileName}
                                    birth={`${splitBirth(item.birthDate)}`}
                                    selectMode={selectMode}
                                    height={item.height}
                                    weight={item.weight}
                                    description={item.description}
                                    selected={selectedCastingIds.includes(
                                        item.castingId
                                    )}
                                    isOffered={item.isOffered}
                                    onClick={() => {
                                        if (selectMode) {
                                            if (
                                                selectedCastingIds.includes(item.castingId)
                                            ) {
                                                setSelectedCastingIds(
                                                    selectedCastingIds.filter(
                                                        (id) => id !== item.castingId
                                                    )
                                                );
                                            } else {
                                                setSelectedCastingIds([
                                                    ...selectedCastingIds,
                                                    item.castingId,
                                                ]);
                                            }
                                        } else {
                                            // if (readAt === null) {
                                            //     profileRead(item?.profileId);
                                            // }
                                            navigate(
                                                `/staff/shortlist/${item.profileId}`,
                                                {
                                                    state: {
                                                        castingCallId: castingCallId,
                                                        castingId: item?.castingId,
                                                    }
                                                }
                                            );
                                        }
                                    }}
                                />
                            );
                        })}
                        {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                    </div>
                )
            }

            {confirmPass &&
                <BottomPopup onClose={() => { }}>
                    <div className='BBody20'>
                        선택한 프로필을 캐스팅 확정할까요
                    </div>
                    <div className='MBody14 text-Gray05 mt-4'>
                        캐스팅을 확정하면 확정된 아티스트와 참조된 스태프에게 알림이 전송되며, 이후에는 확정을 취소할 수 없어요.
                    </div>
                    <ButtonBackground>
                        <FillButton
                            onClick={() => {
                                setConfirmPass(false);
                            }}
                            variant="Secondary"
                            size="B"
                            text='닫기'
                        />
                        <FillButton
                            onClick={() => {
                                changeCastingStatus()
                            }}
                            variant="Membership"
                            size="B"
                            text="확정하기"
                        />
                    </ButtonBackground>
                </BottomPopup>
            }

            {
                selectedCastingIds.length > 0 &&
                <BottomButtonPopup onClick={() => {
                    if (activeTab === "FINALIST_SELECTED") {
                        setConfirmPass(true);
                        return;
                    }
                    changeCastingStatus()
                }} onClose={() => { }}>
                    <div
                        className="flex flex-col gap-1 pt-2 justify-center items-center MCaption14 cursor-pointer text-Gray00"
                    >
                        <SelectCastingCall />
                        {selectedCastingIds.length}명 {ButtonText[activeTab] || ""}하기</div>
                </BottomButtonPopup>
            }

        </div >
    );
};

export default CastingReferenceProcess;