import React from 'react';

interface checkboxCircleType {
    title?: string;
    className?: string;
    checked?: boolean;
    id?: string;

    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxCircle = ({
    title, className, checked, id, onClick, onChange
}: checkboxCircleType) => {
    return (
        <div className="flex items-center">
            <input
                checked={checked}
                onChange={onChange}
                id={id}
                type="checkbox"
                className="appearance-none w-[18px] h-[18px] bg-circleCheck checked:bg-circleChecked bg-no-repeat bg-center"
            />
            {title &&
                <label htmlFor={id} className={`ml-2 ${className} flex-1 MCaption14`}>
                    {title}
                </label>
            }
        </div>
    );
};

export default CheckboxCircle