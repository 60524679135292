import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";

// QR 코드 스캔
const getArtistDelivery = async (id: number) => {
  return await requestToCastingVote
    .get(`qrcode/${id}/artist`)
    .then((res) => res.data);
};

const useArtistDelivery = (id: number) => {
  return useQuery({
    queryKey: ["artistDelivery", id],
    queryFn: () => getArtistDelivery(id),
  });
};

export { useArtistDelivery };
