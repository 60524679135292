import React, { useEffect, useMemo } from "react";

import PointCard from "components/PointCard";
import HeaderWithBackButton from "components/HeaderWithBackButton";

import { usePointHistory } from "apis/point";
import { useInView } from "react-intersection-observer";

const PointUsageHistory = () => {
  const { data, fetchNextPage, hasNextPage } = usePointHistory({
    size: 24,
  });

  const usage = useMemo(
    () =>
      data ? data?.pages?.flatMap((item) => item.data.content) : [],
    [data]
  );

  const {
    ref,
    inView,
  } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div>
      <HeaderWithBackButton title={"사용 내역"} />
      <div className="p-5 pb-24">
        {usage.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">사용내역이 없어요.</div>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {usage.map((item, i) => {
              return (
                <PointCard
                  desc={item.description}
                  price={`${item.point}포인트`}
                  paymentDate={item.createAt}
                  key={i}
                />
              );
            })}
            {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
          </div>
        )}
      </div>
    </div>
  );
};

export default PointUsageHistory;
