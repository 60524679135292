import React, { useEffect, useState } from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import { useLocation, useNavigationType } from 'react-router';
import CastingApplied from './CastingApplied';
import CastingShortlist from './CastingShortlist';
import { useScroll } from 'hooks/useScroll';
import useLocalStorage from 'use-local-storage';

const CastingProcess = () => {
    const location = useLocation();
    const navigationType = useNavigationType();

    const { title, castingCallId, status } = location?.state;

    const [activeTab, setActiveTab] = useState("APPLIED");
    const [selectMode, setSelectMode] = useState(false);
    const [selectedCastingIds, setSelectedCastingIds] = useState<number[]>([]);

    const scrollKey = `scrollIndex${location.pathname}`;
    const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);

    const { y } = useScroll();

    useEffect(() => {
        setScrollY(y);
    }, [y]);

    useEffect(() => {
        if (scrollY !== 0 && navigationType === "POP") {
            setTimeout(() => {
                window.scrollTo(0, scrollY);
            }, 100);
        }
    }, [navigationType, scrollY]);

    const processTab: { id: number, title: string; type: string; }[] = [
        { id: 0, title: "지원", type: "APPLIED" },
        { id: 1, title: "후보", type: "SHORTLIST_SELECTED" },
        { id: 2, title: "리스트업", type: "FINALIST_SELECTED" },
        { id: 3, title: "캐스팅 확정", type: "PASSED" },
    ];

    useEffect(() => {
        const savedTab = localStorage.getItem("castingProcessTab");
        if (savedTab && navigationType === "POP") {
            setActiveTab(savedTab);
        } else {
            setActiveTab("APPLIED");
            localStorage.removeItem("castingProcessTab")
        }

    }, [navigationType]);

    const handleTabChange = (newTab: string) => {
        if (activeTab !== newTab) {
            setActiveTab(newTab);
            localStorage.setItem("castingProcessTab", newTab);
        }
    };

    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={title} />

            {!selectMode &&
                <div className='flex items-center border-b border-Gray03 mt-5 px-5'>
                    {processTab.map((item) => {
                        return (
                            <div
                                onClick={() => handleTabChange(item.type)}
                                className={`flex-1 pb-4 text-center cursor-pointer BBody14 text-Gray05 ${activeTab === item.type ? "border-Gray09 border-b-2 text-Gray09" : ""} `} key={item.id}>
                                {item.title}
                            </div>
                        )
                    })}
                </div>
            }

            {activeTab === "APPLIED" ?
                <CastingApplied
                    status={status}
                    activeTab={activeTab}
                    castingcallId={castingCallId}
                    selectMode={selectMode}
                    setSelectMode={setSelectMode}
                    selectedCastingIds={selectedCastingIds}
                    setSelectedCastingIds={setSelectedCastingIds}
                />
                : <CastingShortlist
                    status={status}
                    activeTab={activeTab}
                    castingcallId={castingCallId}
                    selectMode={selectMode}
                    setSelectMode={setSelectMode}
                    selectedCastingIds={selectedCastingIds}
                    setSelectedCastingIds={setSelectedCastingIds}
                />
            }
        </div>
    );
};

export default CastingProcess;