import useOutSideClick from "hooks/onOutSideClick";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface SideTabType {
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

function SideTabContainer({ children }: { children: React.ReactNode }) {
  return createPortal(<>{children}</>, document.getElementById("sidetab")!);
}

const SideTab = ({ className, children, onClose, isOpen }: SideTabType) => {
  const sideTabRef = useRef<HTMLDivElement>(null);

  const handleClose = () => onClose();

  useOutSideClick(sideTabRef, handleClose);

  const sideTabClass = isOpen ? "translate-x-0" : "translate-x-full";

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <SideTabContainer>
      <div
        className={`fixed inset-0 z-50 bg-Gray09/40 ${isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          } transition-opacity duration-300`}
      >
        <div
          ref={sideTabRef}
          className={`fixed right-0 top-0 bottom-0 w-[400px] max-w-full bg-white transform transition-transform duration-300 ${sideTabClass} ${className}`}
        >
          <div>{children}</div>
        </div>
      </div>
    </SideTabContainer>
  );
};

export default SideTab;
