import React from 'react';
import CheckboxSquare from 'components/Checkbox/CheckboxSquare';

import { ReactComponent as BlankStar } from "assets/Star_empty.svg";
import { ReactComponent as Star } from "assets/star.svg";
import { ReactComponent as P } from "assets/premium.svg";

interface ReceivedProfileListCardType {
    readAt?: string | null;
    createdAt?: string | null;
    thumbnail?: string;

    isPremium?: boolean;
    name: string;
    birth: string;
    weight: string;
    height: string;
    description?: string;

    selectMode: boolean;
    selected?: boolean;
    isOffered?: boolean;
    isMyCasting?: boolean;
    rating?: number;

    onClick?: () => void;
}

const ReceivedProfileListCard = ({
    readAt,
    createdAt,
    thumbnail,
    name,
    birth,
    weight,
    height,
    description,
    selectMode,
    selected,
    isOffered,
    isMyCasting,
    rating,
    onClick
}: ReceivedProfileListCardType) => {


    const readRatingStartValue = () => {
        let result = [];
        for (let i = 0; i < 5; i++) {
            result.push(
                <div key={i + 1}>
                    {i + 1 <= rating! ? <Star className='w-[18px] h-[18px]' /> : <BlankStar className='w-[18px] h-[18px]' />}
                </div>
            );
        }
        return result;
    };
    const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

    return (
        <div onClick={onClick} className='w-full cursor-pointer'>
            <div className={`px-5 py-3 flex items-center justify-between h-10 ${readAt !== null ? "bg-Gray01" : "bg-Blue01/50"} `}>
                <div className='flex items-center gap-2'>
                    {isOffered &&
                        <div className='bg-Blue01 MCaption10 inline-flex h-[18px] py-0.5 px-1 text-Blue04 items-center rounded'>제안</div>
                    }
                    <div className="flex items-center">
                        {rating ?
                            readRatingStartValue()
                            : ""}
                    </div>
                </div>
                <div className='flex items-start'>
                    {selectMode &&
                        <CheckboxSquare checked={selected} />
                    }
                    <div className='flex items-center gap-2'>
                        <div className='text-Gray05 MCaption12'>{createdAt?.split("T")[0]}</div>
                        {!selectMode && isMyCasting ?
                            <>
                                {readAt !== null ?
                                    <div className='text-Gray05 MCaption12'>열람</div>
                                    :
                                    <div className='text-Blue04 BCaption12'>미열람</div>
                                }
                            </>
                            : <div />}
                    </div>
                </div>
            </div>
            <div className='px-5 py-4 flex items-start gap-4 cursor-pointer'>
                <img className='w-full aspect-4/5 max-w-[114px] object-cover rounded-md' src={`${MEDIA_URL}${thumbnail}`} alt="프로필 사진" />
                <div className='flex flex-col gap-1 flex-1 min-w-[150px]'>
                    <div className='flex items-end gap-1'>
                        <div className='BBody16 text-Gray09'>
                            {name}
                        </div>
                        <div className='MCaption14 text-Gray05'>
                            {birth}
                        </div>
                    </div>
                    <div className='flex items-end gap-2 MCaption12'>
                        <div className='text-Gray05'>
                            키
                        </div>
                        <div>
                            {height}cm
                        </div>
                    </div>
                    <div className='flex items-end gap-2 MCaption12'>
                        <div className='text-Gray05'>
                            몸무게
                        </div>
                        <div>
                            {weight}kg
                        </div>
                    </div>
                    {description &&
                        <div className='MCaption14 max-h-[62px] text-Gray09 break-words overflow-hidden text-ellipsis'>
                            {description}
                        </div>
                    }
                </div>
            </div>
        </div >
    );
};

export default ReceivedProfileListCard;