import React, { useEffect, useState } from "react";

import ButtonBackground from "components/ButtonBackground";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import PointChargeCard from "components/PointCharge";
import requestToCastingVote from "../utils/requestToCastingVote";

import { FillButton } from "components/FillButton";
import { useSearchParams } from "react-router";
import { Toast } from "hooks/useToast";
import { usePoint } from "apis/point";
import { useMyInfo } from "apis/my";
import CheckboxCircle from "components/Checkbox/CheckboxCircle";

const PointCharge = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const URL = process.env.REACT_APP_URL;

  const [searchParams] = useSearchParams();
  const message = searchParams?.get("message");

  useEffect(() => {
    Toast.error(message);
  }, [message]);

  const [selectedPoint, setSelectedPoint] = useState<string | null>("");
  const [agreeCharge, setAgreeCharge] = useState<boolean>(false);

  const { data } = useMyInfo();
  const info = data?.data;

  const { data: myPoint, isLoading } = usePoint();

  const pointType = [
    { id: 0, price: "11000" },
    { id: 1, price: "33000" },
    { id: 2, price: "55000" },
    { id: 3, price: "110000" },
  ];

  const payCheck = selectedPoint !== "" && agreeCharge === true;

  const handlePaymentRequest = () => {
    const requestData = {
      totalPrice: selectedPoint,
      orderName: "포인트",
      paymentCategory: "POINT",
      pgType: "WELCOME",
      deviceType: "PC",
      orderAmount: selectedPoint,
    };

    requestToCastingVote({
      method: "POST",
      url: "payments/welcomepay",
      data: requestData,
    }).then((res) => {
      const welcomePayReq = res.data.data;
      if (window?.INIStdPay) {
        const form = document.getElementById(
          "sendPayFormId"
        ) as HTMLFormElement;
        if (!form) return;

        if (welcomePayReq) {
          (form.elements.namedItem("signature") as HTMLInputElement).value =
            welcomePayReq.signature;
          (form.elements.namedItem("timestamp") as HTMLInputElement).value =
            welcomePayReq.timestamp;
          (form.elements.namedItem("mKey") as HTMLInputElement).value =
            welcomePayReq.mKey;
          (form.elements.namedItem("oid") as HTMLInputElement).value =
            welcomePayReq.orderId;
          (form.elements.namedItem("mid") as HTMLInputElement).value =
            welcomePayReq.mid;
          (form.elements.namedItem("price") as HTMLInputElement).value =
            welcomePayReq.price;
        }

        window.INIStdPay.pay("sendPayFormId");
      } else {
        alert("INIStdPay.js가 로드되지 않았습니다.");
      }

    }
    ).catch((err) => {
      Toast.error(err.response.data.message);
    })
  };

  const welcomePayMobileRequest = async () => {
    const requestData = {
      totalPrice: selectedPoint,
      orderName: "포인트",
      paymentCategory: "POINT",
      pgType: "WELCOME",
      deviceType: "MOBILE",
      orderAmount: selectedPoint,
    };

    await requestToCastingVote({
      method: "POST",
      url: "payments/welcomepay",
      data: requestData,
    }).then((res) => {
      const welcomePayReq = res.data.data;
      const form = document.getElementById(
        "sendPayFormMobile"
      ) as HTMLFormElement;
      if (!form) return;

      if (welcomePayReq) {
        // P_UNAME, P_MNAME, P_GOODS, P_MOBILE, P_EMAIL form에 데이터 추가 필요
        (form.elements.namedItem("P_SIGNATURE") as HTMLInputElement).value =
          welcomePayReq.signature;
        (form.elements.namedItem("P_TIMESTAMP") as HTMLInputElement).value =
          welcomePayReq.timestamp;
        (form.elements.namedItem("P_OID") as HTMLInputElement).value =
          welcomePayReq.orderId;
        (form.elements.namedItem("P_NOTI") as HTMLInputElement).value =
          welcomePayReq.orderId;
        (form.elements.namedItem("P_MID") as HTMLInputElement).value =
          welcomePayReq.mid;
        (form.elements.namedItem("P_AMT") as HTMLInputElement).value =
          welcomePayReq.price;
      }

      // 테스트 결제 url
      // form.action = "https://tmobile.paywelcome.co.kr/smart/wcard/";
      //실제 결제 url
      form.action = "https://mobile.paywelcome.co.kr/smart/wcard/";
      form.target = "_self";

      form.submit();
    }).catch((err) => {
      Toast.error(err.response.data.message);
    })
  };

  return (
    <>
      <HeaderWithBackButton title={"포인트"} />
      <div className="p-5 pb-24">
        <div>
          <div className="flex items-center gap-2">
            <div className="text-Gray05 MBody16">내 포인트</div>
            <div className="text-Blue04 BBody18">
              {myPoint?.data ? myPoint?.data?.toLocaleString() : "0"} P
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            {pointType.map((item) => {
              return (
                <PointChargeCard
                  onClick={() => {
                    if (selectedPoint === item.price) {
                      setSelectedPoint("");
                    } else {
                      setSelectedPoint(item.price);
                    }
                  }}
                  selected={selectedPoint === item.price}
                  key={item.id}
                  price={item.price}
                />
              );
            })}
          </div>
        </div>
        <div className="p-4 bg-Gray01 my-5 rounded-md">
          <ol className="list-disc list-inside MBody14 text-Gray05">
            <li>100포인트는 100원이에요.</li>
            <li>포인트를 사용하지 않은 경우 7일 이내 취소할 수 있어요.</li>
            <li>결제 7일 이후에 수수료 10%를 제외한 차액이 환불돼요.</li>
          </ol>
        </div>
        <div>
          <CheckboxCircle
            onChange={(e) => {
              setAgreeCharge(e.target.checked);
            }}
            checked={agreeCharge}
            id="1"
            title={
              "상품, 가격, 할인 정보, 유의 사항 등을 확인하였으며 구매에 동의합니다. (필수)"
            }
          />
        </div>
        <ButtonBackground>
          <FillButton
            isLoading={isLoading}
            disabled={!payCheck}
            onClick={() => {
              const UserAgent = navigator.userAgent;
              if (
                UserAgent.match(
                  /iPhone|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
                ) != null ||
                UserAgent.match(/LG|SAMSUNG|Samsung/) != null
              ) {
                welcomePayMobileRequest();
              } else {
                handlePaymentRequest();
              }
            }}
            variant="Membership"
            size={"B"}
            text={`${selectedPoint !== ""
              ? Number(selectedPoint).toLocaleString("ko-KR")
              : ""
              }포인트 충전하기`}
          />
        </ButtonBackground>
      </div>
      <form id="sendPayFormId" method="POST">
        <input type="hidden" name="version" value="1.0" />
        <input type="hidden" name="mid" value="welcometst" />
        <input type="hidden" name="goodname" value="point" />
        <input type="hidden" name="oid" value="welcometst_1733221645882" />
        <input type="hidden" name="price" value="10" />
        <input type="hidden" name="currency" value="WON" />
        <input type="hidden" name="buyername" value={info?.name} />
        <input type="hidden" name="buyertel" value={info?.phoneNumber} />
        <input type="hidden" name="buyeremail" value={info?.email} />
        <input type="hidden" name="timestamp" value="API" />
        <input type="hidden" name="signature" value="API" />
        <input
          type="hidden"
          name="returnUrl"
          value={`${API_URL}/payments/welcomepay/return`}
        />
        <input type="hidden" name="mKey" value="API" />
        {/* <input
          type="hidden"
          name="closeUrl"
          value={`http://localhost:3000/welcomepay/close`}
        /> */}
        <input
          type="hidden"
          name="closeUrl"
          value={`${URL}/welcomepay/close`}
        />
        <input type="hidden" name="popupUrl" value="http://localhost:3000" />
        <input type="hidden" name="gopaymethod" value="Card" />
      </form>

      <form id="sendPayFormMobile" method="POST" accept-charset="euc-kr">
        <input type="hidden" name="MID" value="welcometst" />
        <input type="hidden" name="P_MID" value="API" />
        <input type="hidden" name="P_OID" value="API" />
        <input type="hidden" name="P_NOTI" value="welcometst_1733221645882" />
        <input type="hidden" name="P_AMT" value="10" />
        <input type="hidden" name="P_UNAME" value={info?.name} />
        <input type="hidden" name="P_MNAME" value="castingvote" />
        <input type="hidden" name="P_GOODS" value="point" />
        <input type="hidden" name="P_MOBILE" value={info?.phoneNumber} />
        <input type="hidden" name="P_EMAIL" value={info?.email} />
        <input type="hidden" name="P_CHARSET" value="utf8" />
        <input
          type="hidden"
          name="P_NEXT_URL"
          value={`${API_URL}/payments/welcomepay/mobile/return`}
        />
        <input type="hidden" name="P_RETURN_URL" value={`${API_URL}`} />
        <input
          type="hidden"
          name="P_NOTI_URL"
          value="http://10.10.1.111:8080/api/payments/welcomepay/mobile/return"
        />
        <input type="hidden" name="P_TIMESTAMP" value="1733221645882" />
        <input
          type="hidden"
          name="P_SIGNATURE"
          value="67f64aa43b227f7e019b75768678edb9e954e7ebc77b9e3291b84f9052ebdd4c"
        />
        <input type="hidden" name="P_HPP_METHOD" value="2" />
        <input
          type="hidden"
          name="P_RESERVED"
          value="twotrs_isp=Y&twotrs_isp_noti=N&apprun_check=Y&below1000=Y"
        />
      </form>
    </>
  );
};

export default PointCharge;
