import React, { useEffect, useState } from "react";

import { ReactComponent as Bookmark } from "assets/Bookmark.svg";

import NoProfile from "assets/noProfile.png";

import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Button from "components/Button";
import ScrollToTop from "components/ScrollToTop";
import ArtistInfoCard from "./_components/ArtistInfoCard";
import ArtistAttachments from "./_components/ArtistAttachments";
import ArtistDesc from "./_components/ArtistDesc";
import ArtistFilmographies from "./_components/ArtistFilmographies";
import Skeleton from "components/Skeleton";
import { useMyProfile } from "apis/my";

const ArtistMy = () => {
  const navigate = useNavigate();
  const [tabId, setTabId] = useState(0);

  const { data, isFetching } = useMyProfile();

  const profileTab = [
    { id: 0, title: "프로필" },
    // { id: 1, title: "포트폴리오" },
  ]

  const ArtistData = data?.data;

  return (
    <>
      <HeaderWithBackButton title={""} />
      {isFetching ? (
        <Skeleton type="PROFILE_DETAIL" />
      ) : (
        <div>
          {ArtistData === null ? (
            <div className="text-center px-5 mx-auto flex-col p-[70px]">
              <img
                className="w-[60px] h-[60px] mx-auto"
                src={NoProfile}
                alt="NoProfile"
              />
              <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
                아직 프로필이 없어요.
                <br />
                프로필을 등록하면 캐스팅을 시작할 수 있어요!
              </div>
              <Button
                className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
                text={"프로필 등록하기"}
                onClick={() => {
                  navigate("/artist/profile");
                }}
              />
            </div>
          ) : (
            <div className="pt-5 pb-24">
              <ScrollToTop />
              <div className="w-full">
                <ArtistInfoCard
                  artistName={ArtistData?.profileName!}
                  artistImageUrl={ArtistData?.thumbnailUrl!}
                  jobs={ArtistData?.jobs!}
                  birth={ArtistData?.birthDate!}
                  height={ArtistData?.basicInfo?.height!}
                  weight={ArtistData?.basicInfo?.weight!}
                  instagram={ArtistData?.snsInfo?.instagram!}
                  youtube={ArtistData?.snsInfo?.youtube!}
                  myProfile
                />
                <div className="border-b border-Gray-3 mt-4">
                  <div className="px-5 flex items-start gap-4 BCaption14">
                    {profileTab.map((item) => {
                      return (
                        <div key={item.id}
                          onClick={() => { setTabId(item.id) }}
                          className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                          {item.title}
                        </div>
                      )
                    })}
                  </div>
                </div>
                {tabId === 0 ? (
                  <div>
                    <ArtistAttachments
                      attachments={ArtistData?.attachments!}
                      videoLinks={ArtistData?.videoLinks!} />
                    <ArtistDesc
                      hobbies={ArtistData?.hobbies!}
                      address={ArtistData?.basicInfo?.address?.sido!}
                      education={ArtistData?.basicInfo?.education!}
                      agency={ArtistData?.basicInfo.agency!}
                      introduce={ArtistData?.introduce!}
                    />
                    {ArtistData?.filmographies?.length! > 0 &&
                      <ArtistFilmographies filmographies={ArtistData?.filmographies!} />
                    }
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ArtistMy;
