import React, { useEffect, useState } from 'react';

import { useArtistDelivery } from 'apis/qrcode';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import ScrollToTop from 'components/ScrollToTop';
import Skeleton from 'components/Skeleton';
import { useNavigate, useParams } from 'react-router';
import ArtistAttachments from './_components/ArtistAttachments';
import ArtistDesc from './_components/ArtistDesc';
import ArtistFilmographies from './_components/ArtistFilmographies';
import ArtistInfoCard from './_components/ArtistInfoCard';
import useLoginInfo from 'stores/loginInfo';
import { Toast } from 'hooks/useToast';

const ArtistDelivery = () => {
    const navigate = useNavigate();
    const params = useParams();
    const qrcodeId = params.id;

    const { loginInfo } = useLoginInfo();


    const [tabId, setTabId] = useState(0);

    const profileTab = [
        { id: 0, title: "프로필" },
    ]

    const { data, isFetching, status } = useArtistDelivery(Number(qrcodeId) as number);

    const ArtistData = data?.data;

    useEffect(() => {
        if (status === "error") {
            navigate("/", { replace: true });
        } else if (status === "success" && data) {
            Toast.success("프로필 확인 완료 !");
        }
    }, [status, data]);
    return (
        <>
            <HeaderWithBackButton
                phone={ArtistData?.phoneNumber}
                sms={ArtistData?.phoneNumber}
                email={ArtistData?.email}
                title={""} />

            {isFetching ? (
                <Skeleton type="PROFILE_DETAIL" />
            ) : (
                <div className="pt-5 pb-24">
                    <ScrollToTop />
                    <div className="w-full">
                        <ArtistInfoCard
                            artistName={ArtistData?.profileName!}
                            artistImageUrl={ArtistData?.thumbnailUrl!}
                            jobs={ArtistData?.jobs!}
                            birth={ArtistData?.birthDate!}
                            height={ArtistData?.basicInfo?.height!}
                            weight={ArtistData?.basicInfo?.weight!}
                            instagram={ArtistData?.snsInfo?.instagram!}
                            youtube={ArtistData?.snsInfo?.youtube!}
                            memberId={ArtistData?.memberId}
                            onClickOffer={() => {
                                if (loginInfo?.profileId === null) {
                                    navigate("/staff/my");
                                } else {
                                    navigate("/suggest/product", {
                                        state: {
                                            memberId: ArtistData?.memberId,
                                            profileId: ArtistData?.profileId,
                                            artistName: ArtistData?.profileName,
                                        },
                                    });
                                }
                            }}
                        />
                        <div className="border-b border-Gray-3 mt-4">
                            <div className="px-5 flex items-start gap-4 BCaption14">
                                {profileTab.map((item) => {
                                    return (
                                        <div key={item.id}
                                            onClick={() => { setTabId(item.id) }}
                                            className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                                            {item.title}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {tabId === 0 ? (
                            <div>
                                <ArtistAttachments
                                    attachments={ArtistData?.attachments!}
                                    videoLinks={ArtistData?.videoLinks!} />
                                <ArtistDesc
                                    hobbies={ArtistData?.hobbies!}
                                    address={ArtistData?.basicInfo.address.sido!}
                                    education={ArtistData?.basicInfo.education!}
                                    agency={ArtistData?.basicInfo.agency!}
                                    introduce={ArtistData?.introduce!}
                                />
                                {ArtistData?.filmographies?.length! > 0 &&
                                    <ArtistFilmographies filmographies={ArtistData?.filmographies!} />
                                }
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                </div>

            )}
        </>
    );
};

export default ArtistDelivery;