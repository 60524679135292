import React, { useState } from "react";

import ButtonBackground from "components/ButtonBackground";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { LineButton } from "components/LineButton";
import Radio from "components/Radio";
import Textarea from "components/Textarea";
import { Toast } from "hooks/useToast";
import { useDeleteMember } from "apis/member";
import { useLogout } from "apis/user";
import useLoginInfo from "stores/loginInfo";

const MemberWithdrawal = () => {
  const { deleteLoginInfo, setIsLogin } = useLoginInfo();
  const [reasonRadio, setReasonRadio] = useState("");
  const [reasonText, setReasonText] = useState("");
  const reason = [
    { id: 0, title: "서비스 방문을 잘 하지 않아요" },
    { id: 1, title: "서비스 이용 방법이 어려워요" },
    { id: 2, title: "새로운 계정을 만들었어요" },
    { id: 3, title: "다른 서비스를 더 많이 이용해요" },
    { id: 4, title: "캐스팅 과정이 불편해요" },
    // { id: 5, title: "기타" },
  ];

  const { mutate, isPending } = useDeleteMember();
  const { mutate: logout } = useLogout();

  const handleSubmit = () => {
    if (!reasonRadio) {
      Toast.error("탈퇴 사유를 입력해주세요.");
      return;
    }
    mutate(reasonRadio,
      {
        onSuccess: (data) => {
          Toast.success("탈퇴 되었어요.");
          logout();
          deleteLoginInfo();
          setIsLogin(false);
        },
      }
    );
  };

  return (
    <div>
      <HeaderWithBackButton title={"탈퇴하기"} />
      <div className="px-5 pt-5 pb-24">
        <div className="text-Gray09 RBody16 whitespace-pre-wrap break-keep">
          {`그동안 캐스팅보트를 이용해주셔서 감사드려요.\n서비스 사용 시 느끼셨던 불편함을 저희에게 알려주시면 더욱 발전하는 캐스팅보트가 될게요.`}
        </div>
        <div className="flex flex-col my-4 items-start gap-2">
          {reason.map((item) => {
            return (
              <Radio
                checked={item.title === reasonRadio}
                key={item.id}
                title={item.title}
                value={item.title}
                onChange={(e) => {
                  setReasonText("");
                  setReasonRadio(e.target.value);
                }}
                name={item.title}
                id={item.title}
              />
            );
          })}
        </div>
        {reasonRadio === "기타" && (
          <Textarea
            value={reasonText}
            onChange={(e) => {
              setReasonText(e.target.value);
            }}
            rows={7}
            maxlength={200}
          ></Textarea>
        )}
        <div className="w-full p-4 rounded-md overflow-scroll bg-Gray01 text-Gray05">
          <ol className="list-disc list-inside RCaption14">
            <li>회원탈퇴 후 같은 아이디로 재가입이 불가능해요.</li>
            <li>
              회원탈퇴 시 포인트는 자동 환불되지 않으니 탈퇴 전에 꼭 고객센터에
              문의해 주세요.
            </li>
          </ol>
        </div>
      </div>
      <ButtonBackground>
        <LineButton
          onClick={() => {
            handleSubmit();
          }}
          isLoading={isPending}
          variant="normal"
          className="w-full"
          size="L"
          text="탈퇴하기"
        />
      </ButtonBackground>
    </div>
  );
};

export default MemberWithdrawal;
