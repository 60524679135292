import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MembershipLogo from 'assets/MembershipLogo.png'
import CVLogo from "assets/CastingVote_logo_white.png";

import CastingList from "assets/casting_list.png"
import ManagerBanner from "assets/managementBanner.png"

import { ReactComponent as BlackButtonArrow } from 'assets/blackButtonRight.svg'
import { ReactComponent as PlusInMembership } from 'assets/plusInMembership.svg'

import Up1 from "assets/up_1.png"
import Up2 from "assets/up_2.png"
import Up3 from "assets/up_3.png"
import Up4 from "assets/up_4.png"
import Up5 from "assets/up_5.png"
import Up6 from "assets/up_6.png"
import Up7 from "assets/up_7.png"
import Up8 from "assets/up_8.png"
import Up9 from "assets/up_9.png"

import Down1 from "assets/down_1.png"
import Down2 from "assets/down_2.png"
import Down3 from "assets/down_3.png"
import Down4 from "assets/down_4.png"
import Down5 from "assets/down_5.png"
import Down6 from "assets/down_6.png"
import Down7 from "assets/down_7.png"
import Down8 from "assets/down_8.png"
import Down9 from "assets/down_9.png"

import Fnc from 'assets/Fnc.png'
import High from 'assets/High.png'
import We from 'assets/We.png'
import BigBoss from 'assets/BigBoss.png'
import Ljuly from 'assets/Ljuly.png'
import Compass from 'assets/Acompass.png'
import Yk from 'assets/YkMedia.png'

import HeaderWithBackButton from "components/HeaderWithBackButton";

import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useInView } from "react-intersection-observer";
import useCountUp from "hooks/useCountUp";

const Membership = () => {
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [position, setPosition] = useState(window.pageYOffset);
  const [whiteHeader, setWhiteHeader] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const moving = window.pageYOffset;
      setWhiteHeader(position < 3045);
      setPosition(moving);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [position]);

  const collaborator = [
    Fnc, High, We, BigBoss, Ljuly, Compass, Yk
  ]

  const up = [
    Up1, Up2, Up3, Up4, Up5, Up6, Up7, Up8, Up9
  ]
  const down = [
    Down1, Down2, Down3, Down4, Down5, Down6, Down7, Down8, Down9
  ]

  const reviews = [
    { id: 0, name: "이충선", job: "캐스팅디렉터", agency: "팀에피퍼니", desc: "예전부터 이런 플랫폼을 기다리고 있었어요. 연기자들에게 양질의 정보를 투명하게 공개하고 캐스팅디렉터는 카톡, 이메일로 프로필을 받고 엑셀, PPT 등으로 정리하는 시스템이 아닌 한 곳에서 정리된 지원자 리스트를 확인하면 좋을 것 같았는데 캐스팅보트를 통해 업무 효율성이 높아졌습니다. 캐스팅 공고를 올리면 배우들이 보낸 프로필이 리스트업되고 피드백까지 해줄 수 있는 플랫폼이 있어서 참 좋습니다." },
    { id: 1, name: "전OO", job: "배우지망생", agency: "", desc: "멤버십 가입은 하지 않고 우선 프로필만 등록해놨었는데 캐디님께 제안이 왔어요..! 처음엔 믿기지가 않고 이게 진짜인가? 싶었는데 오디션 보고 촬영까지 들어가니까 실감이 나네요🫢 주변 친구들이 좋다고 했을 때도 다른 오디션 사이트랑 비슷하겠지 싶어서 크게 관심 없었는데 앞으로 다른 곳 말고 여기만 쓸래요ㅠㅠ 캐스팅되어보니까 다른 곳처럼 작품정보 전부 비공개에 여기저기서 정보들 끌어온 게 아니라 찐찐 캐디분들이 계시는 사이트라는 걸 알게되었어요... 최고예요 이제 제가 친구들한테 영업하고 다녀요;;" },
    { id: 2, name: "김송은", job: "캐스팅디렉터", agency: "퀀텀이엔엠", desc: "급하게 배우를 구해야 할 때, 다른 서비스는 공고 정보를 올리고 카톡이나 이메일 등을 통해 프로필을 따로 받아봐야해서 많은 지원자들을 하나하나 살펴보기 어려웠는데 캐스팅보트는 한 곳에서 공고 올리고 지원자도 확인할 수 있어서 편리한 플랫폼이라고 생각해요. 업무가 편리해졌어요." },
    { id: 3, name: "김OO", job: "배우지망생", agency: "", desc: "타 서비스를 이용하지 않는 저는 프로필 지원 방법이 메일 밖에 없었는데 여러 캐디분들에게 프로필을 보낼 수 있어서 너무 좋아요,,❤️ 그리고 정보가 부족한 저 같은 사람들은 어떤 오디션이 진행중인지 아는 것도 힘들었는데 캐스팅보트에 잘 알 수 없었던 오디션 정보들이 올라오고 멤버십 구독만 하면 다양한 공고에 무제한으로 지원할 수 있어서 도움이 정말 많이 돼요ㅠㅠ 실제 유명한 회사에 소속된 배우분들도 프로필 등록이 되어있어서 더 믿고 이용할 수 있는 것 같아요(˃̣̣̣̣̣̣︿˂̣̣̣̣̣̣ )♡" },
    { id: 4, name: "박병철", job: "캐스팅디렉터", agency: "스튜디오빌드업", desc: "이메일로 확인하기 어려운 여러 배우들의 프로필을 한 플랫폼 안에서 볼 수 있다는 것이 강점. 찾지 못했던 실력 있는 신인 아티스트들을 발견할 수 있음." },
    { id: 5, name: "김OO", job: "배우", agency: "", desc: "다른 곳에서는 확인할 수 없는 캐디님들이 진행중인 작품의 주조연, 단역 오디션 공고까지 확인할 수 있어서 좋아요 ! 오디션 공고에 지원하기뿐만 아니라 캐스팅디렉터님에게 직접 프로필을 보낼 수도 있어서 제작사나 캐디분들의 회사 위치를 몰라도 프로필을 보낼 수 있는 게 너무 좋은 것 같아요 !" },
  ]

  const bgColor = "bg-[#0D0D0D]"

  const count = useCountUp(inView ? 122 : 0);

  return (
    <div className="pb-[124px] shadow-[0_0px_20px_rgba(0,0,0,0.1)] ">
      <HeaderWithBackButton darkMode={whiteHeader ? true : false} title={"멤버십"} />
      <div className={`py-[60px] ${bgColor} w-full`}>
        <div className="max-w-[186px] mx-auto text-Gray00 text-center">
          <img className="h-8 mx-auto" src={CVLogo} alt="" />
          <div className="MCaption14 mb-1 mt-4">혜택은 더 크게! 새로워진</div>
          <div className="BHead24">캐스팅보트 멤버십</div>
          <button
            onClick={() => {
              navigate("/membership/subscribe", { replace: true });
            }}
            className={`${bgColor} MBody16 my-[30px] h-[52px] w-full border-Gray00 border justify-center gap-2 text-Gray00 flex items-center hover:bg-opacity-80 disabled:opacity-20`}>
            멤버십 시작하기
            <BlackButtonArrow />
          </button>
          <img className="w-[186px] h-44" src={MembershipLogo} alt="" />
        </div>
      </div>
      <div className={`py-[60px] ${bgColor} w-full`}>
        <div className="BBody20 text-Gray00 text-center">
          어디서도 볼 수 없는
          <br />
          독점 캐스팅 공고 업데이트
        </div>
        <div className="text-Gray04 mt-2.5 mb-10 text-center MCaption12">
          오직 캐스팅보트에서만!<br />
          확인할 수 있는 미공개 공고에 지원해 보세요.
        </div>
        <img className="w-full mt-10" src={CastingList} alt="" />
      </div>

      <div className={`py-[60px] ${bgColor} w-full`}>
        <div className="BBody20 text-Gray00 text-center">
          국내 최정상 캐스팅디렉터들에게<br />
          직접 프로필을 보내보세요
        </div>
        <div className="text-Gray04 mt-2.5 mb-10 text-center MCaption12">
          펜트하우스, 셀러브리티, 베테랑, 마스크걸, 사내맞선...<br />
          화제의 작품들을 캐스팅한 캐스팅디렉터들이<br />
          당신을 기다립니다.
        </div>
        <div className="flex flex-col gap-2 overflow-hidden">
          <div className="flex items-start w-[1018px] relative h-[150px] overflow-hidden">
            <div className="flex items-center gap-2 flex-nowrap rolling-image up">
              {up.concat(up).map((item, idx) => (
                <img key={idx} className="w-[105px] h-[150px] flex-shrink-0" src={item} alt="" />
              ))}
            </div>
          </div>
          <div className="flex items-start w-[1018px] relative h-[150px] overflow-hidden">
            <div className="flex items-center gap-2 flex-nowrap rolling-image down">
              {down.concat(down).map((item, idx) => (
                <img key={idx} className="w-[105px] h-[150px] flex-shrink-0" src={item} alt="" />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-[60px] pb-[130px] relative bg-[linear-gradient(180deg,#0D0D0D_80%,rgba(13,13,13,0)_99%)] w-full">
        <div className="BBody20 text-Gray00 text-center">
          캐디뿐만 아니라
          <br />
          업계 최고 현업자들과 함께
        </div>
        <div className="text-Gray04 mt-2.5 mb-10 text-center MCaption12">
          연출, 제작, 매니지먼트, 에이전시 등<br />
          각 분야 실무자들과 함께해요.
        </div>
        <div className="mx-5 bg-[#222] border-[0.5px] py-10 px-5 border-Gray09">
          {collaborator.map((item) => {
            return (
              <div key={item} className={`border-b border-Gray05 py-5 first:pt-0 last:pb-0 last:border-none`}>
                <div className="max-w-60 mx-auto">
                  <img className="mb-2.5" src={item} alt="" />
                </div>
              </div>
            )
          })}
        </div>
        <div className="absolute bottom-16 left-2/4 flex flex-col gap-2 items-center -translate-x-2/4">
          <PlusInMembership />
          <div className="MCaption10 text-Gray00">
            가입하고 더 많은 협업사를 확인하세요!
          </div>
        </div>
      </div>

      <div className="pt-[40px] pb-[50px] bg-Gray00 w-full">
        <div className="BBody20 text-Gray09 text-center">
          캐스팅보트를 통해 <br />
          최근 두 달 동안 캐스팅된 배우
        </div>
        <div className="text-Gray05 mt-2.5 mb-5 text-center MCaption12">
          앞으로 더 많아집니다.<br />
          진짜로요!
        </div>
        <div ref={ref} className="font-bold text-[60px] flex justify-center items-end">
          {inView && (
            <span className="membershipCastingCount">{count}</span>
          )}
          명
        </div>
      </div>
      <div className="pt-[50px] pb-[40px]Dbg-Gray00 w-full">
        <div className="BBody20 text-Gray09 text-center">
          #생생한 리뷰
        </div>
        <div className="text-Gray05 mt-2.5 mb-10 text-center MCaption12">
          리뷰 외에도 많은 배우님과 캐스팅디렉터, 매니지먼트, 제작사 분들이<br />
          캐스팅보트를 이용하고 계세요.
        </div>
        <div className="pl-5">
          <Swiper
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Autoplay]}
            className="h-full"
            slidesPerView={"auto"}
            spaceBetween={8}
          >
            {reviews.map((item) => {
              return (
                <SwiperSlide key={item.id} className="!w-fit">
                  <div className="w-[260px] relative py-[30px] px-5 border border-Gray09">
                    <div className="absolute -left-[1px] top-0 px-1 py-0.5 z-[99999] bg-Gray09 text-Gray00 MCaption12">{item.job}</div>
                    <div className="flex items-center gap-1 MCaption14">
                      <div className="text-Gray09">{item.name}</div>
                      <div className="text-Gray04">{item.agency}</div>
                    </div>
                    <div className="MCaption10 text-Gray09 mt-3">
                      {item.desc}
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
      <img src={ManagerBanner} className="my-10" alt="" />
      <div className="px-5">
        <div className="BCaption12 text-Gray09 mb-2">멤버십 유의사항</div>
        <div className="flex flex-col gap-2">
          <div>
            <div className="MCaption12 text-Gray09">구매안내</div>
            <ol className="list-disc pl-4 RCaption10">
              <li>결제 금액은 부가세(VAT)가 포함된 가격입니다.</li>
              <li>등록하신 결제 수단으로 <span className="font-bold">매월 정기 결제일에 멤버십 이용 금액이 자동으로 결제</span>됩니다.</li>
              <li>멤버십은 언제든 해지할 수 있으며 해지해도 결제 만료일까지 사용 가능합니다.</li>
              <li>신용카드 정보 변경 및 잔액부족 시에는 결제실패로 자동결제가 해지될 수 있습니다.</li>
              <li>미성년 회원의 결제는 원칙적으로 법정대리인의 명의 또는 동의 하에 이루어져야 하고, 법정대리인은 본인 동의 없이 체결된 자녀(미성년자)의 계약을 취소할 수 있습니다.</li>
            </ol>
          </div>
          <div>
            <div className="MCaption12 text-Gray09">환불안내</div>
            <ol className="list-disc pl-4 RCaption10">
              <li>환불은 공고에 지원하지 않은 경우 결제 후 7일 이내에 캐스팅보트 고객센터를 통해 환불이 가능합니다.</li>
              <li>멤버십 사용 중에는 남은 기간에 대한 금액이 환불되지 않습니다.</li>
            </ol>
          </div>
          <div>
            <div className="MCaption12 text-Gray09">기타안내</div>
            <ol className="list-disc pl-4 RCaption10">
              <li>기타 문의 사항은 고객센터로 1:1 문의 부탁드립니다.</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 px-5 max-w-[400px] bg-opacity-100 pb-8 z-50 left-2/4 w-full -translate-x-2/4">
        <button
          onClick={() => {
            navigate("/membership/subscribe", { replace: true });
          }}
          className={`${bgColor} MBody16 h-[52px] w-full justify-center gap-2 border border-Gray00 text-Gray00 flex items-center hover:bg-opacity-80 disabled:opacity-20`}>
          멤버십 시작하기
          <BlackButtonArrow />
        </button>
      </div>
    </div>
  );
};

export default Membership;