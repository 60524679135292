import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useInView } from 'react-intersection-observer';

import ReceivedProfileListCard from 'pages/Received/_components/ReceivedProfileListCard';
import BottomButtonPopup from 'components/BottomButtonPopup';
import { splitBirth } from 'utils/onBirth';
import { ReactComponent as SelectCastingCall } from "assets/selectCastingCall.svg";

import { useCastingsByCastingCall, useChangeCastingStatus } from 'apis/castingStaff';
import { useReadReceivedProfile } from 'apis/deliveryProfile';
import { Toast } from 'hooks/useToast';
import requestToCastingVote from 'apis/api';
import BottomPopup from 'components/BottomPopup';
import ButtonBackground from 'components/ButtonBackground';
import { FillButton } from 'components/FillButton';

const ButtonText: Record<string, string> = {
    SHORTLIST_SELECTED: "리스트업",
    FINALIST_SELECTED: "캐스팅 확정",
};

interface CastingShortlistType {
    activeTab: string;
    status: string;
    castingcallId: number;
    selectMode: boolean;
    setSelectMode: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCastingIds: number[];
    setSelectedCastingIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const CastingShortlist = ({ activeTab, status, castingcallId, selectMode, setSelectMode, selectedCastingIds, setSelectedCastingIds }: CastingShortlistType) => {
    const navigate = useNavigate();
    const [confirmPass, setConfirmPass] = useState(false);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useCastingsByCastingCall({
        id: castingcallId,
        status: activeTab,
    });

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    const allLists = useMemo(
        () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
        [data]
    );

    const { mutate: profileRead } = useReadReceivedProfile();
    const { mutate } = useChangeCastingStatus();

    const getNextStatus = (status: string) => {
        const statusMap: Record<string, string> = {
            SHORTLIST_SELECTED: "FINALIST_SELECTED",
            FINALIST_SELECTED: "PASSED",
        };
        return statusMap[status] || "";
    };

    const handleShortlist = () => {
        const nextStatus = getNextStatus(activeTab);
        if (!nextStatus) return;

        const data = {
            castingCallId: castingcallId,
            status: nextStatus,
            castingIds: selectedCastingIds,
        };

        mutate(data, {
            onSuccess: () => {
                setSelectMode(false);
                setConfirmPass(false);
                setSelectedCastingIds([])
                refetch()
                Toast.success(nextStatus === "PASSED" ? "캐스팅 확정되었어요." : "프로필을 이동했어요.");
            }
        })
    }

    const handleDownload = async () => {
        try {
            const response = await requestToCastingVote.get(
                `/castingcalls/${castingcallId}/castings/excel?status=${activeTab}`,
                {
                    responseType: "blob",
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "test.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("엑셀 파일 다운로드 중 오류가 발생했습니다.", error);
        }
    };

    const handleClick = (item: { castingId: number; readAt: null | string; profileId: number; }) => {
        if (selectMode) {
            setSelectedCastingIds((prevSelectedIds) =>
                prevSelectedIds.includes(item.castingId)
                    ? prevSelectedIds.filter((id) => id !== item.castingId)
                    : [...prevSelectedIds, item.castingId]
            );
            return;
        }

        if (item?.readAt === null) {
            profileRead(item?.profileId);
        }

        navigate(`/staff/shortlist/${item.profileId}`, {
            state: {
                castingCallId: castingcallId,
                castingId: item?.castingId,
            },
        });
    };

    return (
        <div>
            {
                allLists.length === 0 ? (
                    <div className="mt-36 items-center text-center">
                        <div className="text-Gray05 RBody14">받은 프로필이 없어요.</div>
                    </div>
                ) : (
                    <div>
                        {(activeTab !== "PASSED" && status === "ACTIVE") &&
                            <div className='flex items-center justify-between px-5'>
                                <div onClick={() => { handleDownload() }} className='text-Blue04 BCaption14 cursor-pointer py-4'>
                                    엑셀 다운로드
                                </div>
                                <div onClick={() => {
                                    if (selectMode) {
                                        setSelectedCastingIds([])
                                    }
                                    setSelectMode(selectMode => !selectMode)
                                }} className='text-Blue04 BCaption14 cursor-pointer py-4'>
                                    {selectMode ? "취소" : "단계이동"}
                                </div>
                            </div>
                        }
                        {allLists.map((item) => {
                            return (
                                <ReceivedProfileListCard
                                    key={item.castingId}
                                    rating={item.rating}
                                    thumbnail={item.thumbnailUrl}
                                    createdAt={item.createdAt}
                                    readAt={item?.readAt}
                                    name={item.profileName}
                                    birth={`${splitBirth(item.birthDate)}`}
                                    selectMode={selectMode}
                                    height={item.height}
                                    weight={item.weight}
                                    description={item.description}
                                    selected={selectedCastingIds.includes(
                                        item.castingId
                                    )}
                                    isMyCasting
                                    isOffered={item.isOffered}
                                    onClick={() => handleClick(item)}
                                />
                            );
                        })}
                        {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                    </div>
                )
            }

            {confirmPass &&
                <BottomPopup onClose={() => { }}>
                    <div className='BBody20'>
                        선택한 프로필을 캐스팅 확정할까요
                    </div>
                    <div className='MBody14 text-Gray05 mt-4'>
                        캐스팅을 확정하면 확정된 아티스트와 참조된 스태프에게 알림이 전송되며, 이후에는 확정을 취소할 수 없어요.
                    </div>
                    <ButtonBackground>
                        <FillButton
                            onClick={() => {
                                setConfirmPass(false);
                            }}
                            variant="Secondary"
                            size="B"
                            text='닫기'
                        />
                        <FillButton
                            onClick={() => {
                                handleShortlist()
                            }}
                            variant="Membership"
                            size="B"
                            text="확정하기"
                        />
                    </ButtonBackground>
                </BottomPopup>
            }

            {selectedCastingIds.length > 0 &&
                <BottomButtonPopup onClick={() => {
                    if (activeTab === "FINALIST_SELECTED") {
                        setConfirmPass(true);
                        return;
                    }
                    handleShortlist()
                }} onClose={() => { }}>
                    <div
                        className="flex flex-col gap-1 pt-2 justify-center items-center MCaption14 cursor-pointer text-Gray00"
                    >
                        <SelectCastingCall />
                        {selectedCastingIds.length}명 {ButtonText[activeTab] || ""}하기</div>
                </BottomButtonPopup>
            }

        </div>
    );
};

export default CastingShortlist;