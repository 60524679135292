import React, { useEffect, useState } from "react";
import Chip from "components/Chip";
import Input from "components/Input";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as ProfileInsta } from "assets/profileInsta.svg";
import { ReactComponent as ProfileYoutube } from "assets/profileYoutube.svg";
import Button from "components/Button";
import Textarea from "components/Textarea";
import Dropdown from "components/Dropdown";

import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import { ReactComponent as Delete } from "assets/delete.svg";
import { ReactComponent as CloseButton } from "assets/icon_close.svg";
import ImgUpload from "components/ImgUpload";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { Toast } from "hooks/useToast";
import { CASTING_LIST, JOB_LIST, TYPE_LIST } from "utils/type";
import DateInput from "components/DateInput";
import { today } from "utils/onToday";
import ButtonBackground from "components/ButtonBackground";
import ContentWrap from "components/ContentWrap";
import { FillButton } from "components/FillButton";
import { ROLE_LABELS, TYPE_LABELS } from "components/SelectBox";
import { useMyProfile } from "apis/my";
import { useUpdateArtistProfile } from "apis/profile";
import { updateArtists } from "types/artist/artist";
import { useUploadImage, useUploadVideo } from "apis/attachment";
import useLoginInfo from "stores/loginInfo";
import { LineButton } from "components/LineButton";
import Loading from "components/Loading";

const UpdateArtistProfile = () => {
  const navigate = useNavigate();
  const { setLoginInfo } = useLoginInfo();
  const [hobbyItem, setHobbyItem] = useState<{
    name: string;
    id?: number;
    isNew: boolean;
  }>({ name: "", isNew: false });
  const [youtubeLink, setYoutubeLink] = useState("");
  const [artistProfile, setArtistProfile] = useState<updateArtists>({
    profileId: null,
    name: "",
    birthDate: "",
    gender: "",
    job: "",
    sido: "",
    height: null,
    weight: null,
    education: "",
    rank: "NORMAL",
    agency: "",
    introduce: "",
    instagram: "",
    youtube: "",

    hobbies: [],
    addHobbies: [],
    removeHobbyIds: [],

    jobs: [],
    addJobs: [],
    removeJobs: [],

    filmographies: [],
    addFilmographies: [],
    removeFilmographyIds: [],

    attachments: [],
    addAttachments: [],
    removeAttachments: [],

    videoLinks: [],
    addVideoLinks: [],
    removeVideoLinks: [],
  });

  const { data, refetch } = useMyProfile();

  const ArtistData = data?.data;

  const { mutate: uploadImages, isPending: imagePending } = useUploadImage();
  const { mutate: uploadVideos, isPending: videoPending } = useUploadVideo();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const files = e.target.files;
    if (files) {
      if (type === "ADDITIONAL_IMAGE") {
        const imagesCount = artistProfile.attachments!.filter(
          (attachment) => attachment.type === "ADDITIONAL_IMAGE"
        ).length;

        const remainingSlots = 15 - imagesCount;

        if (files.length > remainingSlots) {
          Toast.error("최대 15개 업로드할 수 있어요.");
          return;
        }
      }

      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");

          const uploadedItems = res.data.map(
            (item: { attachmentId: number; attachmentUrl: string }) => ({
              id: item.attachmentId,
              isNew: true,
              type: type,
              url: item.attachmentUrl,
            })
          );

          const existingThumbnails = artistProfile.attachments!.filter(
            (attachment) => attachment.type === "THUMBNAIL"
          );

          const existingAdditionalImages = artistProfile.attachments!.filter(
            (attachment) => attachment.type === "ADDITIONAL_IMAGE"
          );

          const newThumbnails = uploadedItems.filter(
            (item) => item.type === "THUMBNAIL"
          );

          const newAdditionalImages = uploadedItems.filter(
            (item) => item.type === "ADDITIONAL_IMAGE"
          );

          const reorderedThumbnails = [
            ...existingThumbnails,
            ...newThumbnails,
          ].map((item) => ({
            ...item,
            orderNumber: 0,
          }));

          const reorderedAdditionalImages = [
            ...existingAdditionalImages,
            ...newAdditionalImages,
          ].map((item, idx) => ({
            ...item,
            orderNumber: idx + 1,
          }));

          const reorderedAttachments = [
            ...reorderedThumbnails,
            ...reorderedAdditionalImages,
          ];

          const reorderedUploadedItems = uploadedItems.map((item) => {
            if (item.type === "THUMBNAIL") {
              return { ...item, orderNumber: 0 };
            }
            const additionalImageIndex =
              reorderedAdditionalImages.findIndex(
                (additionalItem) => additionalItem.id === item.id
              ) + 1;
            return { ...item, orderNumber: additionalImageIndex };
          });

          setArtistProfile({
            ...artistProfile,
            addAttachments: [
              ...artistProfile.addAttachments!,
              ...reorderedUploadedItems,
            ],
            attachments: reorderedAttachments,
          });
        },
      });
    }
  };

  const videoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const additionalVideosCount = artistProfile.attachments!.filter(
        (attachment) => attachment.type === "ADDITIONAL_VIDEO"
      ).length;

      const remainingSlots = 3 - additionalVideosCount;

      if (files.length > remainingSlots) {
        Toast.error("최대 3개까지 업로드 가능해요");
        return;
      }
      uploadVideos(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              id: item.attachmentId,
              isNew: true,
              type: "ADDITIONAL_VIDEO",
              orderNumber: additionalVideosCount + idx + 1,
              url: item.attachmentUrl,
            })
          );
          setArtistProfile({
            ...artistProfile,
            addAttachments: [...artistProfile.addAttachments!, ...updateItem],
            attachments: [...artistProfile.attachments!, ...updateItem],
          });
        },
      });
    }
  };

  const opt = {
    height: "171",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const { mutate: updateMutate, isPending } = useUpdateArtistProfile();

  useEffect(() => {
    if (ArtistData) {
      setArtistProfile({
        ...artistProfile,
        profileId: ArtistData.profileId,
        name: ArtistData.profileName,
        birthDate: ArtistData.birthDate,
        gender: ArtistData.gender?.code,
        // job: ArtistData.basicInfo.job?.code,
        jobs: ArtistData.jobs,
        sido: ArtistData.basicInfo.address?.sido,
        height: ArtistData.basicInfo.height,
        weight: ArtistData.basicInfo.weight,
        education: ArtistData.basicInfo.education,
        rank: ArtistData.rank?.code,
        agency: ArtistData.basicInfo.agency,
        introduce: ArtistData.introduce,
        instagram: ArtistData.snsInfo?.instagram,
        youtube: ArtistData.snsInfo?.youtube,
        // hashtags: ArtistData.hashtags,
        hobbies: ArtistData.hobbies,
        filmographies: ArtistData.filmographies.map(
          (film: {
            id: number;
            title: string;
            type: {
              code: string;
              label: string;
            };
            role: {
              code: string;
              label: string;
            };
            releaseYear: string;
            description: string;
            orderNumber: number;
          }) => ({
            id: film.id,
            title: film.title,
            type: film?.type?.code,
            role: film?.role?.code,
            releaseYear: Number(film.releaseYear),
            description: film.description,
            orderNumber: film.orderNumber,
          })
        ),
        attachments: ArtistData.attachments.map(
          (attachment: {
            id: number;
            type: {
              code: string;
              label: string;
            };
            url: string;
            orderNumber: number;
          }) => ({
            id: attachment.id,
            type: attachment.type.code,
            orderNumber: attachment.orderNumber,
            url: attachment.url,
          })
        ),
        videoLinks: ArtistData.videoLinks.map(
          (video: {
            id: number;
            link: string;
            type: {
              code: string;
              label: string;
            };
            orderNumber: number;
          }) => ({
            id: video.id,
            link: video.link,
            type: video.type.code,
            orderNumber: video.orderNumber,
          })
        ),
      });
    }
  }, [ArtistData]);

  const addFilmographyItem = () => {
    setArtistProfile((prevState) => ({
      ...prevState,
      addFilmographies: [
        {
          isNew: true,
          title: "",
          type: "",
          role: "",
          releaseYear: 0,
          description: "",
          orderNumber: prevState.filmographies!.length,
        },
        ...prevState.addFilmographies!,
      ],
    }));
  };

  const addVideoLink = (newLink: string, type: string) => {
    if (
      newLink.startsWith("https://www.youtube.com") ||
      newLink.startsWith("https://youtu.be/")
    ) {
      setArtistProfile((prevProfile) => {
        const currentVideoCount = prevProfile.videoLinks!.length;

        if (currentVideoCount >= 3) {
          Toast.error("최대 3개까지 업로드 가능해요");
          return prevProfile;
        }

        const newOrderNumber = currentVideoCount;
        const newVideoLink = {
          isNew: true,
          link: newLink,
          type: type,
          orderNumber: newOrderNumber,
        };

        return {
          ...prevProfile,
          addVideoLinks: [...prevProfile.addVideoLinks!, newVideoLink],
          videoLinks: [...prevProfile.videoLinks!, newVideoLink],
        };
      });
    } else {
      alert("잘못된 유튜브 링크입니다.");
    }
  };

  const handleDeleteImage = (id: number, isNew?: boolean) => {
    const updatedAttachments = artistProfile.attachments!.filter(
      (item) => item.id !== id
    );
    const updatedAddAttachments = artistProfile.addAttachments!.filter(
      (item) => item.id !== id
    );
    setArtistProfile({
      ...artistProfile,
      attachments: updatedAttachments,
      addAttachments: updatedAddAttachments,
      removeAttachments: isNew
        ? artistProfile.removeAttachments
        : [...(artistProfile.removeAttachments || []), id],
    });
  };

  const handleDeleteVideo = (id: number, isNew?: boolean) => {
    const updatedVideoLinks = artistProfile.videoLinks!.filter(
      (item) => item.id !== id
    );
    const updatedAddVideoLinks = artistProfile.addVideoLinks!.filter(
      (item) => item.id !== id
    );

    setArtistProfile({
      ...artistProfile,
      videoLinks: updatedVideoLinks,
      addVideoLinks: updatedAddVideoLinks,
      removeVideoLinks: isNew
        ? artistProfile.removeVideoLinks
        : [...(artistProfile.removeVideoLinks || []), id],
    });
  };

  const removeFilmographyItem = (
    idxToRemove: number,
  ) => {
    setArtistProfile((pre) => ({
      ...pre,
      addFilmographies: pre.addFilmographies!.filter(
        (_, i) => i !== idxToRemove
      ),
    }));
  };

  const removePreFilmographyItem = (id: number) => {
    const updatedAddAttachments = artistProfile.filmographies!.filter(
      (item) => item.id !== id
    );
    setArtistProfile({
      ...artistProfile,
      filmographies: updatedAddAttachments,
      removeFilmographyIds: [...artistProfile.removeFilmographyIds!, id],
    });
  };
  const submitProfile = () => {
    updateMutate(artistProfile, {
      onSuccess: () => {
        navigate("/artist/my", { replace: true });
        refetch();
        setLoginInfo();
      },
    });
  };

  const valid = (data: updateArtists) => {
    const hasThumbnail = data?.attachments!.some(
      (attachment) => attachment.type === "THUMBNAIL"
    );
    if (!hasThumbnail) return "대표 프로필 이미지는 필수입니다. ";
    if (data.jobs!.length === 0) return "직업을 선택해주세요.";
    if (!data.name) return "활동명을 입력해주세요.";
    if (data.height === 0) return "키를 입력해주세요.";
    if (data.weight === 0) return "몸무게를 입력해주세요.";
    if (
      artistProfile?.addFilmographies!?.some((filmography) => {
        return (
          !filmography.title ||
          !filmography.type ||
          !filmography.role ||
          filmography.releaseYear! === 0 ||
          !filmography.description
        );
      })
    )
      return "필모그래피는 빈칸없이 입력해주세요";
    return null;
  };

  const toggleJob = (job: string) => {
    const updatedJobs = artistProfile.jobs?.includes(job)
      ? artistProfile.jobs.filter((item) => item !== job)
      : artistProfile.jobs!.length < 5
        ? [...artistProfile.jobs!, job]
        : artistProfile.jobs;

    setArtistProfile({
      ...artistProfile,
      jobs: updatedJobs,
    });

    if (ArtistData?.jobs.includes(job)) {
      setArtistProfile({
        ...artistProfile,
        jobs: updatedJobs,
        removeJobs: artistProfile.removeJobs?.includes(job)
          ? artistProfile.removeJobs.filter((r) => r !== job)
          : [...artistProfile.removeJobs!, job],
      });
    } else {
      setArtistProfile({
        ...artistProfile,
        jobs: updatedJobs,
        addJobs: artistProfile.addJobs?.includes(job)
          ? artistProfile.addJobs.filter((a) => a !== job)
          : [...artistProfile.addJobs!, job],
      });
    }
  };

  return (
    <>
      {(videoPending || imagePending) &&
        <Loading />
      }
      <HeaderWithBackButton title={`프로필 수정`} />
      <div className="pb-24 pt-5">
        <div className="px-5 flex flex-col gap-10 items-start w-full">
          <ContentWrap title="사진 업로드">
            <TitleWrap
              title={"대표 프로필 사진 업로드"}
              subTitle="4:5 / 10MB 이하"
              require
            >
              {artistProfile?.attachments!.some(
                (item) => item.type === "THUMBNAIL"
              ) ? (
                artistProfile
                  ?.attachments!.filter((item) => item.type === "THUMBNAIL")
                  .map((item, idx) => (
                    <div key={item.id} className="relative">
                      <img
                        className="w-full h-[418px] object-cover rounded-[10px]"
                        src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        alt="THUMBNAIL"
                      />
                      <CloseButton
                        onClick={() => {
                          handleDeleteImage(item.id, item.isNew);
                        }}
                        className="absolute cursor-pointer top-0 right-0"
                      />
                    </div>
                  ))
              ) : (
                <ImgUpload
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={(e) => {
                    imgUpload(e, "THUMBNAIL");
                  }}
                  title="프로필 사진 업로드"
                  id={"profileImg"}
                ></ImgUpload>
              )}
            </TitleWrap>
            <TitleWrap
              title={"추가 사진 업로드"}
              subTitle={`최대 15장 / 보정이 과하거나 노출이 심한 사진은 자제해주세요.`}
            >
              <ImgUpload
                multiple
                accept="image/jpg,image/png,image/jpeg"
                onChange={(e) => {
                  imgUpload(e, "ADDITIONAL_IMAGE");
                }}
                title="추가 사진 업로드"
                id={"ADDITIONAL_IMAGE"}
              ></ImgUpload>
              <div className="grid grid-cols-3 gap-1 items-center">
                {artistProfile
                  ?.attachments!.filter(
                    (item) => item.type === "ADDITIONAL_IMAGE"
                  )
                  .map((item, idx) => (
                    <div
                      key={item.id}
                      className={`relative  ${idx < 3 && "mt-2"} `}
                    >
                      <img
                        className="w-[117px] h-[117px] object-cover rounded-[10px]"
                        src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        alt="ADDITIONAL_IMAGE"
                      />
                      <CloseButton
                        onClick={() => {
                          handleDeleteImage(item.id, item.isNew);
                        }}
                        className="absolute cursor-pointer top-0 right-0"
                      />
                    </div>
                  ))}
              </div>
            </TitleWrap>
          </ContentWrap>
          <ContentWrap
            title={"영상 업로드"}
            subTitle="연기영상/자유영상을 첨부하면 캐스팅 확률이 높아져요!"
          >
            <TitleWrap
              title={"영상 파일 첨부"}
              className="mb-4"
              subTitle="50mb/최대 3개"
            >
              <ImgUpload
                accept=".avi, .mov, .mp4"
                onChange={(e) => {
                  videoUpload(e);
                }}
                title="영상 파일 업로드"
                id={"ProfileVideo"}
              ></ImgUpload>
              {artistProfile?.attachments!.some(
                (item) => item.type === "ADDITIONAL_VIDEO"
              )
                ? artistProfile
                  ?.attachments!.filter(
                    (item) => item.type === "ADDITIONAL_VIDEO"
                  )
                  .map((item, idx) => (
                    <div key={item.id} className="relative mt-2 w-full">
                      <video
                        controls
                        className="w-full h-[186px] rounded-[10px]"
                      >
                        <source
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                        ></source>
                      </video>
                      <CloseButton
                        onClick={() => {
                          handleDeleteImage(item.id, item.isNew);
                        }}
                        className="absolute cursor-pointer top-0 right-0"
                      />
                    </div>
                  ))
                : ""}
            </TitleWrap>
            <TitleWrap title={"유튜브 링크"}>
              <div className="flex items-center gap-2 w-full">
                <Input
                  value={youtubeLink}
                  onChange={(e) => {
                    setYoutubeLink(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (youtubeLink !== "") {
                      if (e.key === "Enter") {
                        addVideoLink(youtubeLink, "YOUTUBE");
                        setYoutubeLink("");
                      }
                    }
                  }}
                  placeholder="링크"
                  type={"text"}
                ></Input>
                <LineButton
                  onClick={() => {
                    if (youtubeLink !== "") {
                      addVideoLink(youtubeLink, "YOUTUBE");
                      setYoutubeLink("");
                    }
                  }}
                  size="L"
                  className="w-[88px] flex-shrink-0"
                  text="추가"
                />
              </div>
              {artistProfile.videoLinks!.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <div key={item.orderNumber} className="relative mt-2">
                    <YouTube
                      className={"w-full"}
                      key={item.orderNumber}
                      opts={opt}
                      iframeClassName="youtubeRounded"
                      videoId={link}
                    />
                    <CloseButton
                      onClick={() => {
                        handleDeleteVideo(item.id!);
                      }}
                      className="absolute cursor-pointer top-0 right-0"
                    />
                  </div>
                );
              })}
            </TitleWrap>
            {/* <div className="MBody14 w-full text-Gray09 my-4">
                💡드래그앤드롭으로 영상 순서를 바꿀 수 있어요
              </div> */}
          </ContentWrap>
        </div>
        <hr className="w-full h-3 bg-Gray01 my-10" />
        <div className="px-5 flex flex-col gap-10 items-start w-full">
          <ContentWrap title="기본정보">
            <TitleWrap title={"직업"} subTitle="최대 5개" require>
              <div className="flex items-center flex-wrap gap-2">
                {JOB_LIST.map((item) => {
                  return (
                    <Chip
                      selected={artistProfile?.jobs?.includes(item.title)}
                      onClick={() => {
                        toggleJob(item.title);
                      }}
                      key={item.id}
                      title={item.title}
                    />
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title={"활동명"} require>
              <Input
                value={artistProfile?.name}
                onChange={(e) => {
                  setArtistProfile({ ...artistProfile, name: e.target.value });
                }}
                className="w-[589px]"
                placeholder="활동"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title={"생년월일"}>
              <DateInput
                value={artistProfile?.birthDate}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    birthDate: e.target.value,
                  });
                }}
                min="1920-01-01"
                max={today()}
                className="w-[589px]"
                placeholder="생년월일"
              ></DateInput>
            </TitleWrap>
            <TitleWrap title={"성별"}>
              <div className="flex items-center gap-4 w-full">
                <Button
                  onClick={() => {
                    setArtistProfile({ ...artistProfile, gender: "FEMALE" });
                  }}
                  className={`
                           w-full border ${artistProfile?.gender === "FEMALE"
                      ? "border-Blue04 text-Blue04 bg-Gray00"
                      : "border-Gray03 bg-Gray00 text-Gray05"
                    } cursor-default
                           `}
                  text={"여성"}
                />
                <Button
                  onClick={() => {
                    setArtistProfile({
                      ...artistProfile,
                      gender: "MALE",
                    });
                  }}
                  className={`
                    w-full border RBody16 cursor-default
                     ${artistProfile?.gender === "MALE"
                      ? "border-Blue04 text-Blue04 bg-Gray00"
                      : "border-Gray03 bg-Gray00 text-Gray05"
                    }`}
                  text={"남성"}
                />
              </div>
            </TitleWrap>
            <TitleWrap title={"SNS"}>
              <Input
                value={artistProfile?.instagram}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    instagram: e.target.value,
                  });
                }}
                className="w-[589px] h-12 mb-4 pl-[52px]"
                placeholder="인스타그램 ID"
                type={"text"}
              >
                <ProfileInsta className="left-2.5 top-2.5 absolute" />
              </Input>
              <Input
                value={artistProfile?.youtube}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    youtube: e.target.value,
                  });
                }}
                className="w-[589px] h-12 mb-4 pl-[52px]"
                placeholder="유튜브 채널 URL (영상링크 x)"
                type={"text"}
              >
                <ProfileYoutube className="left-2.5 top-2.5 absolute" />
              </Input>
            </TitleWrap>
            <div className="flex items-center gap-5">
              <TitleWrap title={"키"} require>
                <Input
                  value={artistProfile?.height}
                  onChange={(e) => {
                    setArtistProfile({
                      ...artistProfile,
                      height: Number(e.target.value.replace(/[^0-9]/g, "")),
                    });
                  }}
                  onKeyDown={(e) => {
                    if (["e", "E", "+", "-", "."].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  className="w-[285px]"
                  inputMode="numeric"
                  placeholder="cm"
                  type={"number"}
                ></Input>
              </TitleWrap>
              <TitleWrap title="몸무게" require>
                <Input
                  value={artistProfile?.weight}
                  onChange={(e) => {
                    setArtistProfile({
                      ...artistProfile,
                      weight: Number(e.target.value.replace(/[^0-9]/g, "")),
                    });
                  }}
                  onKeyDown={(e) => {
                    if (["e", "E", "+", "-", "."].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  className="w-[285px]"
                  placeholder="kg"
                  type={"number"}
                  inputMode="numeric"
                ></Input>
              </TitleWrap>
            </div>
            <TitleWrap title="학력">
              <Input
                value={artistProfile?.education}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    education: e.target.value,
                  });
                }}
                className="w-[589px] mb-4"
                placeholder="최종학력"
                type={"text"}
              ></Input>
              {/* <div className="flex items-center">
                  <Checkbox id="학력" title={"비공개"}></Checkbox>
                  <div className="group relative">
                    <Tooltip />
                    <div className="absolute group-hover:block hidden RBody14 rounded-[10px] p-2 border box-content border-Gray03 text-Gray09 top-6 bg-white left-0 w-[308px]">
                      아티스트 유저에게는 가려지고 스탭 유저에게만 공개돼요
                    </div>
                  </div>
                </div> */}
            </TitleWrap>
            <TitleWrap title="소속사">
              <Input
                value={artistProfile?.agency}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    agency: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="소속사"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="특기">
              <div>
                <div className="flex items-center gap-2">
                  <Input
                    value={hobbyItem.name}
                    onChange={(e) => {
                      setHobbyItem({ name: e.target.value, isNew: true });
                    }}
                    onKeyDown={(e) => {
                      if (hobbyItem.name) {
                        if (e.key === "Enter") {
                          setArtistProfile({
                            ...artistProfile,
                            hobbies: [...artistProfile.hobbies!, hobbyItem],
                            addHobbies: [
                              ...artistProfile?.addHobbies!,
                              hobbyItem?.name!,
                            ],
                          });
                          setHobbyItem({ name: "", isNew: false });
                        }
                      }
                    }}
                    placeholder="영어/제주도 사투리/태권도/현대무용 등"
                    type={"text"}
                  ></Input>
                  <LineButton
                    onClick={() => {
                      if (hobbyItem?.name !== "") {
                        setArtistProfile({
                          ...artistProfile,
                          hobbies: [...artistProfile.hobbies!, hobbyItem],
                          addHobbies: [
                            ...artistProfile?.addHobbies!,
                            hobbyItem.name.trim(),
                          ],
                        });
                        setHobbyItem({ name: "", isNew: false });
                      }
                    }}
                    size="L"
                    className="w-[88px] flex-shrink-0"
                    text="추가"
                  />
                </div>
                {artistProfile.hobbies!.length > 0 && (
                  <div className="flex items-center flex-wrap mt-4 gap-2">
                    {artistProfile?.hobbies!.map((item, idx) => {
                      return (
                        <Chip
                          closeButton
                          key={idx}
                          selected
                          onClick={() => {
                            const updatedHobbied =
                              artistProfile.hobbies!.filter(
                                (h) => item !== h
                              );
                            const updatedAddHobbied =
                              artistProfile.addHobbies!.filter(
                                (addH) => item.name !== addH
                              );
                            setArtistProfile({
                              ...artistProfile,
                              hobbies: updatedHobbied,
                              addHobbies: updatedAddHobbied,
                              removeHobbyIds: item?.isNew!
                                ? artistProfile.removeHobbyIds!
                                : [
                                  ...(artistProfile?.removeHobbyIds || []),
                                  item.id!,
                                ],
                            });
                          }}
                          title={item.name}
                        ></Chip>
                      );
                    }
                    )}
                  </div>
                )}
              </div>
            </TitleWrap>
            <TitleWrap title="거주지">
              <Input
                value={artistProfile?.sido}
                onChange={(e) => {
                  setArtistProfile({
                    ...artistProfile,
                    sido: e.target.value,
                  });
                }}
                className="w-[589px]"
                placeholder="시까지 입력"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="자기소개" subTitle="최대 2000자">
              <Textarea
                rows={7}
                value={artistProfile?.introduce}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const phoneNumberPattern =
                    /01[0-9][ {}\[\]\/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]*?[0-9]{3,4}[ {}\[\]\/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]*?[0-9]{4}/g;
                  if (phoneNumberPattern.test(inputValue)) {
                    Toast.error(`휴대폰번호는 입력할 수 없습니다.`);
                    inputValue.replace(phoneNumberPattern, "");
                  }

                  setArtistProfile({
                    ...artistProfile,
                    introduce: inputValue,
                  });
                }}
                placeholder="개인정보(휴대폰번호, 주소 등) 작성을 유의해주세요."
                maxlength={2000}
              />
            </TitleWrap>
          </ContentWrap>
        </div>
        <hr className="w-full h-3 bg-Gray01 my-10" />
        <div className="px-5">
          <div className="flex pb-4 justify-between items-center">
            <div className="BBody20 text-Gray09">필모그래피</div>
            <IconPlus
              onClick={() => {
                addFilmographyItem();
              }}
              className="cursor-pointer"
            />
          </div>
          {artistProfile?.addFilmographies!.map((item, idx) => {
            return (
              <div key={idx} className="flex flex-col gap-4">
                <div
                  onClick={() => {
                    removeFilmographyItem(idx);
                  }}
                  className={`${idx === 0 ? "mt-0" : "mt-4"
                    }  flex items-center gap-2 cursor-pointer`}
                >
                  <hr className="border-t border-dashed bg-Gray03 flex-1" />
                  <Delete />
                  <hr className="border-t border-dashed bg-Gray03 flex-1" />
                </div>

                <div className={`flex items-center gap-5`}>
                  <TitleWrap title={"제작년도"} subTitle="0000로 적어주세요">
                    <Input
                      value={artistProfile?.addFilmographies![idx]?.releaseYear}
                      onChange={(e) => {
                        const updatedFilmographies = [
                          ...artistProfile.addFilmographies!,
                        ];
                        updatedFilmographies[idx].releaseYear = Number(
                          e.target.value.replace(/[^0-9]/g, "").slice(0, 4)
                        );
                        setArtistProfile({
                          ...artistProfile,
                          addFilmographies: updatedFilmographies,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-", "."].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      placeholder="제작년도"
                      type={"number"}
                      inputMode="numeric"
                    ></Input>
                  </TitleWrap>
                  <TitleWrap title="분류">
                    <Dropdown
                      innerText={
                        TYPE_LABELS[
                        artistProfile.addFilmographies![idx]?.type || ""
                        ] || "선택"
                      }
                      width="w-[170px]"
                    >
                      <div>
                        {TYPE_LIST.map((item) => {
                          return (
                            <li
                              onClick={() => {
                                const updatedFilmographies = [
                                  ...artistProfile.addFilmographies!,
                                ];
                                updatedFilmographies[idx].type = item.role;
                                setArtistProfile({
                                  ...artistProfile,
                                  addFilmographies: updatedFilmographies,
                                });
                              }}
                              key={item.id}
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </div>
                    </Dropdown>
                  </TitleWrap>
                </div>
                <TitleWrap title="작품 제목" subTitle="최대 36자">
                  <Input
                    value={artistProfile?.addFilmographies![idx]?.title}
                    onChange={(e) => {
                      const updatedFilmographies = [
                        ...artistProfile.addFilmographies!,
                      ];
                      updatedFilmographies[idx].title = e.target.value;
                      setArtistProfile({
                        ...artistProfile,
                        addFilmographies: updatedFilmographies,
                      });
                    }}
                    className="w-[589px]"
                    placeholder="작품 제목"
                    type={"text"}
                  ></Input>
                </TitleWrap>
                <div className="flex items-center gap-5">
                  <TitleWrap title="배역">
                    <Dropdown
                      innerText={
                        ROLE_LABELS[
                        artistProfile.addFilmographies![idx]?.role || ""
                        ] || "선택"
                      }
                      width="w-[170px]"
                    >
                      <div>
                        {CASTING_LIST.map((item) => {
                          return (
                            <li
                              onClick={() => {
                                const updatedFilmographies = [
                                  ...artistProfile.addFilmographies!,
                                ];
                                updatedFilmographies[idx].role = item.role;
                                setArtistProfile({
                                  ...artistProfile,
                                  addFilmographies: updatedFilmographies,
                                });
                              }}
                              key={item.id}
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </div>
                    </Dropdown>
                  </TitleWrap>
                  <TitleWrap title={"배역명"}>
                    <Input
                      value={artistProfile?.addFilmographies![idx]?.description}
                      onChange={(e) => {
                        const updatedFilmographies = [
                          ...artistProfile.addFilmographies!,
                        ];
                        updatedFilmographies[idx].description = e.target.value;
                        setArtistProfile({
                          ...artistProfile,
                          addFilmographies: updatedFilmographies,
                        });
                      }}
                      className="w-[285px]"
                      placeholder="배역명"
                      type={"text"}
                    ></Input>
                  </TitleWrap>
                </div>
              </div>
            );
          })}
          {artistProfile?.filmographies!.map((item, idx) => {
            return (
              <div key={idx} className="flex flex-col gap-4">
                <div
                  onClick={() => {
                    removePreFilmographyItem(item?.id!);
                  }}
                  className={`${artistProfile.addFilmographies?.length !== 0 || idx !== 0
                    ? "mt-4"
                    : "mt-0"
                    }  flex items-center gap-2 cursor-pointer`}
                >
                  <hr className="border-t border-dashed bg-Gray03 flex-1" />
                  <Delete />
                  <hr className="border-t border-dashed bg-Gray03 flex-1" />
                </div>
                <div className={`flex items-center gap-5`}>
                  <TitleWrap title={"제작연도"} subTitle="0000로 적어주세요">
                    <Input
                      disabled
                      value={String(item?.releaseYear)}
                      type={"text"}
                    />
                  </TitleWrap>
                  <TitleWrap title="분야">
                    <Input
                      disabled
                      value={TYPE_LABELS[item.type || ""] || "선택"}
                      type={"text"}
                    />
                  </TitleWrap>
                </div>
                <TitleWrap title="작품 제목" subTitle="최대 36자">
                  <Input value={item?.title} disabled type={"text"} />
                </TitleWrap>
                <div className="flex items-center gap-5">
                  <TitleWrap title="배역">
                    <Input
                      value={ROLE_LABELS[item.role || ""] || "선택"}
                      disabled
                      type={"text"}
                    />
                  </TitleWrap>
                  <TitleWrap title={"배역명"}>
                    <Input value={item?.description} disabled type={"text"} />
                  </TitleWrap>
                </div>
              </div>
            );
          })}
        </div>
        <ButtonBackground>
          <FillButton
            isLoading={isPending}
            onClick={() => {
              const validError = valid(artistProfile);
              if (validError !== null) {
                Toast.error(validError);
              } else {
                submitProfile();
              }
            }}
            variant="Membership"
            size={"B"}
            text={`수정하기`}
          ></FillButton>
        </ButtonBackground>
      </div>
    </>
  );
};

export default UpdateArtistProfile;
