import { LineButton } from 'components/LineButton';
import React from 'react';
import { useNavigate } from 'react-router';

interface CastingcallCardType {
    deadlineDate: string;
    title: string;
    castingcallId: number;
    status: string;

    onClick?: () => void;
    onDeadlineClick?: () => void;
    referenceCasting?: boolean;
}

const CastingcallCard = ({
    deadlineDate,
    title,
    castingcallId,
    status,
    referenceCasting,

    onClick,
    onDeadlineClick
}: CastingcallCardType) => {
    const navigate = useNavigate();

    return (
        <div className='py-5 border-b border-Gray02 px-5'>
            <div className='flex flex-col gap-4'>
                <div className='flex items-center justify-between'>
                    <div className='MCaption12 text-Gray05'>
                        {status === "CLOSED" ? "마감" : `${deadlineDate || "캐스팅 확정 시"} 마감`}
                    </div>
                    {!referenceCasting &&
                        <div className='flex items-center gap-4 BCaption12'>
                            <div
                                onClick={(e) => {
                                    navigate(
                                        `/casting/my/${castingcallId}`
                                    );
                                }}
                                className='text-Gray05 cursor-pointer'>공고보기</div>
                            {/* {status === "CLOSED" &&
                            <div className='text-Error cursor-pointer'>삭제</div>
                        } */}
                        </div>
                    }
                </div>
                <div className={`MBody16 cursor-pointer ${status === "CLOSED" ? "text-Gray05" : "text-Gray09"}`} onClick={onClick}>{title}</div>
            </div>
            {!referenceCasting &&
                <div className='flex items-center gap-2 mt-4'>
                    <LineButton onClick={() => {
                        navigate(`/staff/casting-call/edit/${castingcallId}`, {
                            state: title,
                        })
                    }} disabled={status === "CLOSED"} className='w-full' variant="normal" size="S" text="수정" />
                    <LineButton onClick={onDeadlineClick} disabled={status === "CLOSED"} className='w-full' variant="normal" size="S" text="마감" />
                </div>
            }
        </div>
    );
};

export default CastingcallCard;