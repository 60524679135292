import React, { useEffect, useState } from "react";

import Button from "components/Button";
import DateInput from "components/DateInput";
import Dropdown from "components/Dropdown";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import ImgUpload from "components/ImgUpload";
import Input from "components/Input";
import Textarea from "components/Textarea";
import TitleWrap from "components/TitleWrap";
import { Toast } from "hooks/useToast";
import { useNavigate, useParams } from "react-router-dom";
import { today } from "utils/onToday";
import { CASTING_LIST } from "utils/type";
import ButtonBackground from "components/ButtonBackground";
import { ROLE_LABELS } from "components/SelectBox";
import CastingStakeholders from "pages/CastingStakeholders";
import { useMyCastingcall } from "apis/my";
import { useUpdateCastingcall } from "apis/castingcall";
import { updateCasting } from "types/casting/casting";
import { useUploadFile } from "apis/attachment";
import { LineButton } from "components/LineButton";

const UpdateCastingCall = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectStaffModal, setSelectStaffModal] = useState(false);
  const [stakeholderType, setStakeholderType] = useState('');

  const { data: myCastingCallData } = useMyCastingcall(Number(id));

  const myCastingCall = myCastingCallData?.data;

  const [castingCallData, setCastingCallData] = useState<updateCasting>({
    castingCallId: null,
    title: "",
    description: "",
    deadlineDate: "",
    deadlineType: "",
    location: "",
    format: "",
    role: "",
    minAge: null,
    maxAge: null,
    ageType: "",
    gender: "",
    attachments: [],
    addAttachments: [],
    removeAttachmentIds: [],
    stakeholders: [],
    addStakeholders: [],
    removeStakeholderMemberIds: [],
  });

  useEffect(() => {
    if (myCastingCall) {
      setCastingCallData({
        ...castingCallData,
        castingCallId: Number(id),
        title: myCastingCall?.title,
        description: myCastingCall?.description,
        deadlineDate: myCastingCall?.deadlineDate,
        deadlineType: myCastingCall?.deadlineType?.code,
        format: myCastingCall?.castingCallFormat?.code!,
        role: myCastingCall?.shootingDetails.role.code,
        minAge: myCastingCall?.shootingDetails.minAge,
        maxAge: myCastingCall?.shootingDetails.maxAge,
        gender: myCastingCall?.shootingDetails.gender.code,
        attachments: myCastingCall?.attachments?.map(
          (attachment: {
            id: number;
            type: {
              code: string;
              label: string;
            };
            originalFilename?: string;
            url: string;
            orderNumber: number;
          }) => ({
            id: attachment.id,
            type: attachment.type.code,
            originalFilename: attachment?.originalFilename,
            orderNumber: attachment.orderNumber,
            url: attachment.url,
          })
        ),
        stakeholders: myCastingCall?.stakeholders?.map(
          (stakeholder: {
            memberId: number;
            agency: string;
            id: number;
            name: string;
            role: { code: string; label: string };
          }) => ({
            memberId: stakeholder.memberId,
            agency: stakeholder.agency,
            id: stakeholder.id,
            role: stakeholder.role.code,
            name: stakeholder.name,
          })
        ),
      });
    }
  }, [myCastingCall]);

  const gender = [
    { id: 0, name: "남자", gender: "MALE" },
    { id: 1, name: "여자 ", gender: "FEMALE" },
    { id: 2, name: "성별무관", gender: "ALL" },
  ];

  const { mutate: uploadFile } = useUploadFile();
  const { mutate: updateCastingCall } = useUpdateCastingcall();

  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadFile(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.map(
            (
              item: {
                attachmentId: number;
                attachmentUrl: string;
              },
              idx: number
            ) => ({
              isNew: true,
              id: item.attachmentId,
              type: "FILE",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setCastingCallData({
            ...castingCallData,
            attachments: [...castingCallData.attachments!, ...updateItem],
            addAttachments: [...castingCallData.addAttachments!, ...updateItem],
          });
        },
      });
    }
  };

  const handleDeleteFile = (id: number, isNew?: boolean) => {
    const updatedAttachments = castingCallData.attachments?.filter(
      (item) => item.id !== id
    );
    const updatedAddAttachments = castingCallData.addAttachments!.filter(
      (item) => item.id !== id
    );
    setCastingCallData({
      ...castingCallData,
      attachments: updatedAttachments,
      addAttachments: updatedAddAttachments,
      removeAttachmentIds: isNew
        ? castingCallData.removeAttachmentIds
        : [...(castingCallData.removeAttachmentIds || []), id],
    });
  };

  const toggleStaff = (preData: {
    memberId: number;
    name: string;
    role: {
      code: string;
      label: string;
    };
    agency: string;
    thumbnailUrl: string;
    id: number;
  }) => {
    const updataStakeholder = castingCallData?.stakeholders!.some((item) => item.memberId === preData.memberId) ?
      castingCallData.stakeholders!.filter((item) => item.memberId !== preData.memberId)
      : [...castingCallData.stakeholders!, { memberId: preData.memberId, role: "DECISION_MAKER", name: preData.name }]

    setCastingCallData({
      ...castingCallData,
      stakeholders: updataStakeholder
    })

    if (myCastingCall?.stakeholders.some((item: { memberId: number }) => item.memberId === preData.memberId)) {
      setCastingCallData({
        ...castingCallData,
        stakeholders: updataStakeholder,
        removeStakeholderMemberIds: castingCallData.removeStakeholderMemberIds!.some((item: number) => item === preData.memberId) ?
          castingCallData.removeStakeholderMemberIds!.filter((item) => item !== preData.memberId)
          : [...castingCallData.removeStakeholderMemberIds!, preData.memberId],
      })
    } else {
      setCastingCallData({
        ...castingCallData,
        stakeholders: updataStakeholder,
        addStakeholders: castingCallData.addStakeholders!.some((item) => item.memberId === preData.memberId) ?
          castingCallData.addStakeholders!.filter((item) => item.memberId !== preData.memberId)
          : [...castingCallData.addStakeholders!, { memberId: preData.memberId, role: "DECISION_MAKER", name: preData.name }],
      })
    }
  }

  return (
    <>
      <CastingStakeholders
        onClick={() => {
          setSelectStaffModal(false);
        }}
        isActive={selectStaffModal}
        setIsActive={setSelectStaffModal}
        onMemberCardClick={(item) => {
          toggleStaff(item)
        }}
        selectedStaff={castingCallData.stakeholders}
        type={stakeholderType} />
      <HeaderWithBackButton title={"공고 수정"} />
      <div className="pt-5 px-5 pb-24">
        <div className="flex flex-col gap-10">
          <TitleWrap require title={"공고 제목"}>
            <Input
              type={"text"}
              value={castingCallData.title}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  title: e.target.value,
                });
              }}
              placeholder="공고 제목"
            />
          </TitleWrap>
          <div className="flex items-center gap-4">
            <TitleWrap title={"배역"}>
              <Dropdown
                width="w-[360px]"
                innerText={ROLE_LABELS[castingCallData.role || ""] || "선택"}
              >
                <div>
                  {CASTING_LIST.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          setCastingCallData({
                            ...castingCallData,
                            role: item.role,
                          });
                        }}
                        key={item.id}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </div>
              </Dropdown>
            </TitleWrap>
          </div>
          <TitleWrap title={"성별"} require>
            <Dropdown
              width="w-full"
              innerText={
                castingCallData.gender === "MALE"
                  ? "남자"
                  : castingCallData.gender === "FEMALE"
                    ? "여자"
                    : castingCallData.gender === "ALL"
                      ? "성별무관"
                      : "구분"
              }
            >
              <div>
                {gender.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setCastingCallData({
                          ...castingCallData,
                          gender: item.gender,
                        });
                      }}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
          <TitleWrap require title={"나이"}>
            <div className="flex gap-1 items-center">
              <Input
                inputMode="numeric"
                value={castingCallData.minAge}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                  setCastingCallData({
                    ...castingCallData,
                    minAge: value!,
                  });
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                type={"text"}
              />
              ~
              <Input
                inputMode="numeric"
                value={castingCallData.maxAge}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-", "."].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                  setCastingCallData({ ...castingCallData, maxAge: value });
                }}
                type={"text"}
              />
            </div>
          </TitleWrap>
          <hr />
          <TitleWrap title={"상세내용"}>
            <Textarea
              value={castingCallData.description}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  description: e.target.value,
                });
              }}
              rows={7}
              placeholder="상세내용"
              maxlength={500}
            />
          </TitleWrap>
          <div className="flex items-center gap-4">
            <TitleWrap
              subTitle="PDF파일만 올려주세요(최대 1개)"
              title={"첨부파일"}
            >
              {castingCallData.attachments?.some((i) => i.type === "FILE") ? (
                castingCallData.attachments?.map((item, idx) => {
                  if (item.type === "FILE")
                    return (
                      <div key={item.id}>
                        <div className="cursor-pointer inline-flex w-full">
                          <div
                            onClick={() => {
                              window.open(
                                `${process.env.REACT_APP_MEDIA_URL}${item?.url}`,
                                "_blank",
                                "noopener, noreferrer"
                              );
                            }}
                            className="text-Blue04 border border-Blue04 cursor-pointer MBody16 mt-2 py-3 text-center bg-Gray00 rounded-[10px] w-full"
                          >
                            첨부파일 보기
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            handleDeleteFile(item.id);
                          }}
                          className="text-Blue04 border border-Blue04 cursor-pointer MBody16 mt-2 py-3 text-center bg-Gray00 rounded-[10px] w-full"
                        >
                          첨부파일 삭제
                        </div>
                      </div>
                    );
                })
              ) : (
                <ImgUpload
                  onChange={(e) => {
                    fileUpload(e);
                  }}
                  title="파일 업로드"
                  accept=".pdf"
                  id={"file"}
                ></ImgUpload>
              )}
            </TitleWrap>
          </div>
          <TitleWrap title={"마감일자"}>
            <DateInput
              value={castingCallData.deadlineDate}
              onChange={(e) => {
                setCastingCallData({
                  ...castingCallData,
                  deadlineDate: e.target.value,
                });
              }}
              min={today()}
              className="w-full"
            />
          </TitleWrap>
          <TitleWrap title="후보 확정 권한 추가" >
            <LineButton onClick={() => {
              setStakeholderType("DECISION_MAKER")
              setSelectStaffModal(true)
            }} className="w-full" variant="normal" size="L" text="확정 권한 추가" />
            <div className="mt-1.5 flex items-start flex-wrap gap-2">
              {castingCallData?.stakeholders?.map((item) => {
                return (
                  <div key={item.memberId} className="bg-Gray01 text-Gray05 p-1.5 MCaption12">
                    {item.name}
                  </div>
                )
              })}
            </div>
          </TitleWrap>
        </div>
        <ButtonBackground>
          <Button
            onClick={() => {
              updateCastingCall(castingCallData, {
                onSuccess: () => {
                  Toast.success("공고가 수정되었어요");
                  navigate(-1);
                },
              });
            }}
            className="w-full h-12 bg-Blue04 text-white border-none"
            text={`수정하기`}
          ></Button>
        </ButtonBackground>
      </div>
    </>
  );
};

export default UpdateCastingCall;
