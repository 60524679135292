import React, { useEffect, useState } from "react";

interface TextareaType {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxlength?: number;
  rows?: number;
  onCopy?: (e: React.ClipboardEvent) => void;
  onCut?: (e: React.ClipboardEvent) => void;
  onPaste?: (e: React.ClipboardEvent) => void;
}

const Textarea = ({
  className,
  value,
  placeholder,
  onChange,
  maxlength,
  rows,
  onCopy,
  onCut,
  onPaste,
}: TextareaType) => {
  const [currentLength, setCurrentLength] = useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (maxlength && newValue?.length > maxlength) {
      return;
    }

    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      setCurrentLength(value?.length);
    }
  }, [value]);

  return (
    <div className={`relative flex ${className ? "" : "mb-10"}`}>
      <textarea
        onCopy={onCopy}
        onCut={onCut}
        onPaste={onPaste}
        onChange={handleChange}
        rows={rows}
        placeholder={placeholder}
        value={value || ""}
        className={`border resize-none border-Gray03 RBody16 text-Gray09 p-4 rounded-md w-full`}
        maxLength={maxlength}
      />
      {maxlength && (
        <div className="absolute bottom-[-29px] right-0 RCaption14 text-Gray05">
          {currentLength}/{maxlength}
        </div>
      )}
    </div>
  );
};

export default Textarea;
