import React, { FC } from "react";

interface Filmography {
  id: number;
  title: string;
  type: string | { code: string; label: string };
  role:
  | string
  | {
    code: string;
    label: string;
  };
  releaseYear: string;
  description: string;
  orderNumber: number;
}

interface StaffDetailCardProps {
  className?: string;
  imageUrl: string;
  staffName?: string;
  staffRole?: string;
  staffAgency?: string;
  filmography?: Filmography[] | [];
  filmographySummary?: string | null;
}

const StaffDescCard: FC<StaffDetailCardProps> = ({
  className = "",
  imageUrl,
  staffName = "",
  staffRole = "",
  staffAgency = "",
  filmography = [],
  filmographySummary = null,
}) => {
  const sortedFilmography = [...filmography].sort(
    (a, b) => Number(b.releaseYear) - Number(a.releaseYear)
  );

  const shouldRenderFilmographySection =
    filmographySummary || sortedFilmography.length > 0;

  return (
    <div className={`${className}`}>
      <div className="flex items-start gap-3">
        <img
          className="rounded-full w-12 h-12 object-cover"
          src={`${process.env.REACT_APP_MEDIA_URL}${imageUrl}`}
          alt="스탭 이미지"
        />
        <div className="flex-1 flex flex-col gap-1">
          <div className="BBody18 text-Gray09">{staffName}</div>
          <div className="flex items-start MCaption14 text-Gray05 gap-1">
            {staffRole && <span>{staffRole}</span>}
            {staffRole && staffAgency && <span>•</span>}
            {staffAgency && <span>{staffAgency}</span>}
          </div>
          {shouldRenderFilmographySection && (
            <div className="flex flex-col max-h-[136px] overflow-y-auto gap-1">
              {filmographySummary && staffRole === "매니저" && (
                <div className="RCaption12 text-Gray05 whitespace-pre-wrap">
                  {filmographySummary}
                </div>
              )}
              {sortedFilmography.length > 0 &&
                staffRole !== "매니저" &&
                sortedFilmography.map(({ id, releaseYear, title }) => (
                  <div key={id} className="flex items-start gap-2">
                    <span className="RBody14 text-Gray05">{releaseYear}</span>
                    <span className="MBody14 text-Gray09">{title}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffDescCard;
