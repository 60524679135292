import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useReadReceivedProfile, useReceivedProfiles } from 'apis/deliveryProfile';
import ReceivedProfileListCard from 'pages/Received/_components/ReceivedProfileListCard';
import { useInView } from 'react-intersection-observer';
import { splitBirth } from 'utils/onBirth';
import { ReactComponent as SelectCastingCall } from "assets/selectCastingCall.svg";
import BottomButtonPopup from 'components/BottomButtonPopup';
import { useSelectCastingProfiles } from 'apis/castingStaff';
import { Toast } from 'hooks/useToast';

const ButtonText: Record<string, string> = {
    APPLIED: "후보",
    SHORTLIST_SELECTED: "리스트업",
    FINALIST_SELECTED: "캐스팅 확정",
};

interface CastingAppliedType {
    activeTab: string;
    status: string;
    castingcallId: number;
    selectMode: boolean;
    setSelectMode: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCastingIds: number[];
    setSelectedCastingIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const CastingApplied = ({ activeTab, status, castingcallId, selectMode, setSelectMode, selectedCastingIds, setSelectedCastingIds }: CastingAppliedType) => {
    const navigate = useNavigate();

    const {
        data,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useReceivedProfiles({ castingCallId: castingcallId });

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    const allLists = useMemo(
        () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
        [data]
    );

    const { mutate: profileRead } = useReadReceivedProfile();
    const { mutate } = useSelectCastingProfiles();

    const handleShortlist = () => {
        let data = {
            castingCallId: castingcallId,
            deliveryProfileIds: selectedCastingIds,
        }

        mutate(data, {
            onSuccess: (res) => {
                Toast.success("프로필을 이동했어요.");
                setSelectedCastingIds([]);
                setSelectMode(false);
                refetch();
            },
        });
    }

    return (
        <div>
            {allLists.length === 0 ? (
                <div className="mt-36 items-center text-center">
                    <div className="text-Gray05 RBody14">받은 프로필이 없어요.</div>
                </div>
            ) : (
                <div>
                    {status === "ACTIVE" &&
                        <div className='flex items-center justify-end px-5'>
                            <div onClick={() => {
                                if (selectMode) {
                                    setSelectedCastingIds([])
                                }
                                setSelectMode(selectMode => !selectMode)
                            }} className='text-Blue04 BCaption14 cursor-pointer py-4'>
                                {selectMode ? "취소" : "단계이동"}
                            </div>
                        </div>
                    }
                    {allLists.map((item) => {
                        return (
                            <ReceivedProfileListCard
                                isMyCasting
                                key={item.deliveryProfileId}
                                rating={item.rating}
                                thumbnail={item.thumbnailUrl}
                                readAt={item?.readAt}
                                createdAt={item.createdAt}
                                name={item.profileName}
                                birth={`${splitBirth(item.birthDate)}`}
                                selectMode={selectMode}
                                height={item.height}
                                weight={item.weight}
                                description={item.description}
                                selected={selectedCastingIds.includes(
                                    item.deliveryProfileId
                                )}
                                isOffered={item.isOffered}
                                onClick={() => {
                                    if (selectMode) {
                                        if (
                                            selectedCastingIds.includes(item.deliveryProfileId)
                                        ) {
                                            setSelectedCastingIds(
                                                selectedCastingIds.filter(
                                                    (id) => id !== item.deliveryProfileId
                                                )
                                            );
                                        } else {

                                            setSelectedCastingIds([
                                                ...selectedCastingIds,
                                                item.deliveryProfileId,
                                            ]);
                                        }
                                    } else {
                                        if (item?.readAt === null) {
                                            profileRead(item?.deliveryProfileId);
                                        }
                                        navigate(
                                            `/staff/applied/${item.deliveryProfileId}`
                                        );
                                    }
                                }}
                            />
                        );
                    })}
                    {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                </div>
            )
            }
            {selectedCastingIds.length > 0 &&
                <BottomButtonPopup onClick={() => {
                    handleShortlist()
                }} onClose={() => { }}>
                    <div className="flex flex-col gap-1 pt-2 justify-center items-center MCaption14 cursor-pointer text-Gray00">
                        <SelectCastingCall />
                        {selectedCastingIds.length}명 {ButtonText[activeTab] || ""}로 이동하기</div>
                </BottomButtonPopup>
            }

        </div>
    );
};

export default CastingApplied;