import React, { useState } from "react";
import Skeleton from "./Skeleton";
import { ReactComponent as Membership } from "assets/chip-membership.svg";
import MainProfileSkeleton from "components/MainProfileSkeleton";


interface MainProfileCardType {
  title: string;
  subTitle?: string;
  description?: string;
  isMembership?: boolean;
  onClick?: () => void;
  src?: string;

  isSuccess: boolean;

}

const MainProfileCard = ({
  title,
  subTitle,
  description,
  isMembership,
  src,
  onClick,
  isSuccess,
}: MainProfileCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  return (
    <>
      {isSuccess ?
        <>
          <div className="relative">
            {MEDIA_URL && (
              <img
                src={`${MEDIA_URL}${src}`}
                alt="Thumbnail"
                onClick={onClick}
                className="object-cover w-[139px] aspect-4/5 cursor-pointer rounded-[10px]"
              />
            )}
            {isMembership && (
              <Membership
                className="absolute bottom-1 left-1"
              />
            )}
          </div>
          <div className="flex items-center gap-0.5 mt-2">
            <div className="BBody16 text-Gray09 max-w-[81px] text-ellipsis whitespace-nowrap overflow-hidden">
              {title}
            </div>
            <div className="MBody14 text-Gray05">{subTitle}</div>
          </div>
          <div className="MBody16 max-w-[145px] text-ellipsis overflow-hidden whitespace-nowrap text-Gray05">
            {description}
          </div>
        </>
        : <MainProfileSkeleton />}
    </>
  );
};

export default MainProfileCard;
