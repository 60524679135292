import React, { useEffect, useMemo } from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import { useMyCastingcallsByProduction } from 'apis/my';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router';
import { useSelectCastingProfiles } from 'apis/castingStaff';
import { Toast } from 'hooks/useToast';
import shortlistProfile from 'stores/shortlistProfile';

const ShortListSelectCastingcall = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const shortlistData = location?.state;

    const { deleteSelectedDeliveryProfileIds } = shortlistProfile();
    const { productId, selectedDeliveryProfileIds } = shortlistData;

    const {
        data,
        fetchNextPage,
        hasNextPage,
    } = useMyCastingcallsByProduction({ status: "ACTIVE", id: productId });

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    const castingcalls = useMemo(
        () =>
            data ? data?.pages?.flatMap((staff) => staff.data.content) : [],
        [data]
    );

    const mutate = useSelectCastingProfiles();

    const handleShortlist = (castingcallId: number,) => {
        const data = {
            castingCallId: castingcallId,
            deliveryProfileIds: selectedDeliveryProfileIds,
        };
        mutate.mutate(data, {
            onSuccess: (res) => {
                navigate(-1)
                deleteSelectedDeliveryProfileIds();
                Toast.success("프로필을 이동했어요.");
            },
        });
    };

    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={''} />
            <div className='BBody20 text-Gray09 px-5 py-1'>
                후보 추가할 공고를 선택해주세요
            </div>
            {castingcalls.length === 0 ? (
                <div className="mt-36 items-center text-center">
                    <div className="text-Gray05 RBody14">제안할 공고가 없습니다.</div>
                </div>
            ) : (
                <>
                    {castingcalls?.map((item) => {
                        return (
                            <div onClick={() => {
                                handleShortlist(item.castingCallId)
                            }} key={item.castingCallId} className='p-5 cursor-pointer flex flex-col items-start gap-1 border-b border-Gray02'>
                                <div className='MCaption12 text-Gray05'>
                                    {item.deadlineDate || "캐스팅 확정 시"} 마감
                                </div>
                                <div className='MBody16 text-Gray09'>{item.title}</div>
                            </div>
                        )
                    })}
                    {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                </>
            )}
        </div>
    );
};

export default ShortListSelectCastingcall;