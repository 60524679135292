import React, { useState } from "react";
import { ReactComponent as Membership } from "assets/chip-membership.svg";
import { ReactComponent as Checked } from "assets/checkedProfile.svg";
import Chip from "./Chip";
import ArtistListProfileSkeleton from "./ArtistListProfileSkeleton";

interface ArtistProfileCardType {
  src?: string;
  title?: string;
  subTitle?: string;
  description?: string;

  isMembership?: boolean;
  selectMode?: boolean;
  selected?: boolean;
  isOffered?: boolean;
  onClick?: () => void;
}

const ArtistListCard = ({
  src,
  title,
  subTitle,
  description,
  selectMode,
  isMembership,

  selected,
  isOffered,
  onClick,
}: ArtistProfileCardType) => {

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative">
      {isLoading &&
        <ArtistListProfileSkeleton />}
      <div className="relative">
        {MEDIA_URL && (
          <img
            onLoad={(e) => {
              setIsLoading(false);
            }}
            onClick={onClick}
            src={`${MEDIA_URL}${src}`}
            alt="thumbnailUrl"
            className={`w-full aspect-4/5 cursor-pointer relative object-cover rounded-md`}
          />
        )}
        {selectMode && selected && (
          <Checked className="absolute right-2 top-2 z-50" />
        )}
        {isOffered && (
          <Chip
            selected
            className="absolute bg-Blue01 h-[18px] bottom-1 left-1 !MCaption10 text-Blue04 border-none rounded-md"
            title={"제안"}
          />
        )}
        {isMembership && (
          <Membership
            className="absolute bottom-1 left-1"
          />
        )}
      </div>
      <div className="flex items-center gap-0.5 mt-2">
        <div className="BBody16 text-Gray09 max-w-[81px] text-ellipsis whitespace-nowrap overflow-hidden">
          {title}
        </div>
        {subTitle && <div className="MBody14 text-Gray05">{subTitle}</div>}
      </div>
      {description && (
        <div className="RBody14 text-Gray05 max-w-[114px] text-ellipsis overflow-hidden whitespace-nowrap">
          {description}
        </div>
      )}
    </div>
  );
};

export default ArtistListCard;

