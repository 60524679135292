import React from 'react';

const MainCastingSkeleton = () => {
    return (
        <div className="w-[139px] bg-Gray00">
            <div className="skeleton w-full max-w-[139px] aspect-4/5 bg-Gray03" />
        </div>
    );
};

export default MainCastingSkeleton;