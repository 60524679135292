import React, { useEffect, useMemo } from "react";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useRelatedCastingcalls } from "apis/my";
import CastingcallCard from "../MyCasting/_components/CastingcallCard";

const CastingReference = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const { data, fetchNextPage, hasNextPage } = useRelatedCastingcalls({});

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const relatedCastingcalls = useMemo(
    () =>
      data ? data?.pages?.flatMap((staff) => staff.data.content) : [],
    [data]
  );

  return (
    <>
      <HeaderWithBackButton title={"참여 캐스팅"} />
      <div className="pb-24">
        {relatedCastingcalls.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">참여한 캐스팅이 없어요.</div>
          </div>
        ) : (
          <div className="flex flex-col">
            {relatedCastingcalls.map((item) => {
              return (
                <CastingcallCard
                  onClick={() => {
                    navigate(
                      `/staff/casting/ref/process/${item.castingCallId}`,
                      {
                        state: {
                          title: item.title,
                          castingCallId: item.castingCallId,
                          status: item.status.code,
                        },
                      }
                    );
                  }}
                  referenceCasting
                  key={item.castingCallId}
                  deadlineDate={item.deadlineDate}
                  title={item.title}
                  castingcallId={item.castingCallId}
                  status={item?.status?.code}
                />
              );
            })}
            {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
          </div>
        )}
      </div>
    </>
  );
};

export default CastingReference;
