import React, { useEffect } from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
interface FullPageModalType {
    children: React.ReactNode;
    onClick?: () => void;
}

const FullPageModal = ({ children, onClick }: FullPageModalType) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className='fixed left-2/4 top-0 -translate-x-2/4 h-full w-full min-w-[280px] max-w-[400px] z-[9999]'>
            <HeaderWithBackButton setIsBackAction={onClick} title={''} />
            <div className='bg-Gray00 h-full overflow-auto'>
                {children}
            </div>
        </div>
    );
};

export default FullPageModal;