import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";

import { ReactComponent as Filter } from "assets/icon_filter.svg";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";

import NoticeCard from "components/NoticeCard";
import SearchBox from "components/SearchBox";
import Chip from "components/Chip";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";
import SideTab from "components/SideTab";
import Header from "components/Header";
import ButtonBackground from "components/ButtonBackground";

import { CASTING_LIST, GENDER_LIST, TYPE_LIST } from "utils/type";
import { useScroll } from "hooks/useScroll";

import { useInView } from "react-intersection-observer";
import useLocalStorage from "use-local-storage";

import { useCastingcalls } from "apis/castingcall";
import FullPage from "stores/Modal/fullPage";

const CastingList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();

  const scrollKey = `scrollIndex${location.pathname}`;
  const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);

  type SearchType = "title" | "prodTitle" | "comName" | "director";
  const LOCAL_STORAGE_KEY = "castingState";
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [sort, setSort] = useState<string>("createdAt,desc");
  const [selectedType, setSelectedType] = useState<SearchType>("title");
  const [searchValues, setSearchValues] = useState<Record<SearchType, string>>({
    title: "",
    prodTitle: "",
    comName: "",
    director: "",
  });
  const [formats, setFormats] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [gender, setGender] = useState("");
  const [debouncedSearchValues, setDebouncedSearchValues] = useState(searchValues);

  const [isSideTabOpen, setIsSideTabOpen] = useState(false);

  const { closeFullPage } = FullPage();
  const { y } = useScroll();

  useEffect(() => {
    if (scrollY !== 0 && navigationType === "POP") {
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 100);
    }
  }, [navigationType, scrollY]);

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSearchValues({
      title: "",
      prodTitle: "",
      comName: "",
      director: "",
    });
    setSelectedType(value as SearchType);
  };

  const [filters, setFilters] = useState({
    type: "",
    formats: "",
    deadlineType: "",
    roles: "",
    ageType: "",
    gender: "",
  });

  useEffect(() => {
    if (navigationType === "POP") {
      const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        setSearchValues(parsedState.searchValues || {});
        setSort(parsedState.sort || "createdAt,desc");
        setSelectedType(parsedState.selectedType || "title");
        setFormats(parsedState.formats || []);
        setRoles(parsedState.roles || []);
        setGender(parsedState.gender || "");
        setFilters(
          parsedState.filters || {
            type: "",
            formats: "",
            deadlineType: "",
            roles: "",
            ageType: "",
            gender: "",
          }
        );
      }
    }
  }, [navigationType]);

  useEffect(() => {
    const stateToSave = {
      searchValues,
      sort,
      selectedType,
      formats,
      roles,
      gender,
      filters,
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateToSave));
  }, [searchValues, sort, selectedType, formats, roles, gender, filters]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValues(searchValues);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValues]);

  const { data, hasNextPage, fetchNextPage } = useCastingcalls({
    sort: sort,
    title: debouncedSearchValues.title,
    productionTitle: debouncedSearchValues.prodTitle,
    companyName: debouncedSearchValues.comName,
    directorName: debouncedSearchValues.director,
    ...filters,
  })

  const allLists = useMemo(
    () =>
      data ? data?.pages?.flatMap((item) => item.data.content) : [],
    [data]
  );

  const {
    ref,
    inView,
  } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);

  const options = [
    { id: 0, title: "제목", value: "title" },
    { id: 1, title: "작품", value: "prodTitle" },
    { id: 2, title: "제작사", value: "comName" },
    { id: 3, title: "감독", value: "director" },
  ];

  return (
    <>
      <Header title="캐스팅" />
      <div className="pb-24 pt-2 p-5">
        <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
          <div className="pl-2 p-4 flex items-center justify-between">
            <div
              onClick={handleCloseTab}
              className="MBody18 cursor-pointer flex items-center"
            >
              <ArrowLeft />
              <div>필터</div>
            </div>
            <div
              onClick={() => {
                setGender("");
                setFormats([]);
                setRoles([]);
              }}
              className="BBody18 cursor-pointer text-Blue04"
            >
              초기화
            </div>
          </div>
          <div
            style={{ height: `${viewportHeight - 160}px` }}
            className="overflow-y-scroll "
          >
            <div className="pt-5 px-5 flex flex-col gap-4 items-start">
              <TitleWrap title="분야">
                <div className="flex items-center flex-wrap gap-2">
                  {TYPE_LIST.map((item, i) => {
                    return (
                      <Chip
                        selected={formats.includes(item.role)}
                        onClick={() => {
                          if (formats.includes(item.role)) {
                            setFormats(
                              formats.filter((f: string) => f !== item.role)
                            );
                          } else {
                            setFormats([...formats, item.role]);
                          }
                        }}
                        key={i}
                        title={item.title}
                      ></Chip>
                    );
                  })}
                </div>
              </TitleWrap>
              <TitleWrap title="배역">
                <div className="flex items-center flex-wrap gap-2">
                  {CASTING_LIST.map((item, i) => {
                    return (
                      <Chip
                        selected={roles.includes(item.role)}
                        onClick={() => {
                          if (roles.includes(item.role)) {
                            setRoles(
                              roles.filter((r: string) => r !== item.role)
                            );
                          } else {
                            setRoles([...roles, item.role]);
                          }
                        }}
                        key={i}
                        title={item.title}
                      ></Chip>
                    );
                  })}
                </div>
              </TitleWrap>
              <TitleWrap title="성별">
                <div className="flex items-center flex-wrap gap-2">
                  {GENDER_LIST.map((item, i) => {
                    return (
                      <Chip
                        selected={item.role === gender}
                        onClick={() => {
                          if (gender === item.role) {
                            setGender("");
                          } else {
                            setGender(item.role);
                          }
                        }}
                        key={i}
                        title={item.title}
                      ></Chip>
                    );
                  })}
                </div>
              </TitleWrap>
            </div>
          </div>
          <ButtonBackground className="w-full">
            <Button
              onClick={() => {
                setFilters({
                  ...filters,
                  formats: formats.join(","),
                  gender: gender,
                  roles: roles.join(","),
                });
                handleCloseTab();
              }}
              className="w-full border-none bg-Blue04 text-white"
              text={"필터 적용하기"}
            ></Button>
          </ButtonBackground>
        </SideTab>
        <div className="flex items-center gap-4">
          <SearchBox
            onDropDownChange={onTypeChange}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValues({
                ...searchValues,
                [selectedType]: value,
              });
            }}
            value={searchValues[selectedType]}
            placeholder="작품, 제목, 제작사, 감독"
          >
            {options.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </SearchBox>
        </div>
        <div className="flex items-center my-4 justify-between">
          <select
            onChange={(e) => {
              setSort(e.target.value);
            }}
            value={sort}
          >
            <option value="createdAt,desc">등록순 ↓</option>
            <option value="createdAt,asc">등록순 ↑</option>
            <option value="updatedAt,desc">최신순 ↓</option>
            <option value="updatedAt,asc">최신순 ↑</option>
          </select>
          <Filter className="cursor-pointer" onClick={handleOpenTab} />
        </div>
        {allLists.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">모집중인 캐스팅이 없어요.</div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col gap-4">{
              allLists?.map((staffItem: any) => {
                const now = new Date();
                const deadlineDate = new Date(staffItem?.deadlineDate);

                const timeDifference = deadlineDate.getTime() - now.getTime();
                const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                const isDeadlineSoon = staffItem?.deadlineDate !== null && dayDifference < 2;
                return (
                  <NoticeCard
                    key={staffItem.castingCallId}
                    title={staffItem.title}
                    productionTitle={staffItem.productionTitle}
                    src={staffItem.thumbnailUrl}
                    type={staffItem?.productionFormat?.label}
                    gender={staffItem.gender.label}
                    age={`${staffItem.minAge}세 ~ ${staffItem.maxAge}세`}
                    deadlineSoon={isDeadlineSoon}
                    deadline={
                      staffItem?.deadlineDate
                        ? `${staffItem?.deadlineDate}까지`
                        : "캐스팅 확정 시 마감"
                    }
                    onClick={() => {
                      closeFullPage();
                      setScrollY(y)
                      navigate(`/casting/${staffItem.castingCallId}`);
                    }}
                  />
                );
              })}
              {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CastingList;
