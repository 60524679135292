import React, { useEffect, useMemo, useState } from "react";

import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import useLocalStorage from "use-local-storage";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import BottomButtonPopup from "components/BottomButtonPopup";
import { ReactComponent as SelectCastingCall } from "assets/selectCastingCall.svg";
import { ReactComponent as List } from "assets/listType.svg";
import { ReactComponent as Tiled } from "assets/tiledType.svg";

import { Toast } from "hooks/useToast";
import { splitBirth } from "utils/onBirth";
import { useScroll } from "hooks/useScroll";

import { useReadReceivedProfile, useReceivedProfiles } from "apis/deliveryProfile";
import ReceivedProfileListCard from "./_components/ReceivedProfileListCard";
import shortlistProfile from "stores/shortlistProfile";
import ArtistListCard from "components/ArtistListCard";

const ReceivedProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();

  const { selectedDeliveryProfileIds, setSelectedDeliveryProfileIds, deleteSelectedDeliveryProfileIds } = shortlistProfile();

  const scrollKey = `scrollIndex${location.pathname}`;

  const [listType, setListType] = useState("list");
  const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);
  const [selectedList, setSelectedList] = useState<number[]>(selectedDeliveryProfileIds || []);
  const [selectMode, setSelectMode] = useState(selectedDeliveryProfileIds.length ? true : false);

  const { y } = useScroll();

  useEffect(() => {
    setScrollY(y);
  }, [y]);

  useEffect(() => {
    if (scrollY !== 0 && navigationType === "POP") {
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 100);
    }
  }, [navigationType, scrollY]);

  const { mutate: profileRead } = useReadReceivedProfile();

  // 받은 프로필
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const {
    data: received,
    fetchNextPage,
    hasNextPage,
  } = useReceivedProfiles({});

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const receivedLists = useMemo(
    () =>
      received
        ? received?.pages?.flatMap((profile) => profile.data.content)
        : [],
    [received]
  );

  const handleClick = (receivedItem: {
    deliveryProfileId: number;
    readAt: null | string;
    rating: number | null;
  }) => {
    const { rating, deliveryProfileId, readAt } = receivedItem;

    if (selectMode) {
      if (!rating) {
        return Toast.error("피드백 작성 후 후보로 추가할 수 있어요.");
      }
      setSelectedList((prevSelectedList) =>
        prevSelectedList.includes(deliveryProfileId)
          ? prevSelectedList.filter((id) => id !== deliveryProfileId)
          : [...prevSelectedList, deliveryProfileId]
      );
      return;
    }

    if (!readAt) {
      profileRead(deliveryProfileId);
    }

    navigate(`/staff/received/${deliveryProfileId}`);
  };

  return (
    <>
      <HeaderWithBackButton setIsBackAction={() => {
        deleteSelectedDeliveryProfileIds()
        navigate(-1);
      }} title="받은 프로필">
        <div
          onClick={() => {
            setSelectMode((selectMode) => !selectMode);
            if (selectMode) {
              setSelectedList([]);
              deleteSelectedDeliveryProfileIds()
            }
          }}
          className="text-Blue04 min-w-[65px] BBody14 cursor-pointer text-right"
        >
          {receivedLists?.length !== 0 && (
            <>{selectMode ? "취소" : "후보추가"}</>
          )}
        </div>
      </HeaderWithBackButton>
      <div className="pb-24">
        {receivedLists.length === 0 ? (
          <div className="mt-36 pt-5 items-center text-center">
            <div className="text-Gray05 RBody14">받은 프로필이 없어요.</div>
          </div>
        ) : (
          <div>
            {/* <div className="mt-5 mb-4 flex items-center px-5 gap-4 justify-end">
              <Tiled onClick={() => {
                setListType("tiled")
              }} className={`cursor-pointer ${listType === "tiled" ? "" : " opacity-50"}`} />
              <List onClick={() => {
                setListType("list")
              }} className={`cursor-pointer ${listType === "list" ? "" : " opacity-50"}`} />
            </div> */}
            <div className={`${listType === "tiled" && "grid grid-cols-3 gap-y-4 gap-x-2 px-5"}`}>
              {receivedLists.map((receivedItem) => {
                const readAt = receivedItem.readAt;
                const createdAt = receivedItem.createdAt;
                if (listType === "list") {
                  return (
                    <ReceivedProfileListCard
                      isMyCasting
                      key={receivedItem.deliveryProfileId}
                      rating={receivedItem.rating}
                      thumbnail={receivedItem.thumbnailUrl}
                      readAt={receivedItem?.readAt}
                      createdAt={createdAt}
                      name={receivedItem.profileName}
                      birth={`${splitBirth(receivedItem.birthDate)}`}
                      selectMode={selectMode}
                      height={receivedItem.height}
                      weight={receivedItem.weight}
                      description={receivedItem.description}
                      selected={selectedList.includes(
                        receivedItem.deliveryProfileId
                      )}
                      isOffered={receivedItem.isOffered}
                      onClick={() => handleClick(receivedItem)}
                    />
                  );
                } else {
                  return (
                    <ArtistListCard
                      key={receivedItem.deliveryProfileId}
                      src={receivedItem.thumbnailUrl}
                      title={receivedItem.profileName}
                      subTitle={`${splitBirth(receivedItem.birthDate)}`}
                      selectMode={selectMode}
                      selected={selectedList.includes(
                        receivedItem.deliveryProfileId
                      )}
                      isOffered={receivedItem.isOffered}
                      onClick={() => {
                        if (selectMode) {
                          if (
                            selectedList.includes(receivedItem.deliveryProfileId)
                          ) {
                            setSelectedList(
                              selectedList.filter(
                                (id) => id !== receivedItem.deliveryProfileId
                              )
                            );
                          } else {
                            setSelectedList([
                              ...selectedList,
                              receivedItem.deliveryProfileId,
                            ]);
                          }
                        } else {
                          if (readAt === null) {
                            profileRead(receivedItem?.deliveryProfileId);
                          }
                          navigate(
                            `/staff/received/${receivedItem.deliveryProfileId}`
                          );
                        }
                      }}
                    />
                  )
                }
              })}
              {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
            </div>
          </div>
        )}
        {selectMode && (
          <BottomButtonPopup
            onClose={() => {
              setSelectMode(false);
            }}
          >
            <div
              onClick={() => {
                if (selectedList.length !== 0) {
                  navigate('/staff/shortlist/product', { state: { selectedDeliveryProfileIds: selectedList } })
                  setSelectedDeliveryProfileIds(selectedList)
                } else {
                  Toast.error("프로필을 선택해주세요");
                }
              }}
              className="flex flex-col gap-1 pt-2 justify-center items-center MCaption14 cursor-pointer text-Gray00"
            >
              <SelectCastingCall />
              <div>{selectedList.length !== 0 && `${selectedList.length}명`} 후보추가</div>
            </div>
          </BottomButtonPopup>
        )}
      </div >
    </>
  );
};

export default ReceivedProfile;
