import React from "react";

import Thumbnail from "assets/thumbnail.png";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useParams } from "react-router-dom";
import { useMyProduction } from "apis/my";

const StaffMyProductDetail = () => {
  const { productId } = useParams();
  const { data } = useMyProduction(Number(productId));

  const myProductData = data?.data;

  const productInfo = [
    {
      id: 0,
      title: "분야",
      desc: myProductData?.format.label,
    },
    {
      id: 1,
      title: "감독",
      desc: myProductData?.directorName,
    },
    {
      id: 2,
      title: "제작사",
      desc: myProductData?.companyName,
    },
    {
      id: 3,
      title: "소속사",
      desc: myProductData?.agencyName,
    },
    {
      id: 4,
      title: "에이전시",
      desc: myProductData?.adAgencyName,
    },
    {
      id: 5,
      title: "캐스팅",
      desc: myProductData?.castingDirectorName,
    },
  ];

  return (
    <>
      <HeaderWithBackButton title={""} />
      <div className="py-5 px-5 pb-24">
        <div className="flex mb-4 items-center justify-between">
          <div className="BBody20">{myProductData?.title}</div>
        </div>
        <div className="flex items-start gap-4">
          <div className="w-full flex-col gap-2 flex MBody14">
            {productInfo.map((item) => {
              if (item.desc)
                return (
                  <div key={item.id} className="flex items-start gap-2">
                    <div className="w-[74px] text-Gray05">{item.title}</div>
                    <div className="flex-1">{item.desc}</div>
                  </div>
                );
            })}
          </div>
          <img
            src={
              myProductData?.thumbnailUrl !== null
                ? `${process.env.REACT_APP_MEDIA_URL}${myProductData?.thumbnailUrl}`
                : Thumbnail
            }
            className="max-w-[109px] object-cover rounded-[10px] flex-1 h-[136px]"
            alt="filmoDetailImage"
          />
        </div>
        <div>
          <div className="RBody16 whitespace-break-spaces break-words my-4 mb-10">
            {myProductData?.description}
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffMyProductDetail;
