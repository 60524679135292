import React from 'react';

const MainProfileSkeleton = () => {
    return (
        <div className="w-[139px] aspect-4/5 bg-Gray00">
            <div className="skeleton w-full h-full bg-Gray03" />
            <div className="skeleton mt-2 mb-3 w-full h-5 bg-Gray03" />
            <div className="skeleton w-full h-4 bg-Gray03" />
        </div>
    );
};

export default MainProfileSkeleton;