import MainCastingSkeleton from "components/MainCastingSkeleton";
import React from "react";

interface MainCastingCardardType {
  type: string;
  title: string;
  src?: string;
  onClick?: () => void;

  isSuccess: boolean;
}

const MainCastingCard = ({ type, title, src, onClick, isSuccess }: MainCastingCardardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  return (
    <div>
      {isSuccess ?
        <div
          onClick={onClick}
          style={{
            backgroundImage: `url(${MEDIA_URL}${src})`,
          }}
          className={`relative cursor-pointer bg-center w-[139px] bg-no-repeat aspect-4/5 bg-cover rounded-[10px]`}
        >
          <div
            className="absolute inset-0 rounded-b-[10px]"
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0) 64.52%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0.4))",
            }}
          ></div>
          <div className="absolute text-white bottom-2 left-2">
            <div className="RCaption12">{type ? type : "광고"}</div>
            <div className="BCaption14 max-w-[123px] overflow-hidden text-ellipsis whitespace-nowrap">
              {title}
            </div>
          </div>
        </div>
        :
        <MainCastingSkeleton />
      }
    </div>
  );
};

export default MainCastingCard;
