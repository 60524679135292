import React from 'react';

const ArtistListProfileSkeleton = () => {
    return (
        <div className="w-full absolute z-50 aspect-4/5 bg-Gray00">
            <div className="skeleton w-full h-full bg-Gray03" />
            <div className="skeleton mt-2 mb-3 w-2/4 h-5 bg-Gray03" />
        </div>
    );
};

export default ArtistListProfileSkeleton;