import React from 'react';

interface checkboxSquareType {
    title?: string;
    className?: string;
    checked?: boolean;
    id?: string;

    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


const CheckboxSquare = ({
    title, className, checked, id, onClick, onChange
}: checkboxSquareType) => {
    return (
        <div className="flex items-center">
            <input
                checked={checked}
                onChange={onChange}
                id={id}
                type="checkbox"
                readOnly
                className="appearance-none w-4 h-4 bg-squareCheck checked:bg-squareChecked bg-no-repeat bg-center"
            />
            {title &&
                <label htmlFor={id} className={`ml-2 ${className} MCaption14`}>
                    {title}
                </label>
            }
        </div>
    );
};

export default CheckboxSquare;