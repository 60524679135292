import React, { useState } from "react";

import { ReactComponent as Bookmark } from "assets/Bookmark.svg";
import { ReactComponent as Share } from "assets/share.svg"

import { useParams } from "react-router-dom";
import { ReactComponent as Feedback } from "assets/icon_checkbox.svg";
import { ReactComponent as BlankStar } from "assets/blankStar.svg";
import { ReactComponent as Star } from "assets/star.svg";
import BottomButtonPopup from "components/BottomButtonPopup";
import BottomPopup from "components/BottomPopup";
import Button from "components/Button";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Modal from "components/Modal";
import { handleCopyClipBoard } from "utils/onCopyClipBoard";
import ScrollToTop from "components/ScrollToTop";
import ButtonBackground from "components/ButtonBackground";
import ArtistAttachments from "./_components/ArtistAttachments";
import ArtistDesc from "./_components/ArtistDesc";
import ArtistFilmographies from "./_components/ArtistFilmographies";
import ArtistInfoCard from "./_components/ArtistInfoCard";
import Skeleton from "components/Skeleton";
import { useReceivedProfile } from "apis/deliveryProfile";
import { useShareLink } from "apis/profileShare";
import { LineButton } from "components/LineButton";

const ArtistApplied = () => {
    const [tabId, setTabId] = useState(0);

    const { artistId } = useParams();

    const [copyModal, setCopyModal] = useState(false);
    const [profileId, setProfileId] = useState<null | number>(null);
    const [feedback, setFeedback] = useState(false);

    const profileTab = [
        { id: 0, title: "프로필" },
    ]

    const { data, isFetching, refetch } = useReceivedProfile(
        Number(artistId)
    );

    const ArtistData = data?.data;

    const readRatingStartValue = () => {
        let result = [];
        for (let i = 0; i < 5; i++) {
            result.push(
                <div key={i + 1}>
                    {i + 1 <= ArtistData?.rating! ? <Star className="w-8 h-8" /> : <BlankStar className="w-8 h-8" />}
                </div>
            );
        }
        return result;
    };

    const { data: link } = useShareLink({
        id: profileId,
        type: "DELIVERY_PROFILE",
        period: "MONTH",
    });

    return (
        <>
            <HeaderWithBackButton
                phone={ArtistData?.phoneNumber}
                sms={ArtistData?.phoneNumber}
                email={ArtistData?.email}
                title=""
            >
                {/* <Bookmark className="cursor-pointer" /> */}
                <Share
                    onClick={() => {
                        setCopyModal(true);
                        setProfileId(ArtistData?.deliveryProfileId!);
                    }}
                    className="cursor-pointer"
                />
            </HeaderWithBackButton>
            {isFetching ? (
                <Skeleton type="PROFILE_DETAIL" />
            ) : (
                <>
                    <div className="pb-24 pt-5">
                        <ScrollToTop />
                        {ArtistData?.description && (
                            <div className="px-5">
                                <div className="p-5 w-full border mb-5 border-Gray03 bg-Gray01 rounded-md">
                                    <div className="w-full text-Gray09 BBody16">
                                        <div>💌 {ArtistData?.profileName}님이 보낸 프로필</div>
                                        {/* <ArrowDown /> */}
                                    </div>
                                    <div className="text-Gray09 break-words whitespace-break-spaces RBody14 mt-4">
                                        {ArtistData?.description}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="w-full">
                            <ArtistInfoCard
                                artistName={ArtistData?.profileName!}
                                artistImageUrl={ArtistData?.thumbnailUrl!}
                                jobs={ArtistData?.jobs!}
                                birth={ArtistData?.birthDate!}
                                height={ArtistData?.basicInfo.height!}
                                weight={ArtistData?.basicInfo.weight!}
                                instagram={ArtistData?.snsInfo.instagram!}
                                youtube={ArtistData?.snsInfo.youtube!}
                                isSharedPage
                            />
                            <div className="border-b border-Gray-3 mt-4">
                                <div className="px-5 flex items-start gap-4 BCaption14">
                                    {profileTab.map((item) => {
                                        return (
                                            <div key={item.id}
                                                onClick={() => { setTabId(item.id) }}
                                                className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                                                {item.title}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {tabId === 0 ? (
                                <div>
                                    <ArtistAttachments
                                        attachments={ArtistData?.attachments!}
                                        videoLinks={ArtistData?.videoLinks!} />
                                    <ArtistDesc
                                        hobbies={ArtistData?.hobbies!}
                                        address={ArtistData?.basicInfo.address.sido!}
                                        education={ArtistData?.basicInfo.education!}
                                        agency={ArtistData?.basicInfo.agency!}
                                        introduce={ArtistData?.introduce!}
                                    />
                                    {ArtistData?.filmographies?.length! > 0 &&
                                        <ArtistFilmographies filmographies={ArtistData?.filmographies!} />
                                    }
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                        {copyModal && (
                            <Modal
                                onClose={() => {
                                    setProfileId(null);
                                    setCopyModal(false);
                                }}
                                title="공유하기"
                                subTitle="공유된 링크는 30일 동안 유효해요."
                                closeButton
                            >
                                <Button
                                    onClick={() => {
                                        handleCopyClipBoard(
                                            `${process.env.REACT_APP_URL}/artist/share?value=${link
                                                ?.data.value!}&type=PROFILE`
                                        );
                                        setCopyModal(false);
                                    }}
                                    className="w-full border border-Gray03 text-Gray05"
                                    text={"링크 복사하기"}
                                ></Button>
                            </Modal>
                        )}
                        {feedback && (
                            <BottomPopup
                                onClose={() => {
                                    setFeedback(false);
                                }}
                            >
                                <div className="BBody20">보낸 프로필 피드백</div>
                                <div className="my-4 flex items-center justify-between">
                                    <div className="flex items-center gap-1">
                                        <div className="RBody16 text-Gray09">별점</div>
                                        <div className="BBody16 text-Blue04">
                                            {ArtistData?.rating ? ArtistData?.rating : 0}/5
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-1">
                                        {readRatingStartValue()}
                                    </div>
                                </div>
                                {ArtistData?.comments && (
                                    <div className="p-4 bg-Gray01 max-h-64 rounded-md RBody16 break-words overflow-y-auto whitespace-break-spaces">
                                        {ArtistData.comments}
                                    </div>
                                )}
                                <ButtonBackground>
                                    <LineButton
                                        onClick={() => {
                                            setFeedback(false);
                                        }}
                                        variant="normal"
                                        size="B"
                                        text="닫기"
                                    />
                                </ButtonBackground>
                            </BottomPopup>
                        )}
                        {ArtistData?.rating && (
                            <BottomButtonPopup
                                onClose={() => { }}
                            >
                                <div className="flex items-start justify-between">
                                    <div
                                        onClick={() => (setFeedback(true))}
                                        className="flex flex-col h-full flex-1 justify-center items-center MBody14 cursor-pointer text-Gray00 pt-2">
                                        <Feedback />
                                        <div>피드백</div>
                                    </div>
                                </div>

                            </BottomButtonPopup>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default ArtistApplied;