import React from "react";

import { useParams } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useMyCastingcall } from "apis/my";

const MyCasting = () => {
  const { itemId } = useParams();
  const { data: productCastingCall } = useMyCastingcall(Number(itemId));

  const CastingDetail = productCastingCall?.data;

  const deadline =
    CastingDetail?.deadlineDate &&
    `${CastingDetail?.deadlineDate?.split("-")[1]}월 ${CastingDetail?.deadlineDate?.split("-")[2]
    }일`;

  const castingNotice = [
    // { label: "작품제목", value: CastingDetail?.title },
    { label: "분야", value: CastingDetail?.castingCallFormat?.label },
    { label: "제작사", value: CastingDetail?.companyName },
    { label: "감독", value: CastingDetail?.directorName },
    { label: "소속사", value: CastingDetail?.agencyName },
    { label: "에이전시", value: CastingDetail?.adAgencyName },
    { label: "배역", value: CastingDetail?.shootingDetails.role.label },
    { label: "성별", value: CastingDetail?.shootingDetails.gender.label },
    { label: "나이", value: `${CastingDetail?.shootingDetails.minAge}세 ~ ${CastingDetail?.shootingDetails.maxAge}세` },
    { label: "출연료", value: CastingDetail?.shootingDetails.fee },
    { label: "마감일자", value: deadline },
    { label: "캐스팅", value: CastingDetail?.staffName },
  ]

  return (
    <>
      <HeaderWithBackButton title={""} />
      <div className="px-5 pt-5 pb-24">
        <div>
          <div className="flex justify-between items-center MBody16">
            <div className="BBody20">{CastingDetail?.title}</div>
          </div>
          {CastingDetail?.productionTitle && (
            <div
              onClick={() => {
                //   navigate(`/staff/product/${CastingDetail?.productionId}`);
              }}
              className="BBody16 cursor-pointer mt-2 inline-block"
            >/zz
              {CastingDetail?.productionTitle}
            </div>
          )}
          {CastingDetail?.attachments.map((item: any) => {
            if (item.type.code === "THUMBNAIL")
              return (
                <div className="mt-4">
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                    className="w-full h-[360px] object-cover rounded-[10px]"
                    alt="thumbnail"
                  />
                </div>
              );
          })}
          <div className="flex mt-4 flex-col mb-10 items-start gap-4">
            <div className="w-full">
              <div>
                <div className="bg-Gray01 w-full rounded-md p-4 flex flex-col gap-2">
                  {castingNotice.map(({ label, value }) => {
                    if (value) {
                      return (
                        <div key={label} className="flex items-start MCaption14 gap-4">
                          <div className="w-[60px] text-Gray05">{label}</div>
                          <div className="flex-1 break-words text-Gray09">{value}</div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-10 w-full">
            {CastingDetail?.description &&
              <div>
                <div className="BBody18 text-Gray09">상세내용</div>
                <div className="text-Gray09 whitespace-break-spaces break-words RBody16 mt-4">
                  {CastingDetail?.description}</div>
              </div>
            }
            <div>
              {CastingDetail?.attachments.some(
                (attachment: any) => attachment.type.code === "FILE"
              ) && (
                  <>
                    <div className="BBody18">첨부파일</div>
                    <div className="RBody16 mt-4">
                      {CastingDetail?.attachments.map((attItem: any) => {
                        if (attItem.type.code === "FILE")
                          return (
                            <div
                              className="cursor-pointer inline-block"
                              onClick={() => {
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_URL}${attItem?.url}`,
                                  "_blank",
                                  "noopener, noreferrer"
                                );
                              }}
                              key={attItem.id}
                            >
                              {attItem?.originalFilename}
                            </div>
                          );
                      })}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCasting;
