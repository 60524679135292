import { useState } from "react";

import Button from "components/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Toast } from "hooks/useToast";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import ButtonBackground from "components/ButtonBackground";
import ScrollToTop from "components/ScrollToTop";
import { MY_ROLE } from "utils/onRoleStatus";
import { Profile } from "pages/StaffDetail";

import { usePointCheck, usePointCheckByRole } from "apis/point";
import { useCheckAlreadySentProfile, useDelveryProfileToCasting } from "apis/deliveryProfile";
import { useCastingcall } from "apis/castingcall";
import useLoginInfo from "stores/loginInfo";
import FullPageModal from "components/Modal/FullPageModal";

import { ReactComponent as ProfileSend } from 'assets/profileSend.svg'
import { FillButton } from "components/FillButton";

const CastingDetail = () => {
  const navigate = useNavigate();
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const role = MY_ROLE();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedProfiles, setSelectedProfiles] = useState<Profile[]>([]);

  const { loginInfo } = useLoginInfo();

  const [sendProfileData, setSendProfileData] = useState<{
    staffId: number | null;
    profileIds: null | number[];
    castingCallId: number | null;
    type: "CASTING";
  }>({
    staffId: null,
    profileIds: null,
    castingCallId: null,
    type: "CASTING",
  });

  const { itemId } = useParams();

  const { data, isLoading } = useCastingcall(Number(itemId));
  const CastingDetail = data?.data;

  const { data: rolePointData } = usePointCheckByRole({
    role: CastingDetail?.staffRole?.code!,
    type: sendProfileData.type
  });

  const rolePoint = rolePointData?.data;

  const deadline =
    CastingDetail?.deadlineDate &&
    `${CastingDetail?.deadlineDate?.split("-")[1]}월 ${CastingDetail?.deadlineDate?.split("-")[2]
    }일`;

  const castingNotice = [
    { label: "작품제목", value: CastingDetail?.productionTitle },
    { label: "분야", value: CastingDetail?.productionFormat?.label },
    { label: "제작사", value: CastingDetail?.companyName },
    { label: "감독", value: CastingDetail?.directorName },
    { label: "소속사", value: CastingDetail?.agencyName },
    { label: "에이전시", value: CastingDetail?.adAgencyName },
    { label: "배역", value: CastingDetail?.shootingDetails.role.label },
    { label: "성별", value: CastingDetail?.shootingDetails.gender.label },
    { label: "나이", value: `${CastingDetail?.shootingDetails.minAge}세 ~ ${CastingDetail?.shootingDetails.maxAge}세` },
    { label: "출연료", value: CastingDetail?.shootingDetails.fee },
    { label: "마감일자", value: deadline },
    { label: "캐스팅", value: CastingDetail?.staffName },
  ]

  const profileSendNotice = [
    { label: "공고제목", value: CastingDetail?.title },
    { label: "배역", value: CastingDetail?.shootingDetails?.role.label },
    { label: "성별", value: CastingDetail?.shootingDetails.gender.label },
    { label: "나이", value: `${CastingDetail?.shootingDetails.minAge}세 ~ ${CastingDetail?.shootingDetails.maxAge}세` },
    { label: "출연료", value: CastingDetail?.shootingDetails.fee },
    { label: "마감일자", value: deadline },
  ]

  const mutate = useDelveryProfileToCasting();

  const usePoint = selectedProfiles.length * (rolePoint?.point || 0);

  const {
    isError,
  } = usePointCheck(usePoint);

  const canSendProfile = role === "ROLE_ARTIST";

  const { data: isSend, refetch } = useCheckAlreadySentProfile({
    profileIds: [loginInfo.profileId!].join(","),
    castingCallId: CastingDetail?.castingCallId
  });


  const handleSendProfileClick = () => {
    if (role === "ROLE_ARTIST" && loginInfo.profileId === null) {
      navigate("/artist/my");
    } else {
      setSearchParams({ "casting-apply": "true" });
      setSendProfileData({
        ...sendProfileData,
        staffId: CastingDetail?.staffId,
        castingCallId: CastingDetail?.castingCallId,
        profileIds: [loginInfo.profileId!]
      })
    }
  };

  const profileSend = () => {
    mutate.mutate(sendProfileData, {
      onSuccess: () => {
        Toast.success("공고 지원 완료!");
        refetch()
        setSendProfileData({
          ...sendProfileData,
          staffId: null,
          profileIds: null,
          castingCallId: null,
          type: "CASTING",
        })
        setSelectedProfiles([])
        navigate(-1);
      },
    },

    );
  };

  if (isLoading) return null;

  return (
    <>
      <HeaderWithBackButton title={""} />
      {/* {CastingDetail?.productionThumbnailUrl &&
        <img className="w-[400px] h-[400px] object-cover" src={MEDIA_URL + CastingDetail.productionThumbnailUrl}
          alt="" />
      } */}
      <div className="pb-24 p-5">
        <ScrollToTop />
        <div>
          <div className="BBody20">{CastingDetail?.title}</div>
          {CastingDetail?.attachments.map((item: any) => {
            if (item.type.code === "THUMBNAIL") {
              return (
                <div key={item.id} className="mt-4">
                  {MEDIA_URL && (
                    <img
                      src={`${MEDIA_URL}${item.url}`}
                      className="w-full h-[360px] object-cover rounded-[10px]"
                      alt="thumbnail"
                    />
                  )}
                </div>
              );
            }

            return null;
          })}
          <div className="flex mt-4 flex-col mb-10 items-start gap-4">
            <div className="w-full">
              <div className="bg-Gray01 w-full rounded-md p-4 flex flex-col gap-2">
                {castingNotice.map(({ label, value }) => {
                  if (value) {
                    return (
                      <div key={label} className="flex items-start MCaption14 gap-4">
                        <div className="w-[60px] text-Gray05">{label}</div>
                        <div className="flex-1 break-words text-Gray09">{value}</div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-10 w-full">
            {CastingDetail?.description &&
              <div>
                <div className="BBody18 text-Gray09">상세내용</div>
                <div className="text-Gray09 whitespace-break-spaces break-words RBody16 mt-4">
                  {CastingDetail?.description}</div>
              </div>
            }

            {CastingDetail?.attachments.some(
              (attachment: any) => attachment.type.code === "FILE"
            ) && (
                <div>
                  <div className="BBody18">첨부파일</div>
                  <div className="RBody16 mt-4">
                    {CastingDetail?.attachments.map((attItem: any) => {
                      if (attItem.type.code === "FILE") {
                        return (
                          <div
                            className="cursor-pointer inline-block"
                            onClick={() => {
                              window.open(
                                `${MEDIA_URL && MEDIA_URL}${attItem?.url}`,
                                "_blank",
                                "noopener, noreferrer"
                              );
                            }}
                            key={attItem.id}
                          >
                            {attItem?.originalFilename}
                          </div>
                        );
                      }

                      return null;
                    })}
                  </div>
                </div>
              )}
          </div>
        </div>
        {canSendProfile && (
          <ButtonBackground>
            <Button
              disabled={isSend?.data[0]?.isDelivery === true}
              onClick={handleSendProfileClick}
              className="bg-Blue04 w-full py-3 MBody16 text-white border-none"
              text={`${isSend?.data[0]?.isDelivery === true ? "이미 지원한 공고예요" : "지원하기"}`}
            />
          </ButtonBackground>
        )}
      </div>

      {/* 중복 지원 모달 */}
      {/* <BottomPopup onClose={() => { }}>
        <div className="text-Gray09 BBody20">
          이전에 지원한 캐스팅 공고예요. <br />
          프로필을 다시 보낼까요?
        </div>
        <div className="MBody16 text-Gray05 mt-4">
          이전에 [영화 종이의 집 공고에] [아티스트, 아티스트, 아티스트 님의] 프로필을 보냈어요.
          한번 더 보낼까요?
        </div>
        <ButtonBackground>
          <FillButton variant="Secondary" size="B" text="닫기" />
          <FillButton variant="Membership" size="B" text="보내기" />
        </ButtonBackground>
      </BottomPopup> */}

      {/* 매니저 아티스트 선택 모달 */}
      {/* {searchParams.get("manager-select-artist") &&
        (
          <ManagerSendArtistProfile
            buttonText={isError ? "포인트가 부족해요" : `프로필 ${selectedProfiles.length}개 보내기`}
            title="어떤 프로필을 보낼까요?"
            disabled={isError}
            onClick={() => {
              setSendProfileData({
                ...sendProfileData,
                staffId: CastingDetail?.staffId,
                castingCallId: CastingDetail?.castingCallId,
                profileIds: selectedProfiles.map((item) => item.profileId)
              })
              setSearchParams({ "casting-apply": "true" });
            }}
            setIsActive={() => { navigate(-1) }}
            setSelectedProfiles={setSelectedProfiles}
            isActive={searchParams.get("manager-select-artist") ? true : false}
            selectedProfiles={selectedProfiles}
          />
        )} */}

      {/* 프로필 보내기 모달 */}
      {searchParams.get("casting-apply") &&
        <FullPageModal onClick={() => {
          navigate(-1)
        }}>
          <div className="px-5 pt-10 pb-40">
            <div className="BBody20 flex flex-col gap-7 mx-auto">
              <div>
                <div className="BBody20 text-center">{data.data.castingCallFormat?.label} {CastingDetail?.title} 공고에<br />
                  지원할까요?
                </div>
              </div>
              <ProfileSend className="mx-auto" />
              <div className="bg-Gray01 w-full rounded-md p-4 flex flex-col gap-2">
                {profileSendNotice.map(({ label, value }) => {
                  if (value) {
                    return (
                      <div key={label} className="flex items-start MCaption14 gap-4">
                        <div className="w-[60px] text-Gray05">{label}</div>
                        <div className="flex-1 break-words text-Gray09">{value}</div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div className="mt-20 text-Gray05 flex flex-col gap-4">
              <div className="BCaption14">프로필을 보내기 전에 확인해 주세요.</div>
              <ol className="list-disc pl-4 RCaption14 space-y-2">
                <li>프로필은 보낸 시점의 프로필이 보여져요 중복지원이 불가능하기 때문에 신중하게 보내주세요.</li>
                <li>프로필에 연기영상/자유영상을 첨부하면 캐스팅 확률이 높아져요.</li>
                <li>프로필 받은 스탭 회원은 내 연락처를 열람할 수 있어요.</li>
                <li>
                  캐스팅 공고는 캐스팅보트가 관리하지 않으며 이로 인하여 생기는 불이익에 대해서 당사의 책임이 없으니 사전에 확인 및 참고해주시기 바랍니다.
                </li>
              </ol>
            </div>
          </div>
          <ButtonBackground>
            <FillButton
              variant="Membership"
              size="B"
              isLoading={mutate.isPending}
              onClick={() => {
                profileSend();
              }}
              text={`지원하기`}
            />
          </ButtonBackground>
        </FullPageModal>
      }
    </>
  );
};

export default CastingDetail;
