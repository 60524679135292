import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import requestToCastingVote from "./api";

import {
  selectCastingProfilesRequest,
  changeCastingStatusRequest,
  castingsByCastingCallRequest,
  castingByCastingCallRequest,
} from "types/castingStaff";

// 캐스팅 후보 선정
const selectCastingProfiles = async (
  selectCastingProfilesRequest: selectCastingProfilesRequest
) => {
  return await requestToCastingVote
    .patch(`castings/shortlist`, selectCastingProfilesRequest)
    .then((res) => res.data);
};

const useSelectCastingProfiles = () => {
  return useMutation({
    mutationFn: selectCastingProfiles,
  });
};

// 캐스팅 상태 변경
const changeCastingStatus = async (
  changeCastingStatusRequest: changeCastingStatusRequest
) => {
  return await requestToCastingVote
    .patch(`castings/status`, changeCastingStatusRequest)
    .then((res) => res.data);
};

const useChangeCastingStatus = () => {
  return useMutation({
    mutationFn: changeCastingStatus,
  });
};

// 공고별 캐스팅 조회
const getCastingsByCastingCall = async (
  castingsByCastingCallRequest: castingsByCastingCallRequest
) => {
  return await requestToCastingVote
    .get(`castingcalls/${castingsByCastingCallRequest.id}/castings`, {
      params: castingsByCastingCallRequest,
    })
    .then((res) => res.data);
};

const useCastingsByCastingCall = (
  castingsByCastingCallRequest: castingsByCastingCallRequest
) => {
  return useInfiniteQuery({
    queryKey: ["castingsByCastingCall", castingsByCastingCallRequest],
    queryFn: ({ pageParam = 0 }) =>
      getCastingsByCastingCall({
        ...castingsByCastingCallRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    enabled: !!castingsByCastingCallRequest.id,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 공고별 캐스팅 상세 조회
const getCastingByCastingCall = async (
  castingByCastingCallRequest: castingByCastingCallRequest
) => {
  return await requestToCastingVote
    .get(
      `castingcalls/${castingByCastingCallRequest.castingCallId}/castings/${castingByCastingCallRequest.castingId}`,
      {
        params: castingByCastingCallRequest,
      }
    )
    .then((res) => res.data);
};

const useCastingByCastingCallRequest = (
  castingByCastingCallRequest: castingByCastingCallRequest
) => {
  return useQuery({
    queryKey: ["castingByCastingCall", castingByCastingCallRequest],
    enabled: !!(
      castingByCastingCallRequest.castingCallId &&
      castingByCastingCallRequest.castingId
    ),
    queryFn: () => getCastingByCastingCall(castingByCastingCallRequest),
  });
};

// 아래 4개는 상태변경으로 가능
// 캐스팅 최종 후보 선정
// 캐스팅 오디션 제안
// 캐스팅 최종 합격
// 캐스팅 불합격

export {
  useSelectCastingProfiles,
  useChangeCastingStatus,
  useCastingsByCastingCall,
  useCastingByCastingCallRequest,
};
