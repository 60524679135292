import Banner0 from "assets/banner0.png";
import Banner1 from "assets/banner1.png";
import NewMembership from "assets/newMembership.png";
import Banner4 from "assets/banner4.png";
import Banner5 from "assets/banner5.png";
import Banner6 from "assets/banner6.png";
import BannerMOU from "assets/banner_mou.png";

export const bannerItems = [
  {
    src: Banner6,
    url: "https://daffy-periwinkle-38f.notion.site/1c24e358e81b80d68ecbeae11eb169a8",
  },
  {
    src: NewMembership,
    url: "https://daffy-periwinkle-38f.notion.site/1b64e358e81b805ba103d79f2d13fd5a",
  },
  {
    src: BannerMOU,
    url: "https://www.hankyung.com/article/202502195638H",
  },
  {
    src: Banner5,
    url: "https://daffy-periwinkle-38f.notion.site/19f4e358e81b807ebcd4c25e9f2e03c6",
  },
  {
    src: Banner0,
    url: "https://pf.kakao.com/_WlxlAn",
  },
  {
    src: Banner1,
    url: "https://daffy-periwinkle-38f.notion.site/1844e358e81b807a815ef05cd98d0d35",
  },
  {
    src: Banner4,
    url: "https://daffy-periwinkle-38f.notion.site/FAQ-16f4e358e81b8036bb09ff328465d040",
  },
];
